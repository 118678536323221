
@font-face {
    font-family: "Conv_MoonTime-Regular";
    src: url("../src/assets/fonts/MoonTime-Regular.eot");
    src: local("☺"), url("../src/assets/fonts/MoonTime-Regular.woff") format("woff"),
    url("../src/assets/fonts/MoonTime-Regular.ttf") format("truetype"),
    url("../src/assets/fonts/MoonTime-Regular.svg") format("svg");
    font-weight: normal;
    font-style: normal;
}
.joy{
    font-family: "Conv_MoonTime-Regular";
}