// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Admin-UI-primary: mat.define-palette(mat.$indigo-palette);
$Admin-UI-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Admin-UI-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Admin-UI-theme: mat.define-light-theme(
  (
    color: (
      primary: $Admin-UI-primary,
      accent: $Admin-UI-accent,
      warn: $Admin-UI-warn,
    ),
  )
);

/* Add Froala CSS */
@import "froala-editor/css/froala_style.min.css";
@import "froala-editor/css/plugins/code_view.min.css";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Admin-UI-theme);

/* You can add global styles to this file, and also import other style files */

/* toast ui css*/

.tui-image-editor-container
  .tui-image-editor-submenu
  .tui-image-editor-submenu-item
  label {
  font-size: 11px !important;
}

#tuiContainer {
  z-index: 999999999999 !important;
}

.tui-image-editor-container.left .tui-image-editor-submenu {
  width: 196px !important;
  border-right: 1px solid #3a3535;
}

.tui-image-editor-header-logo {
  // background-image: url("../src/assets/images/editor_logo.png");
  width: 74px;
  height: 36px;
  background-repeat: no-repeat;
  margin: 5px !important;
  padding: 0 !important;
}

.tui-image-editor-header-logo img {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  height: 100%;
}

// @import "~font-awesome/css/font-awesome.css";
// @import "~ng-pick-datetime/assets/style/picker.min.css";
// // @import "~ngx-toastr/toastr";
// @import "~@ng-select/ng-select/themes/default.theme.css";

@import "~ng-pick-datetime/assets/style/picker.min.css";

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.radius50 {
  border-radius: 50%;
}

.shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

.example-container {
  height: 500px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.example-sidenav-content {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.minheightScroll {
  max-height: 250px;
  overflow-x: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.minheightScroll::-webkit-scrollbar {
  /* WebKit */
  width: 0px;
}

.minHeight200 {
  min-height: 278px !important;
}

.topLogowidth {
  width: 250px;
}

.margin0 {
  margin: 0px !important;
}

.displayFlex {
  display: flex !important;
}

.displayInlineFlex {
  display: inline-flex !important;
}

.displayBlock {
  display: block;
}

.alignItemEnd {
  align-items: flex-end;
}

.alignItemStart {
  align-items: flex-start;
}

.example-sidenav {
  user-select: none;
}

.full-width {
  width: 100%;
}

.menu-button {
  transition: 300ms ease-in-out;
  transform: rotate(0deg);
}

.menu-button.rotated {
  transform: rotate(180deg);
}

.submenu {
  overflow-y: hidden;
  transition: transform 300ms ease;
  transform: scaleY(0);
  transform-origin: top;
  padding-left: 10px;
}

.submenu.expanded {
  transform: scaleY(1);
  //display:none;
}

.mat-toolbar {
  background: #ffffff !important;
}

button {
  outline: none;
}

button:focus {
  outline: none;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  padding: 0px !important;
}

.adminLogo {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  height: 72px;
  width: 72px;
  margin-top: 20px;
  object-fit: cover;
}

.profilepicture {
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}

// .mainContainer {
//   width: 100%;
//   display: flex;
// }
.leftSection {
  /* width: 17%;*/
  // padding-top: 30px;
  // height: 100vh;

  .admininfo {
    color: #242f48;
    padding-top: 10px;
  }
}

.rightSection {
  width: 100%;
  background: #f8f8f8;
  height: 100%;
  padding-left: 280px;
  padding-right: 40px;
}

.alignCenter {
  text-align: center;
}

.mainContainerExpand {
  width: 100%;
  padding-left: 0;
  padding-left: 25px;
}

.w100per {
  width: 100%;
}

.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  color: #f36b24 !important;
  background: transparent !important;
}

body {
  background: #f8f8f8;
  // font-family: "Poppins", sans-serif !important;
}

.pright {
  padding-right: 20px;
}

.bottomButtonGroup {
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 5px;
  position: fixed;
  /* width: 92%;
  border: 130;*/
  z-index: 99999999999;
  bottom: 50px;
  width: calc(100% - 390px);
}

.bottomButtonGroupExpand {
  width: calc(100% - 130px) !important;
}

.mat-drawer {
  // position: fixed !important;
  // top: 53px !important;
  width: 249px;
}

.borderColor {
  border: 1px solid #f36b24 !important;
  width: auto !important;
  float: left;
  margin-right: 10px;
  margin-right: 10px;
  height: 30px !important;
  padding: 3px !important;
}

.borderColor2 {
  border: 1px solid #f36b24 !important;
  float: left;
  margin-right: 10px;
  margin-right: 10px;
  height: 30px !important;
  padding: 3px !important;
}

.textletterA {
  // background: url(../src/assets/images/atext.png) !important;
  width: 106px;
  height: 30px;
  border: 1px solid #f36b24 !important;
  // padding: 3px;
  // margin-left: 10px;

  float: left;
  margin-right: 10px;
  border-radius: 5px;
}

.matdrawerCollapsed {
  width: 0% !important;
}

.app-container {
  margin-top: 94px !important;
  padding: 0px !important;
}

.topLogo {
  // width: 250px;
  text-align: center;
}

.cursorPointer {
  cursor: pointer;
}

#divLoading {
  display: none;
}

#divLoading.show {
  display: block;
  position: fixed;
  z-index: 100;
  background-image: url("../src/assets/images/loader.gif");
  background-color: rgba(102, 102, 102, 0.4);
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
}

.mat-form-field-infix {
  padding: 0px;
  border-top: unset;
}

.main_heading {
  padding: 10px;
}

.mainBanner {
  border-radius: 10px;
  height: 270px;
  background: #fff;
  // background: -webkit-linear-gradient(to right, #e100ff, #7f00ff);
  // background: linear-gradient(to right, #e100ff, #7f00ff);
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagePlaceHolder {
  width: 100%;
  // height: 170px;
  background: #dae1f8;
  border: 1px solid #dadada4a;
}

.imagePlaceHolder label {
  margin-bottom: 0 !important;
}

.play-btn {
  width: 100px;
  height: 100px;
  background: radial-gradient(
    rgba(222, 176, 77, 0.8) 60%,
    rgba(255, 255, 255, 1) 62%
  );
  border-radius: 50%;
  position: absolute;

  box-shadow: 0px 0px 25px 3px rgba(222, 175, 77, 0.8);
  margin-left: -50px;
  margin-top: -50px;
  left: 50%;
  top: 50%;
}

/* triangle */
.play-btn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.play-btn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  top: -30%;
  left: -30%;
  background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-header > .card-tools {
  float: right;
  // margin-right: -0.625rem;
}

.card.card-tabs:not(.card-outline) > .card-header {
  border-bottom: 0;
}

.card-primary:not(.card-outline) > .card-header,
.card-primary:not(.card-outline) > .card-header a {
  color: #000000;
}

.card-primary:not(.card-outline) > .card-header {
  background-color: #fff;
}

.card-header:first-child {
  // border-radius: calc(0.25rem - 0) calc(0.25rem - 0) 0 0;
  border-radius: 0.25rem;
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card {
  margin-bottom: 1rem;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #dadada4a;
  border-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  float: left;
  font-size: 1.1rem !important;
  font-weight: 400 !important;
  margin: 0;
  text-transform: uppercase;
  margin: 0 !important;
}

.innerTitle {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
}

.linkColor {
  color: #007bff;
  cursor: pointer;
}

.form-control:focus {
  border-color: #ddba6f !important;
  box-shadow: 0 0 0 0.2rem rgba(221, 186, 111, 0.25) !important;
}

.companyDetails .mat-tab-label {
  background: #d9d9d9;
  margin-right: 20px !important;
  height: auto !important;
  padding: 5px 50px !important;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
}

.companyDetails .mat-tab-label-active {
  background: #a7a2a9 !important;
}

.companyDetails .mat-tab-header {
  border-bottom: none;
}

.bgWhite {
  background: #fff !important;
}

.defaultLogo {
  // background: url(../src/assets/images/default_logo.png);
  background-size: auto;
  background-repeat: no-repeat;
  background-size: contain, cover;
  width: 80px;
  height: 100px;
  margin-left: 50px;
  margin-top: 30px;
  position: absolute;
}

.editStrip {
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  padding: 5px;
}

.defaultlogocheckbox {
  font-size: 12px;
  font-weight: normal !important;
  padding-left: 5px;
}

.updateButton {
  background-color: #28272d;
  /* Green */
  border: none;
  color: #fff;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 20px;
  text-transform: uppercase;
  margin-right: 30px;
  cursor: pointer;
}

.updateButton:hover {
  background-color: #1d1a2d;
  /* Green */
}

.cancelButton {
  background-color: #a7a2a9;
  /* Green */
  border: none;
  color: #fff;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 20px;
  text-transform: uppercase;
  margin-right: 30px;
  cursor: pointer;
}

button:focus {
  outline: 0px !important;
}

.main-footer {
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
}

.mainfooter2 {
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
  position: fixed;
  /* width: 92%; */
  border: 130;
  z-index: 999999999;
  bottom: 0;
  width: calc(100% - 333px);
}

.mainfooter2Expand {
  width: calc(100% - 90px) !important;
}

.companyDetails .mat-tab-list {
  margin-bottom: 20px !important;
  margin-left: 15px;
}

.companyDetails .mat-tab-group {
  width: 100% !important;
}

.socialPromotemp_item {
  cursor: pointer;
  width: 100%;
  height: 250px;
}

.socialPromotemp_item img {
  border: 1px solid #e9ebec;
  // padding: 10px;
  // border-radius: 5px;
  width: 100%;
  height: 250px;
  min-height: 250px;
  object-fit: contain;
  background: #fff;
}

.promoPopupButton {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  margin: 20px auto;
  width: 150px;
  height: 150px;
  background: white;
  border-radius: 75px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  -webkit-transition: 1s box-shadow;
  transition: 1s box-shadow;
  z-index: 999;
  border: 1px solid #f36b24;
  cursor: pointer;
}

.promoPopupButton:hover {
  box-shadow: 0 5px 35px 0px rgba(0, 0, 0, 0.1);
}

.promoPopupButton:hover:before,
.promoPopupButton:hover:after {
  display: block;
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  background: #fda8cf;
  border-radius: 75px;
  z-index: -1;
  -webkit-animation: 1s clockwise infinite;
  animation: 1s clockwise infinite;
}

.promoPopupButton:hover:after {
  // background: #f3ce5e;
  background: linear-gradient(180deg, #f36b24 0%, #ffb168 100%);
  -webkit-animation: 2s counterclockwise infinite;
  animation: 2s counterclockwise infinite;
}

.promoPopupButton:hover {
  color: white !important;
}

@-webkit-keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }

  12% {
    top: -2px;
    left: 2px;
  }

  25% {
    top: 0;
    left: 5px;
  }

  37% {
    top: 2px;
    left: 2px;
  }

  50% {
    top: 5px;
    left: 0;
  }

  62% {
    top: 2px;
    left: -2px;
  }

  75% {
    top: 0;
    left: -5px;
  }

  87% {
    top: -2px;
    left: -2px;
  }

  100% {
    top: -5px;
    left: 0;
  }
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }

  12% {
    top: -2px;
    left: 2px;
  }

  25% {
    top: 0;
    left: 5px;
  }

  37% {
    top: 2px;
    left: 2px;
  }

  50% {
    top: 5px;
    left: 0;
  }

  62% {
    top: 2px;
    left: -2px;
  }

  75% {
    top: 0;
    left: -5px;
  }

  87% {
    top: -2px;
    left: -2px;
  }

  100% {
    top: -5px;
    left: 0;
  }
}

@-webkit-keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }

  12% {
    top: -2px;
    right: 2px;
  }

  25% {
    top: 0;
    right: 5px;
  }

  37% {
    top: 2px;
    right: 2px;
  }

  50% {
    top: 5px;
    right: 0;
  }

  62% {
    top: 2px;
    right: -2px;
  }

  75% {
    top: 0;
    right: -5px;
  }

  87% {
    top: -2px;
    right: -2px;
  }

  100% {
    top: -5px;
    right: 0;
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }

  12% {
    top: -2px;
    right: 2px;
  }

  25% {
    top: 0;
    right: 5px;
  }

  37% {
    top: 2px;
    right: 2px;
  }

  50% {
    top: 5px;
    right: 0;
  }

  62% {
    top: 2px;
    right: -2px;
  }

  75% {
    top: 0;
    right: -5px;
  }

  87% {
    top: -2px;
    right: -2px;
  }

  100% {
    top: -5px;
    right: 0;
  }
}

.promoPopup {
  text-align: center;
}

// start code
[class^="imghvr-"],
[class*=" imghvr-"] {
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  background: linear-gradient(180deg, #f36b24 0%, #ffb168 100%);
  color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 5px;
}

[class^="imghvr-"] > img,
[class*=" imghvr-"] > img {
  vertical-align: top;
  max-width: 100%;
}

[class^="imghvr-"] figcaption,
[class*=" imghvr-"] figcaption {
  background-color: #ead8b0;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #000;
}

[class^="imghvr-"] figcaption h1,
[class^="imghvr-"] figcaption h2,
[class^="imghvr-"] figcaption h3,
[class^="imghvr-"] figcaption h4,
[class^="imghvr-"] figcaption h5,
[class^="imghvr-"] figcaption h6,
[class*=" imghvr-"] figcaption h1,
[class*=" imghvr-"] figcaption h2,
[class*=" imghvr-"] figcaption h3,
[class*=" imghvr-"] figcaption h4,
[class*=" imghvr-"] figcaption h5,
[class*=" imghvr-"] figcaption h6 {
  color: #ffffff;
}

[class^="imghvr-"] a,
[class*=" imghvr-"] a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

[class^="imghvr-"],
[class*=" imghvr-"],
[class^="imghvr-"]:before,
[class^="imghvr-"]:after,
[class*=" imghvr-"]:before,
[class*=" imghvr-"]:after,
[class^="imghvr-"] *,
[class*=" imghvr-"] *,
[class^="imghvr-"] *:before,
[class^="imghvr-"] *:after,
[class*=" imghvr-"] *:before,
[class*=" imghvr-"] *:after {
  box-sizing: border-box;
  transition: all 0.35s ease;
}

/* imghvr-hinge-*
   ----------------------------- */
[class^="imghvr-hinge-"],
[class*=" imghvr-hinge-"] {
  -webkit-perspective: 50em;
  perspective: 50em;
}

[class^="imghvr-hinge-"] figcaption,
[class*=" imghvr-hinge-"] figcaption {
  opacity: 0;
  z-index: 1;
}

[class^="imghvr-hinge-"]:hover img,
[class*=" imghvr-hinge-"]:hover img {
  opacity: 0;
}

[class^="imghvr-hinge-"]:hover figcaption,
[class*=" imghvr-hinge-"]:hover figcaption {
  opacity: 1;
  transition-delay: 0.21s;
}

/* imghvr-hinge-down
   ----------------------------- */
.imghvr-hinge-down img {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  // border: 1px solid #f36b24;
  border: 1px solid #e9ebec;
  border-radius: 5px;
}

.imghvr-hinge-down figcaption {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
  -webkit-transform-origin: 50% -50%;
  transform-origin: 50% -50%;
  text-align: center;
}

.imghvr-hinge-down:hover > img {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  opacity: 0;
}

.imghvr-hinge-down:hover figcaption {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}

// end code

.button {
  display: inline-block;
  font-weight: 400;
  color: #000;
  background-color: transparent;

  box-shadow: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #f36b24;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button:hover {
  color: #fff;
  background-color: #f36b24;
  border-color: #f36b24;
}

.graybutton {
  display: inline-block;
  font-weight: 400;
  color: #000;
  background-color: transparent;

  box-shadow: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #545b62;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.graybutton:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.chooseButton {
  width: 180px;
  font-size: 12px !important;
}

.remove-border-bottom ::ng-deep .mat-tab-header {
  border-bottom: none;
}

.remove-border-bottom .mat-tab-header {
  border-bottom: none;
}

.justifycontentCenter {
  justify-content: center;
}

.mb15 {
  margin-bottom: 15px;
}

.table td,
.table th {
  padding: 5px !important;
  vertical-align: initial !important;
}

.mat-dialog-container {
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.modal-title {
  font-weight: 400;
  font-size: 1.5rem !important;
}

.modal-header {
  padding-bottom: 0px !important;
}

.modal-content {
  border: none !important;
}

.titckBlock {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #f36b24;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;

  i {
    font-size: 40px;
    line-height: 100px;
    text-align: center;
    color: #f36b24;
  }
}

.textAlignCenter {
  text-align: center;
}

.textAlignRight {
  text-align: right;
}

.fright {
  float: right;
}

.clearBoth {
  clear: both;
}

.flotLeft {
  float: left;
}

.flotRight {
  float: right;
  margin-right: 10px;
}

.popupcloseButton {
  padding-top: 20px;
  padding-right: 20px;
  cursor: pointer;
}

.w50per {
  width: 50%;
  margin: 0 auto;
}

.mtb10 {
  margin: 10px 0px;
}

.mtb20 {
  padding-left: 10px;
  padding-bottom: 25px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize34 {
  font-size: 34px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize24 {
  font-size: 24px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize16 {
  font-size: 16px;
}

.fontsize18 {
  font-size: 18px;
}

.fontsize14 {
  font-size: 14px;
}

.ptopBottom {
  padding: 10px 0px;
}

.longButton {
  padding: 0.375rem 50px !important;
}

.personalImage img {
  width: 154px;
  height: 154px;
  object-fit: cover;
  border: 1px solid #f36b24;
  border-radius: 5px;
}

.personalImage2 img {
  width: 230px;
  height: 154px;
  object-fit: cover;
  border: 1px solid #f36b24;
  border-radius: 5px;
}

.personalImage3 img {
  width: 113px;
  height: 244px;
  object-fit: cover;
  border: 1px solid #f36b24;
  border-radius: 5px;
}

.personalImage4 img {
  width: 100%;
  height: 154px;
  object-fit: contain;
  border: 1px solid #e9ebec;
  border-radius: 5px;
  padding: 5px;
}

.connecttxt {
  color: red !important;
  float: right;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  display: none !important;
}

.custom-file {
  display: none !important;
}

.custom-file-label {
  // width: 30% !important;
  float: right;
  position: relative;
  white-space: nowrap;
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

.settingBlock {
  position: fixed;
  right: 0;
  top: 150px;
  z-index: 9999;
  display: flex;

  .gearIcon {
    width: 50px;
    height: 50px;
    border: 1px solid #f36b24;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    line-height: 50px;
    cursor: pointer;
  }

  .gearIcon i {
    font-size: 20px;
  }

  .settingmainBlock {
    border: 1px solid #f36b24;
    width: 250px;

    background-color: #fff;
    padding: 10px;
  }
}

.p5 {
  padding: 5px;
}

.ptop12 {
  padding-top: 12px;
}

.ptop6 {
  padding-top: 6px;
}

@media screen and (max-width: 601px) {
  .imagePlaceHolder {
    margin-bottom: 10px;
  }

  .titleText {
    font-size: 14px !important;
  }

  .w50per {
    width: 90% !important;
  }

  .updateButton {
    margin-right: 20px;
  }

  .rightSection {
    padding-left: 0px;
    padding-right: 0;
  }

  .topLogo {
    width: inherit;
  }

  .topLogowidth {
    width: inherit;
  }
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: #1d1a2d;
  border-color: #1d1a2d;
}

.btn-outline-secondary {
  color: #fff;
  background-color: #1d1a2d;
  border-color: #1d1a2d;
}

/*.btn-primary {
  color: #000;
  background-color: #fff;
  border-color: #f36b24;
}
.btn-primary:hover {
  color: #fff;
  background-color: #f36b24;
  border-color: #f36b24;
}
*/
.cal-month-view .cal-day-cell {
  background: #fff;
}

.cal-month-view .cal-header {
  font-weight: normal;
}

.cal-month-view .cal-open-day-events {
  color: white;
  background-color: #f36b24;
  box-shadow: none;
}

.cal-event-actions a {
  border: 1px solid #84817c;
  border-radius: 5px;
  background: #fff;
  color: #000;
}

.mat-cell,
.mat-footer-cell {
  border-right: 1px solid #ccc;
}

.mat-header-row {
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border: 1px solid #ccc;
}

@media screen and (max-width: 960px) {
  .mat-cell:last-of-type,
  .mat-header-cell:last-of-type,
  .mat-footer-cell:last-of-type {
    padding-right: 0;
  }

  .editStrip {
    width: 154px;
  }

  .mainBanner {
    height: 170px;
  }

  .minHeight200 {
    min-height: inherit !important;
  }

  .mainBanner {
    margin-top: 70px;
  }

  .mat-row::after {
    display: inherit;
  }

  .mat-cell .chooseButton {
    width: 165px;
  }

  mat-row,
  mat-footer-row {
    min-height: unset !important;
  }

  .mat-header-cell {
    display: none;
  }

  /*  Enable this to hide header
    .mat-table .mat-header-cell {

      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    */

  /*
    .mat-table .mat-row:nth-child(even) {background: #CCC}
    .mat-table .mat-row:nth-child(odd) {background: #FFF}
    */
  .mat-table .mat-cell {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
    font-weight: bold;
    // height: 30px;
    // margin-bottom: 4%;
  }

  .mat-table .mat-cell:before {
    /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: normal;

    font-size: 0.85em;
  }
}

//  -----------------------------  Newsletter Style Part -------------------------------------------------------------

// @import url("https://fonts.googleapis.com/css2?family=Dancing+Script&family=Montserrat:ital,wght@0,500;0,600;1,500&display=swap");
// @font-face {
//   font-family: "Conv_MoonTime-Regular";
//   src: url("../src/assets/fonts/MoonTime-Regular.eot");
//   src: local("?"),
//     url("../src/assets/fonts/MoonTime-Regular.woff") format("woff"),
//     url("../src/assets/fonts/MoonTime-Regular.ttf") format("truetype"),
//     url("../src/assets/fonts/MoonTime-Regular.svg") format("svg");
//   font-weight: normal;
//   font-style: normal;
// }
// .joy {
//   font-family: "Conv_MoonTime-Regular";
// }
// .miller {
//   font-family:  "Poppins", sans-serif;
// }

//  -----------------------------  End Newsletter Style Part -------------------------------------------------------------
.second-toolbar #logo {
  display: none;
}

// .fr-box.fr-basic .fr-element {
// padding: 0px !important;
// }

// .fr-view img.fr-dii {
// max-width: inherit !important;
// }

.hidden {
  display: none;
}

// Optin Form Styles

@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Arimo&display=swap");

:root {
  --heading-font: "Abril Fatface", cursive;
  --input-font: "Arimo", sans-serif;
}

.bg-brown {
  background: #e3ac5d !important;
}

.bg-darkgray {
  background: #2e3138;
}

.bg-gray {
  background: #7c747c !important;
}

.bg-white {
  background: white !important;
}

.form-container {
  position: relative;
  // top: 10vh;
  width: 70%;
  /* max-height: 100vh;
    max-width: 95vw; */
  margin: auto;
}

.p10 {
  padding: 10px;
}

.mb_24 {
  margin-bottom: 24px !important;
}

.relative {
  position: relative;
}

.customShadow {
  box-shadow: 1px 1px 20px -5px #84827b;
}

.formBg {
  /* background-image: url('../img/dummyImg.jpg'); */
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.formBg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.inputDiv {
  position: relative;
  text-align: center;
  padding: 5px 10px;
}

.formBg h3 {
  font-family: var(--heading-font);
}

.inputDiv h3 {
  font-family: var(--heading-font);
  padding: 25px 0px;
  color: #000000;
}

.inputDiv .submitBtn {
  margin: 20px 0px;
}

.inputDiv input {
  border: 1px solid #f6e9da;
  color: #000000;
  font-family: var(--input-font);
  border-radius: 0 !important;
}

.inputDiv input::placeholder {
  color: #000000;
}

.closeBtn {
  position: absolute;
  right: 4px;
  color: #000000;
}

.closeBtn button {
  border: none;
  padding: 0px 10px 3px 10px;
  font-weight: 600;
  border-radius: 50%;
  transform: scale(0.9);
}

.submitBtn {
  border: none;
  color: #000000;
  padding: 6px 35px;
  border-radius: 50px;
  font-size: 13px;
  font-family: var(--input-font);
  cursor: pointer;
}

/** template 02 **/
.boxImgPadding {
  padding: 15px 15px 15px 30px !important;
}

.boxImgPadding .closeBtn {
  top: 18px;
  right: 35px;
}

.footerForm {
  padding: 15px;
  padding-bottom: 0;
}

.footerForm input {
  border: 4px solid #f6e9da;
  color: #000000;
  font-family: var(--input-font);
  background: #e3ac5d;
  padding: 0px 20px;
}

.footerForm input::placeholder {
  color: #000000;
}

.footerForm .submitBtn {
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
}

/** template 03 **/
.formHeader {
  position: relative;
  text-align: center;
}

.formHeader h3 {
  padding: 15px 0px;
}

.formHeader .closeBtn {
  top: 4px;
}

/** template04 **/
.floatFormBg {
  height: auto;
}

.floatForm {
  padding: 25px 20px;
  background: white;
  transform: scale(0.9);
  margin-top: 2%;
}

.floatFormBtn {
  border: none;
  padding: 0px 6px 3px 6px;
  font-weight: 600;
  border-radius: 50%;
  right: 0px;
  top: 3px;
}

/** template 05 **/
.form-container-small {
  position: relative;
  // top: 10vh;
  width: 50%;
  /* max-height: 100vh;
    max-width: 95vw; */
  margin: auto;
  padding: 30px;
}

.form-container-small .closeBtn {
  right: -23px;
  top: -23px;
}

/** template 06 **/
.leftAlign {
  text-align: left !important;
}

/** template 07 **/
.subHeading h3 {
  padding: 25px 0px 0px 0px;
}

/** template08 **/
.skewImg {
  z-index: -2;
}

.skewDiv::before {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: -141px;
  z-index: -1;
  background: white;
  transform-origin: bottom;
  -ms-transform: skew(30deg, 0deg);
  -webkit-transform: skew(30deg, 0deg);
  transform: skew(-16deg, 0deg);
}

.skewDiv h3 {
  padding: 57px 0px 10px 0px;
}

/** template 09 **/
.simpleFormBg {
  //background-image: url("../img/circle.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.simpleForm {
  padding: 80px 0px;
}

.simpleForm .transformTemplate {
  transform: scale(0.9);
}

.simpleForm .closeBtn {
  top: 4px;
}

/** template 10 **/
.stripedBg {
  overflow: hidden;
}

.stripedBg img {
  position: absolute;
  width: 67%;
  height: 129%;
  transform: rotate(-27deg);
  margin-left: -173px;
  margin-top: -56px;
}

.simpleBorder {
  border: 6px solid #7c747c;
}

/** Stripe svg **/
.stripeSvgInput form {
  padding: 0px 50px;
}

.stripeSvg {
  position: absolute;
  background: white;
  width: 22%;
  right: 0;
  top: 103px;
}

.stripeSvg svg {
  width: 100%;
}

@media only screen and (min-width: 320px) and (max-width: 399px) {
  .stripeSvgInput form {
    padding: 0px 33px;
  }

  .stripeSvg {
    width: 24%;
    top: 140px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 499px) {
  .stripeSvgInput form {
    padding: 0px 75px;
  }

  .stripeSvg {
    width: 25%;
    top: 130px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 766px) {
  .stripeSvgInput form {
    padding: 0px 100px;
  }

  .stripeSvg {
    width: 25%;
    top: 120px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1000px) {
  .stripeSvgInput form {
    padding: 0px 80px;
  }

  .stripeSvg {
    width: 20%;
    top: 115px;
  }
}

@media only screen and (min-width: 720px) {
  .form-container {
    width: 720px;
  }

  .leftAlign .form-group {
    padding-right: 8em !important;
  }
}

@media only screen and (min-width: 768px) {
  /** template 07 **/
  .subHeading .form-group {
    padding: 0px 10em;
  }
}

@media only screen and (max-width: 767px) {
  .formHeader {
    padding-top: 35px;
  }

  /** template 07 **/
  .subHeading .form-group {
    padding: 0px 2em;
  }
}

@media only screen and (max-width: 500px) {
  .stripedBg img {
    margin-left: -100px;
  }
}

@media only screen and (max-width: 1000px) {
  .form-container {
    width: 100%;
  }

  .formBg {
    height: 150px;
  }

  .closeBtn {
    right: 20px;
    top: 4px;
  }

  .boxImgPadding {
    padding: 15px 30px 15px 30px !important;
  }

  .floatFormBg .closeBtn {
    right: 0;
  }

  .form-container-small .closeBtn {
    right: -13px;
    top: -18px;
  }

  .footerForm .row {
    padding: 0px 35px 10px 35px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  /** template 04 **/
  /* .floatFormBg{
        height: 483px;
    } */
  .form-container-small {
    width: 100%;
  }

  .leftAlign form button {
    width: 90%;
  }

  .simpleForm .transformTemplate {
    transform: scale(0.8);
  }
}

.bordered-image img {
  // width: 350px;
  height: auto;
}

.fr-box.fr-basic {
  width: 100%;
}

.fr-box.fr-basic .fr-element::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.fr-box.fr-basic .fr-element::-webkit-scrollbar-track {
  background: #e5e7eb;
}

/* Handle */
.fr-box.fr-basic .fr-element::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.fr-box.fr-basic .fr-element::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Custome Template Css

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

:root {
  --mont: "Poppins", sans-serif;
  --dancing: "Dancing Script", cursive;
}

.posRelative {
  position: relative;
}

.imgDiv::before {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 219px;
  border-left-width: 0px;
  border-bottom: 246px solid #27aae1;
  content: "";
  position: absolute;
  margin-left: 5px;
  margin-top: 250px;
  z-index: 0;
}

@media only screen and (max-width: 900px) {
  .heading {
    position: absolute;
    right: -95px;
    bottom: -71px;
  }

  .heading p {
    font-size: 40px;
  }

  .para {
    right: -55px;
    bottom: -53px;
  }

  .para p {
    font-size: 12px;
  }
}

.posRelative {
  position: relative;
}

// CustomPost CSS End

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Satisfy&display=swap");

header {
  max-height: 600px;
  position: relative;
  overflow: hidden;
}

.grey-bg {
  background-color: #d9d9d9;
  padding: 25px 50px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.dark-overlay {
  position: relative;
}

.dark-overlay-img {
  z-index: 14;
  position: absolute;
}

.dark-overlay::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 12;
  pointer-events: none;
}

.full-header {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  height: 100vh;
}

.navbar-brand img {
  max-width: 140% !important;
  max-height: 100px;
}

a.btn-primary {
  background-color: #f36b24;
  border: none;
  font-size: 16px;
  padding: 0px 30px;
  color: #fff !important;
  position: relative;
  z-index: 3;
  height: 40px;
  display: inline-flex;
  align-items: center;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  /* Overwriting bootstrap styling */
  background-color: rgb(141, 145, 170) !important;
  box-shadow: none !important;
  opacity: 0.5;
}

.fancy-text {
  color: rgb(141, 145, 170);
  // font-family: "Satisfy", sans-serif !important;
}

.wide-text {
  line-height: 1.5;
  margin: 20px 0;
}

.greyish-color {
  color: #8d91aa;
}

/* Nav Style */

.logo {
  width: 90px;
}

.navbar-light {
  background-color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #333;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2px;
  padding: 8px 12px;
  text-transform: uppercase;
}

.navbar-light .navbar-toggler {
  border: 0;
}

// .dropdown-menu {
//   border: none;
//   box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
//   border-radius: 0;
// }

.dropdown-item {
  display: block;
  padding: 0.5rem 1.5rem;
  color: #333;
  color: #333;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #333;
  background-color: transparent;
}

/* Bg-text (Under header on Homepage) */
.bg-text {
  background: rgba(225, 218, 220, 0.9);
  color: #616161;
  font-size: 16px;
  line-height: 1.75;
  padding: 24px;
}

// .bg-text img {
//   margin-top: -50%;
// }

/* Bordered-image  Style*/
.bordered-image {
  position: relative;
  margin: 50px 0;
  z-index: 14;
}

// .bordered-image::after {
//   border: 4px solid rgb(185, 151, 118);
//   content: "";
//   position: absolute;
//   top: -30px;
//   right: -30px;
//   width: 100%;
//   height: 100%;
//   pointer-events: none;
// }

/* Text-container Style */
.text-container {
  padding: 30px;
}

/* Lets's Connect styles */
.lets-connect-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin: auto;
  text-align: center;
}

.lets-connect-text h4 {
  margin: 10px 0 40px;
}

.lets-connect-friends-container {
  background-color: #f9f5f1;
  padding: 50px 0;
}

.lets-connect-friends-container ul {
  display: flex;
  padding: 0;
  list-style-type: none;
}

.lets-connect-friends-container ul li {
  margin: 30px;
  text-align: center;
}

.lets-connect-friends-container ul i {
  font-size: 100px;
  margin: 10px 0;
}

.lets-connect-bordered-text {
  background-color: rgba(0, 0, 0, 0.49);
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  display: flex;
  font-size: 24px;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 30px;
  text-align: left;
  margin: auto;
  width: 55%;
}

/* Oils Page styles */

/* Oils subpages Header */
.oils-header,
.dark-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}

.oils-header > .container {
  position: absolute;
  left: 0;
}

.oils-help-header-text {
  position: absolute;
  bottom: -47px !important;
  z-index: 14;
}

.oils-help-header-img {
  position: absolute;
  top: 260px;
  left: 56px;
}

.oils-help-header-text-v2 {
  position: absolute;
  top: -45px !important;
  bottom: unset;
  z-index: 14;
}

.oils-help-header-img-v2 {
  max-width: 375px !important;
  position: absolute;
  top: -337px;
  left: 56px;
}

.oils-help-container {
  margin: 50px auto;
  padding-top: 160px;
  position: relative;
}

.oils-help-box {
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  position: relative;
  margin: 15px;
  min-height: 300px;
  width: calc(25% - 30px);
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
  background-size: cover;
}

.oils-help-box > * {
  z-index: 3;
}

.oils-help-box::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.oils-help-box img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.booking {
  background-color: #181e1e;
  color: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}

.booking .title {
  color: #ead3cc;
}

.booking p {
  display: flex;
  align-items: center;
  /*justify-content: space-between;*/
  margin: 2px 0;
}

.booking .social-links {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.booking .social-links a {
  color: #ead3cc;
  font-size: 30px;
  margin-right: 15px;
}

/* Item-details style */
.item-details {
  background-color: #f9f5f1;
  color: #616161;
  font-size: 1.1em;
  line-height: 2;
  padding-top: 50px;
  padding-bottom: 50px;
}

.item-details ul {
  padding-left: 20px;
}

.item-details strong {
  color: #333;
}

/* Slider style */

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 30px;
  width: 30px;
}

.slider-container {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  position: relative;
  text-align: center;
}

.slider-container::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

.slider-container .hearts-container {
  margin-top: 20px;
}

.slider-container .hearts-container i {
  font-size: 20px;
  margin: 10px 6px;
}

.slider-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 65%;
  margin: auto;
}

.slider-text i {
  line-height: 30px;
}

/* Free Gift page style */
.free-gift-sc {
  position: absolute;
  text-align: center;
  max-width: 70%;
}

.white-radius-background {
  border-radius: 10px;
  padding: 10px 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-style: solid;
  border-width: 0;
  background-clip: padding-box;
  border-color: rgba(2, 0, 5, 0.11);
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.white-radius-background ul {
  line-height: 40px;
  max-width: 500px;
  margin: 20px auto;
  text-align: left;
}

.golden-border {
  border: 4px solid rgb(185, 151, 118);
  padding: 20px;
  position: relative;
  z-index: 3;
}

.btn-up {
  margin-top: -30px;
  position: relative;
  z-index: 5;
}

.white-bordered-img {
  border: 30px solid #fff;
}

.white-bordered-img.move-left {
  margin-left: -80px;
}

.white-bordered-img.move-right {
  margin-left: 80px;
}

.colored-wrap {
  background-color: #e0e5e8;
  padding-top: 150px;
  padding-bottom: 150px;
  position: relative;
  margin-top: -50px;
  /*margin-bottom: -100px;*/
  /*z-index: -1;*/
}

.colored-wrap::before {
  border-bottom: 100px solid #e0e5e8;
  border-left: 1140px solid transparent;
  content: "";
  position: absolute;
  top: -100px;
  left: 0;
  width: 0;
  height: 0;
  z-index: 0;
}

.colored-wrap.no-before {
  margin: 0;
  padding: 50px 0;
}

.colored-wrap.no-before::before {
  display: none;
}

/* About styling */
.story-container {
  margin-top: -100px;
  margin-bottom: 200px;
}

.story-container .story-title {
  color: #333;
  margin-top: -102px;
  margin-bottom: 100px;
}

/* The colored-warp from the about page */
.colored-wrap-about {
  background-color: #d9d9d9;
  padding-top: 0;
}

.colored-wrap-about::before {
  border-bottom: 100px solid #d9d9d9;
  border-left: none;
  border-right: 1120px solid transparent;
}

.get-started-collage {
  border: 30px solid #fff;
  background-color: #fff;
}

/* Monthly styling*/
.timer-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.timer-container.timer-big {
  margin-top: -50px;
  z-index: 14;
  position: relative;
}

.timer-item {
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

.timer-container.timer-big .timer-item {
  font-size: 24px;
}

.timer-item .circle {
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #333;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  margin: auto auto 10px;
  position: relative;
  height: 50px;
  width: 50px;
  z-index: 1;
}

.timer-container.timer-big .circle {
  border: 5px solid #333;
  font-size: 30px;
  margin: auto auto 20px;
  height: 100px;
  width: 100px;
}

/* The colored-warp from the monthly-deals page */

.colored-wrap-monthly-deals {
  background-color: #d9d9d9;
  padding: 50px 0;
  margin: 0;
}

.colored-wrap-monthly-deals::before {
  border-bottom-color: #d9d9d9;
}

/* FAQ */
.refund-table th,
.refund-table td {
  border: 1px solid #000000;
  padding: 30px 15px;
}

.refund-table td {
  text-align: center;
}

/* Accordion styling */
.accordion-header {
  margin: 20px 0;
}

.card {
  border-radius: 0;
  border: 0;
  margin: 10px 0;
}

.card-header {
  background: #d9d9d9;
  border-radius: 0 !important;
  /* overwriting bootstrap */
  cursor: pointer;
  padding: 10px 15px;
}

.card-body {
  background-color: #eff0f2;
  border: 0;
}

/* Reviews Styling */
.reviews-container {
  background-color: #f9f8f3;
  margin-top: 150px;
}

.review {
  margin: 40px 0;
}

.review img {
  width: 100px;
}

.review p {
  color: #808285;
  font-weight: 100;
  font-size: 17px;
  line-height: 1.76em;
  margin: 10px 0;
}

.review strong {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.reviews-colored-bg {
  background-color: #8d91aa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.reviews-colored-bg::before {
  background-color: #8d91aa;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  height: 100px;
  width: 100%;
  /*z-index: -1;*/
}

.reviews-colored-bg h1 {
  color: #fff;
  text-transform: uppercase;
}

.reviews-container .reviews-colored-bg::before {
  content: none;
}

/* Wholesale Style */
.pink-bg {
  background-color: #ecd8cf;
  height: 100%;
  width: 100%;
}

.pink-bgg {
  background-color: #ecd8cf;
}

/* Buy Retail Styling */
.box {
  // border: 1px solid #333;
  border: 1px solid #e9ebec;
  height: 100%;
  padding: 20px;
  text-align: center;
}

// .box p {
//   color: #616161;
//   line-height: 30px;
//   margin: 0;
// }

/* Banner Styling */
.banner {
  margin: 15px auto;
}

/*Pre-footer Styling */
.pre-footer {
  background-color: #e4dedf;
  padding-top: 30px;
}

.pre-footer .text-small {
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
  margin: 10px 0;
}

.pre-footer-links {
  padding: 0;
  list-style-type: none;
}

.pre-footer-links a {
  color: #333;
  display: inline-block;
  font-size: 12px;
  padding: 10px 0;
  text-transform: uppercase;
}

/* Footer Styling */
footer {
  background-color: #c6c0b6;
  padding-top: 25px;
  padding-bottom: 25px;
}

footer p {
  font-weight: 300;
  margin: 0;
}

footer .social-links {
  list-style-type: none;
  display: flex;
  margin: 0;
}

footer .social-links a,
.wholesale-text .social-links a {
  margin-left: 15px;
  color: #333;
  font-size: 20px;
}

footer .social-links a:hover {
  opacity: 0.8;
}

footer .row.align-items-center,
.pre-footer .row {
  color: #333 !important;
  background-color: unset !important;
  font-size: 1rem !important;
}

/* Media Questies */
/* --breakpoint-xs: 0;
--breakpoint-sm: 576px;
--breakpoint-md: 768px;
--breakpoint-lg: 992px;
--breakpoint-xl: 1200px; */

@media screen and (max-width: 992px) {
  .dropdown-menu {
    box-shadow: none;
    color: red;
  }

  .oils-help-box {
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    min-height: 175px;
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 150%;
  }

  a.btn-primary {
    background-color: #f36b24;
    border: none;
    font-size: 14px;
    padding: 10px 20px;
    color: #fff !important;
  }

  /* Reset these margins */
  .white-bordered-img.move-left {
    margin-left: 0;
  }

  .white-bordered-img.move-right {
    margin-left: 0;
  }

  /* Reset to look like the normal one (small) */
  .timer-container.timer-big .circle {
    border: 2px solid #333;
    font-size: 14px;
    margin: auto auto 10px;
    height: 75px;
    width: 75px;
  }

  .timer-container.timer-big .timer-item {
    font-size: 14px;
  }

  .slider-text {
    max-width: 80%;
  }

  .wholesale-text {
    margin-top: 40px;
  }

  .card-header {
    font-size: 10px;
  }
}

/* Remove header padding and side margins for mobile view */
@media (max-width: 768px) {
  .container {
    max-width: none;
  }

  .navbar {
    padding: unset;
  }
}

@media (min-width: 1200px) {
  .navbar {
    padding: 0.5rem 1rem;
  }
}

/* Connect page social links styling */
.lets-connect-friends-container a {
  color: #333;
}

.lets-connect-friends-container a:hover {
  opacity: 0.8;
}

.loading_spinner:after {
  content: " ";
  position: fixed;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  border: 2px solid red;
  border-color: transparent red transparent red;
  border-radius: 50%;
  animation: loader 1s linear infinite;
  z-index: 2;
}

@keyframes loader {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.head-overlay-bg::before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(84, 82, 82, 0.8);
  opacity: 0.5;
  z-index: 1;
}

.pointer-events-none {
  pointer-events: none;
}

/* OptIn form styling */
#optin-form .form-actions {
  margin-top: 3%;
}

#optin-form input[type="submit"] {
  margin-right: 2%;
}

#optin-form .modal-content {
  padding: 20px 40px;
  background-color: #e4e4e6;
}

#optin-form .modal-header {
  border-bottom: 1px solid #000;
}

#validationError {
  color: red;
}

.gjs-mdl-dialog {
  /*width: auto !important;*/
  overflow: auto;
  max-width: unset !important;
  min-width: 55% !important;
}

.gjs-mdl-dialog {
  width: 97% !important;
}

.gjs-mdl-container {
  max-width: 1300px;
  margin: 0 auto;
}

.cropper-view-box {
  width: 99.8% !important;
  outline: 3px solid #39f !important;
}

.slider-container {
  max-height: 600px;
  max-width: 1300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

@media screen and (max-width: 1024px) {
  header,
  .slider-container {
    height: auto;
  }

  .white-radius-background ul {
    line-height: 24px;
    margin: 0px auto;
  }
}

@media screen and (max-width: 1280px) {
  .colored-wrap::before {
    border-left: 0 solid transparent;
  }

  .colored-wrap-about::before {
    border-right: 0 solid transparent;
  }
}

.mainContainer .card-header {
  background-color: #fff !important;
}

.mainContainer .card-body {
  background-color: #fff !important;
}

.modal-body .card-header {
  background-color: #fff !important;
}

.modal-body .card-body {
  background-color: #fff !important;
}

.help_videoBlock {
  border: 1px solid #e1af5e;
  border-radius: 5px;
  margin: 5px;
  outline: none;

  .textcontent {
    padding: 10px;
  }
}

.help_videoBlock video {
  padding-bottom: 10px;
}

/* loader Block */

// .loaderBlock {
//   background-color: #f8f8f8;
//   // background-color: rgba(0, 0, 0, 0.7);
//   width: 100%;
//   height: 100%;
//   display: flex;
//   text-align: center;
//   position: fixed;
//   left: 0;
//   z-index: 9999;
// }

.loader {
  /*-webkit-perspective:700px;
	perspective: 700px; */
  text-align: center;
  position: absolute;
  width: 400px;
  height: 200px;
  left: 50%;
  top: 50%;
  margin-left: -200px;
  margin-top: -100px;
}

.loader > span {
  font-size: 65px;
  font-family: "franklin gothic medium", sans-serif;
  display: inline-block;
  animation: flip 2.6s infinite linear;
  transform-origin: 0 70%;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

@keyframes flip {
  35% {
    transform: rotateX(360deg);
  }

  100% {
    transform: rotatex(360deg);
  }
}

.loader > span:nth-child(even) {
  color: white;
}

.loader > span:nth-child(odd) {
  color: #f36b24;
}

.loader > span:nth-child(2) {
  animation-delay: 0.3s;
}

.loader > span:nth-child(3) {
  animation-delay: 0.6s;
}

.loader > span:nth-child(4) {
  animation-delay: 0.9s;
}

.loader > span:nth-child(5) {
  animation-delay: 1.2s;
}

.loader > span:nth-child(6) {
  animation-delay: 1.5s;
}

.loader > span:nth-child(7) {
  animation-delay: 1.8s;
}

.loader > span:nth-child(8) {
  animation-delay: 2.1s;
}

// .fr-box.fr-basic .fr-element {
//   font-family:  "Poppins", sans-serif !important;
// }

.socialDesText {
  max-height: 100px;
  overflow-x: scroll;
  padding: 10px 0;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.socialDesText::-webkit-scrollbar {
  /* WebKit */
  width: 0px;
}

/* webstie template */

.temp84firstimage img {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

section img {
  max-width: 100%;
}

.fr-view .editor-view {
  height: 500px;
}

.fr-view .editor-view .oils-help-header-img-v2 {
  top: 0;
}

.fr-view .editor-view .oils-help-header-text-v2 {
  top: 275px !important;
}

@media (min-width: 992px) {
  .colored-wrap::before {
    // border-left: 929px solid transparent !important;
    z-index: 0;
  }
}

// Extra large devices (large desktops, 1200px and up)

// Remove Delete Icon in Froala Editor IMage Manager
div.fr-modal-body div.fr-image-list div.fr-image-container .fr-delete-img {
  display: none !important;
}

div.fr-modal-body div.fr-image-list div.fr-image-container .fr-insert-img {
  -webkit-transform: translateY(-50%) translateX(-50%) !important;
  -moz-transform: translateY(-50%) translateX(-50%) !important;
  -ms-transform: translateY(-50%) translateX(-50%) !important;
  -o-transform: translateY(-50%) translateX(-50%) !important;
}

.scrollcss {
  overflow: scroll;
  overflow-y: hidden;
}

.scrollcss .mat-paginator-container {
  width: inherit;
}

.cal-month-view .cal-day-badge {
  background-color: #f36b24 !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-top: 10px;
}

.fr-popup {
  z-index: 99999999 !important;
  left: 0px !important;
  margin-left: 250px;
  // left: 80px !important;
}

.fr-popup.fr-above {
  margin-top: 100px !important;
}

.fr-element section.colored-wrap {
  margin-top: 100px !important;
  max-width: 1140px;
  margin: 0 auto;
}

.mat-paginator-container {
  width: inherit !important;
  justify-content: space-between !important;
}

.plr0 {
  padding-left: 0;
  padding-right: 0;
}

.opform2 {
  overflow: auto;
  position: relative;

  .outertable {
    width: 720px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 1px 1px 20px -5px #84827b;
  }

  img {
    width: 260px;
    height: 350px;
    object-fit: cover;
  }

  .inputDiv {
    text-align: left;

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.opform1 {
  overflow: auto;
  position: relative;

  .outertable {
    width: 720px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 1px 1px 20px -5px #84827b;
  }

  img {
    width: 280px;
    height: 468px;
    object-fit: cover;
    margin-left: -15px;
    margin-top: -15px;
    margin-bottom: -15px;
  }

  .inputDiv {
    text-align: left;
  }

  h3 {
    text-align: center;
  }
}

.opform3 {
  overflow: auto;
  position: relative;

  .outertable {
    width: 720px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 1px 1px 20px -5px #84827b;
  }

  img {
    width: 280px;
    height: 333px;
    object-fit: cover;
    // margin-left: -15px;
    margin-top: -15px;
    // margin-bottom: -15px;
  }

  .inputDiv {
    text-align: left;
  }

  h3 {
    text-align: center;
    font-family: var(--heading-font);
    padding-bottom: 30px;
  }

  .inputDiv .submitBtn {
    margin: 20px 0px;
    font-size: 20px;
  }
}

.opform4 {
  overflow: auto;
  position: relative;

  .outertable {
    width: 720px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 1px 1px 20px -5px #84827b;
  }

  img {
    width: 720px;
    height: 550px;
    object-fit: cover;
  }

  .innerDivContainer {
    position: absolute;
    margin-top: -530px;
    width: 720px;
  }

  .rightBlock {
    height: 400px;

    margin-right: 15px;
    position: absolute;
    width: 420px;
    float: right;
    right: 0;
  }

  .inputDiv .submitBtn {
    margin: 20px 0px;
    font-size: 20px;
  }

  .bgwhite {
    background: #fff;
    /* padding: 15px; */
    opacity: 0.8;
  }

  .innerContent {
    margin-top: -440px;
    position: absolute;
    /* padding: 10px; */
    float: right;
    /* right: 0; */
    width: 340px;
    /* margin: 0 auto; */
    margin-left: 39px;

    h3 {
      text-align: center;
      font-family: var(--heading-font);
      padding-top: 30px;
    }
  }
}

.opform5 {
  overflow: auto;
  position: relative;

  .outertable {
    width: 350px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 1px 1px 20px -5px #84827b;
  }

  h3 {
    text-align: center;
    font-family: var(--heading-font);
  }

  .submitBtn {
    margin: 20px 0px;
    font-size: 20px;
  }
}

.opform6 {
  overflow: auto;
  position: relative;

  .outertable {
    width: 720px;
    background: #fff;
    margin: 0 auto;
    box-shadow: 1px 1px 20px -5px #84827b;
    padding: 15px;
  }

  img {
    width: 210px;
    height: 340px;
    object-fit: cover;
    // margin-left: -15px;
    // margin-top: -15px;
    // margin-bottom: -15px;
  }

  .inputDiv {
    text-align: left;
  }

  h3 {
    text-align: center;
  }

  .submitBtn {
    margin: 20px 0px;
    font-size: 20px;
    color: #fff;
  }
}

.optCloseButton {
  position: absolute;
  float: right;
  cursor: pointer;
  width: 690px;
  z-index: 99999;
}

// .ehhInvitation .fr-popup {
//   position: fixed !important;
//   top: 0 !important;
// }
.tableCenter {
  margin: 0 auto;
}

.class1 {
  background: #27aae1 !important;
}

.class2 {
  background: #fda8cf !important;
}

.sendmailButton {
  margin-top: 32px;
}

.landingPageGallery {
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
}

.galleryDesign {
  cursor: pointer;
  width: 256px;
  height: 250px;
}

.galleryDesign img {
  border: 1px solid #e9ebec;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  height: 250px;
  min-height: 250px;
  object-fit: contain;
}

// Editor Link Button Background Color
.whiteclr {
  background-color: #ffffff !important;
}

.silverclr {
  background-color: #c0c0c0 !important;
}

.grayclr {
  background-color: #808080 !important;
}

.blackclr {
  background-color: #000000 !important;
}

.redclr {
  background-color: #ff0000 !important;
}

.maroonclr {
  background-color: #800000 !important;
}

.yellowclr {
  background-color: #ffff00 !important;
}

.oliveclr {
  background-color: #808000 !important;
}

.limeclr {
  background-color: #00ff00 !important;
}

.oliveclr {
  background-color: #808000 !important;
}

.limeclr {
  background-color: #00ff00 !important;
}

.greenclr {
  background-color: #008000 !important;
}

.aquaclr {
  background-color: #00ffff !important;
}

.tealclr {
  background-color: #008080 !important;
}

.blueclr {
  background-color: #0000ff !important;
}

.navyclr {
  background-color: #000080 !important;
}

.fuchsiaclr {
  background-color: #ff00ff !important;
}

.purpleclr {
  background-color: #800080 !important;
}

#fr-logo {
  display: none;
}

/* circle image */

.maincircleImg {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
}

.innerCircle {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  border: 10px solid #e1af5e;
  background: #fff;
}

.innerImage {
  border-radius: 50%;
  width: 260px;
  height: 260px;
  margin-top: -325px;
  object-fit: cover;
  /* position: absolute; */
  // margin-left: 21px;
}

.mtb {
  margin-top: 50px;
  margin-bottom: 100px;
}

.ml70 {
  margin-left: 70px !important;
}

.temp32maincircleImg {
  width: 450px;
  height: 450px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  z-index: 9999;
}

.temp32innerCircle {
  border-radius: 50%;
  width: 450px;
  height: 450px;
  border: 10px solid #e1af5e;
  background: #fff;
}

.temp32innerImage {
  border-radius: 50%;
  width: 410px;
  height: 410px;
  margin-top: -390px;
  object-fit: cover;
  /* position: absolute;
   margin-left: 21px;*/
}

.temp32mtopmin34 {
  margin-top: -40px;
}

.mleftmin50 {
  margin-left: -70px;
}

.mrightmin50 {
  margin-right: -100px;
}

.millerstextbottom {
  // font-family:  "Poppins", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: -97px;
  position: relative;
  margin-left: -74px;
  z-index: 9999;
}

.brownTxtHeader32 {
  // font-family: "Conv_MoonTime-Regular";
  text-align: center;
  font-size: 128px;
  padding: 40px 10px;
  padding-left: 150px;
}

.brownTxtHeader33 {
  // font-family: "Conv_MoonTime-Regular";
  text-align: center;
  font-size: 128px;
  padding: 40px 10px;
  // padding-left: 150px;
}

.millerstext32 {
  // font-family:  "Poppins", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: -97px;
  position: relative;
  margin-left: -90px;
  z-index: 999;
}

.mleft20 {
  margin-left: 20px;
}

.mright20 {
  margin-right: 20px;
}

.maskimg {
  display: block;
  width: 282px;
  height: 284px;
  // -webkit-mask-image: url();
  // mask-image: url();
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  object-fit: cover;
}

.svgPath {
  cursor: pointer;
}

.goldbgcolor {
  cursor: pointer;
}

.goldbordercolor {
  cursor: pointer;
}

@font-face {
  font-family: "melattiregular";
  src: url("./assets/fonts/melatti-webfont.woff2") format("woff2"),
    url("./assets/fonts/melatti-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

// Blue Circle Color change

.blueCircle {
  width: 520px;
  height: 520px;
  background: #27aae1;
  border-radius: 50%;
  cursor: pointer;
}

.bluearrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 227px 0 0 226px;
  border-color: transparent transparent transparent #27aae1;
  position: absolute;
  margin-top: 291px;
  margin-left: 2px;
}

.blueCircleBlock {
  width: 550px;
}

.circleImg {
  width: 480px;
  height: 480px;
  margin-top: -502px;
  position: absolute;
  margin-left: -255px;
  object-fit: cover;
}

.circleImg img {
  object-fit: cover;
}

.spaceEvenly {
  justify-content: space-evenly !important;
}

@media only screen and (max-width: 900px) {
  .circleImg {
    width: 290px !important;
    height: 290px !important;
    margin-top: -305px !important;

    margin-left: -145px !important;
  }

  .blueCircle {
    width: 320px !important;
    height: 320px !important;
  }

  .blueCircleBlock {
    width: inherit !important;
    margin-top: 50px;
  }

  .bluearrow {
    border-width: 180px 0 0 150px !important;
    margin-top: 138px !important;
    margin-left: 1px !important;
  }
}

.reports-stats {
  min-width: 148px;
  vertical-align: top;
  height: 110px;
  border: 1px solid #ced4da !important;
  padding: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  text-align: center;
  margin: 0.5rem !important;

  .innertext {
    color: #6c757d;
    text-transform: uppercase;
    padding: 10px 0px 18px;
    font-size: 15px;
    font-weight: 500;
  }

  p {
    margin: 0;
  }

  .secondary {
    font-size: 13px;
    line-height: 16px;
  }
}

.reportBlock {
  flex-wrap: wrap;
  justify-content: center;
}

.mat-sort-header-arrow {
  margin: 0px !important;
}

.noteText {
  background: #cccccc6b;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  padding: 5px;
  font-size: 12px;
}

@media only screen and (max-width: 900px) {
  .opform2 {
    height: 450px;
    overflow: auto;
  }

  .opform2 .outertable {
    width: 95%;
  }

  .opform2 img {
    width: 100%;
    height: 150px;
  }

  .optCloseButton {
    position: absolute;
    float: right;
    cursor: pointer;
    width: 690px;
    z-index: 99999;
  }

  .optCloseButton {
    margin-top: -14px;
    margin-right: 8px;
    width: 90%;
  }

  .opform1 {
    height: 450px;
    overflow: auto;
  }

  .opform1 .outertable {
    width: 95%;
  }

  .opform1 img {
    width: 100%;
    height: 150px;
    margin-left: inherit;
    margin-top: inherit;
  }

  .opform3 {
    height: 450px;
    overflow: auto;
  }

  .opform3 .outertable {
    width: 95%;
  }

  .opform3 img {
    width: 100%;
    height: 150px;
    margin-left: inherit;
    margin-top: inherit;
  }

  .buttonWidth {
    width: 300px !important;
  }

  .opform4 {
    height: 500px;
    overflow: auto;
  }

  .opform4 .outertable {
    width: 95%;
  }

  .opform4 img {
    width: 100%;
  }

  .opform4 .rightBlock {
    width: 90%;
  }

  .opform4 .innerDivContainer {
    width: 95%;
  }

  .opform4 .innerContent {
    width: 100%;
    margin-left: inherit;
  }

  .opform5 {
    height: 550px;
    overflow: auto;
  }

  .opform5 .outertable {
    width: 95%;
  }

  .opform6 {
    height: 450px;
    overflow: auto;
  }

  .opform6 .outertable {
    width: 95%;
  }

  .opform6 img {
    width: 100%;
    height: 150px;
  }
}

#tie-crop-button .tui-image-editor-button.apply {
  /* margin-right: 24px; */
  background: #fdba3b;
  padding: 5px 15px;
  border-radius: 15px;
  border: 3px solid #ffffff;
  margin-right: inherit;

  label {
    font-size: 15px !important;
    color: #fff;
  }

  label:hover {
    color: #000 !important;
  }
}

#tie-crop-button .tui-image-editor-button.apply svg {
  display: none;
}

#tie-crop-button .tui-image-editor-button.cancel {
  /* margin-right: 24px; */
  background: #fcfffc;
  padding: 5px 15px;
  border-radius: 15px;
  border: 3px solid #fff;
  margin-right: inherit;

  label {
    font-size: 15px !important;
    color: #000 !important;
  }

  label:hover {
    color: #000 !important;
  }
}

#tie-crop-button .tui-image-editor-button.cancel svg {
  display: none;
}

/* website template responsive */

@media only screen and (max-width: 767px) {
  .innerCircle {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }

  .innerImage {
    width: 210px;
    height: 210px;
    margin-top: -276px;
  }

  .temp32innerCircle {
    width: 300px;
    height: 300px;
  }

  .temp32innerImage {
    border-radius: 50%;
    width: 310px;
    height: 310px;
    margin-top: -330px;
  }
}

@media only screen and (max-width: 767px) {
  .mtop20 {
    margin-top: 20px;
  }

  .cal-month-view .cal-day-cell {
    min-height: 50px;
  }

  .cal-month-view .cal-cell-top {
    min-height: 50px;
  }

  .fr-toolbar {
    background: #f5f5f5;
  }

  .fr-toolbar .fr-command.fr-btn,
  .fr-popup .fr-command.fr-btn,
  .fr-modal .fr-command.fr-btn {
    margin: 0 !important;
  }

  .plrnone {
    padding-right: 0;
    padding-left: 0px;
  }

  .customerSpotLightContent {
    padding-left: 0;
    padding-right: 0;
  }

  .slider-container img {
    height: 200px;
  }

  header img {
    width: 100% !important;
    height: auto;
  }

  .free-gift-sc {
    max-width: 95%;

    h3.fancy-text {
      font-size: 13px !important;
    }

    h2.h2-text {
      font-size: 14px !important;
    }
  }

  .white-radius-background {
    padding: 10px 0px;
  }

  .white-radius-background ul {
    display: none;
  }

  .bg-text img {
    margin-top: inherit;
  }

  .container-fluid header img {
    width: 100%;
  }

  .grey-bg {
    position: inherit !important;
  }

  .bordered-image::after {
    right: -10px;
  }

  .bg-text {
    padding: 10px;
  }

  .oils-help-header-img-v2 {
    position: relative;
    top: inherit;
    left: inherit;
  }

  .oils-help-container {
    padding-top: inherit;
  }

  .oils-header img {
    width: 100% !important;
    height: auto;
  }

  .slider-text i {
    line-height: 15px;
    font-size: 11px;
  }

  .slider-container .h2-text {
    font-size: 13px;
  }

  .timer-container.timer-big .circle {
    border: 2px solid #333;
    font-size: 14px;
    margin: auto auto 10px;
    height: 55px;
    width: 55px;
  }

  .timer-container.timer-big .timer-item {
    font-size: 10px;
  }

  .lets-connect-text h4 {
    margin: inherit;
    font-size: 13px;
    padding-bottom: 5px;
  }

  .lets-connect-text h1 {
    font-size: 16px;
  }

  .lets-connect-friends-container ul li {
    margin: 10px;
  }

  .lets-connect-friends-container ul i {
    font-size: 70px;
  }

  .lets-connect-friends-container {
    padding: 0;
  }

  .lets-connect-friends-container .h3-text {
    text-align: center;
  }

  .lets-connect-bordered-text .p-text {
    margin-bottom: 0;
  }

  .lets-connect-bordered-text {
    width: 95%;
    padding: 20px;
    font-size: 14px;
  }

  .lets-connect-bordered-text .company-connect-email {
    margin-bottom: 0;
  }

  .lets-connect-bordered-text .company-connect-phone {
    margin-bottom: 0;
  }

  .lets-connect-bordered-text .company-connect-text {
    margin-bottom: 0;
  }
}

.fr-fil {
  float: left;
  margin: 5px 5px 5px 0;
  max-width: calc(100% - 5px);
}

.fr-fir {
  float: right;
  margin: 5px 0 5px 5px;
  max-width: calc(100% - 5px);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .responsive_table td {
    display: block;
    width: 100% !important;
  }

  .responsive_table img {
    width: 100% !important;
  }
}

@media only screen and (max-width: 900px) {
  .responsiveImg {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .responsive_table td {
    display: inline-block;
    width: 100% !important;
  }
}

.height50 {
  height: 50px !important;
}

// new code
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.mb_12 {
  margin-bottom: 12px !important;
}

.mb_16 {
  margin-bottom: 16px !important;
}

.mb_8 {
  margin-bottom: 8px !important;
}

.mb_4 {
  margin-bottom: 4px !important;
}

.mr_12 {
  margin-right: 12px;
}

.mr_24 {
  margin-right: 24px;
}

.mr_8 {
  margin-right: 8px;
}

.pt_50 {
  padding-top: 50px;
}

.height100per {
  height: 100%;
}

.spaceBetween {
  justify-content: space-between;
}

.textDecorationUnset {
  text-decoration: unset;
}

.gap12 {
  gap: 12px;
}

.gap16 {
  gap: 16px;
}

.logoBrand {
  text-align: center;
  padding-bottom: 40px;
}

.logoBrand img {
  width: 70px;
}

.minHeight350 {
  min-height: 350px !important;
}

.whiteFrame {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(204, 204, 204, 0.69);
}

.frameHeader {
  border-bottom: 1px solid rgba(204, 204, 204, 0.69);
  padding: 12px 16px;
}

.frameHeader h5 {
  // font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #000;
  line-height: 1.5;
  margin: 0px;
  font-weight: 500;
}

.dashboardItem {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5px;
  padding: 12px;
  min-height: 170px;
  height: 100%;
}

.dashboardItem p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0px;
}

.dashboardItem h4 {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
}

.companyUser_Item {
  // background: linear-gradient(60deg, #ff8c21 13.4%, #f5576e 86.6%);
  // box-shadow: 0px 9.62963px 34.4px rgba(0, 0, 0, 0.0425185),
  //   0px 5px 17.55px rgba(0, 0, 0, 0.035),
  //   0px 2.03704px 8.8px rgba(0, 0, 0, 0.0274815),
  //   0px 0.462963px 4.25px rgba(0, 0, 0, 0.0168519);

  border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 12px;
    display: flex;
    // align-items: center;
    gap: 16px;
    cursor: pointer;
    // justify-content: space-between;
}
.iconRectangle{
  border-radius: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconRectangle img{
  width: 34px;
}
.row.companyUserparent .col-md-4:first-child .iconRectangle{
  background-color: #FF6D60 !important;
}
.row.companyUserparent .col-md-4:nth-child(2) .iconRectangle {
    background-color: #F7D060 !important;
}

.row.companyUserparent .col-md-4:nth-child(3) .iconRectangle {
    background-color: #D14D72 !important;
}

// .totalemailsend h5 {
//   // font-family: "Poppins", sans-serif;
//   font-size: 14px;
//   line-height: 1.5;
//   font-weight: 500;
//   margin: 0px;
//   color: #fff;
// }

// .totalemailsend h2 {
//   // font-family: "Poppins", sans-serif;
//   font-size: 34px;
//   line-height: 1.5;
//   font-weight: 600;
//   margin: 0px;
//   color: #fff;
//   text-align: center;
// }

.dashboardItem img {
  width: 100%;
}

.dashboardItem h5 {
  // font-family: "Poppins", sans-serif;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0px;
}

.width_34 {
  width: 34px !important;
}

.width_20 {
  width: 20px !important;
}
.width_24 {
  width: 24px !important;
}
.mb_34 {
  margin-bottom: 34px !important;
}

.alignItem_center {
  align-items: center;
}

.recent_activity {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user_profile {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.user_profile .mat-mdc-icon-button {
  padding: 0px !important;
}

.user_profile img.db_img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}

.user_profile p {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  text-transform: capitalize;
  // font-family: "Poppins", sans-serif;
}

.user_profile .mat-mdc-menu-item {
  font-size: 14px;
  font-weight: 400;
}

.userdropdown .mat-menu-content:not(:empty) {
  padding: 0px !important;
}

.userdropdown .mat-icon {
  margin-right: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: #878a99;
}

.userdropdown .mat-mdc-menu-item-text {
  font-weight: 300 !important;
  font-size: 14px !important;
}

.userdropdown .mat-mdc-menu-item .mat-icon {
  margin-right: 0px !important;
}

.userdropdown .mat-mdc-menu-item {
  min-height: unset;
  line-height: 1.5;
  padding: 8px 14px;
  // font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  gap: 8px;
}

.next_step_slider ul {
  display: block;
  padding-left: 0px;
  margin-bottom: 20px;
}

.next_step_slider ul li {
  list-style: none;
  display: flex;
  height: 100%;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  gap: 15px;
  padding-bottom: 24px;
}

.next_step_slider ul li a {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-decoration: none;
  background-color: #52b141;
  position: relative;
  z-index: 999;
}

.next_step_slider ul li h5 {
  // font-family: "Poppins", sans-serif;

  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  margin: 0px;
}

.next_step_slider ul li p {
  font-size: 14px;
  line-height: 1.5;
  // font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0px;
}

.next_step_slider ul li span {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #969696;
}

.next_step_slider ul li:after,
.next_step_slider ul li:before {
  position: absolute;
  content: "";
  top: 21px;
  bottom: 0px;
  left: 10px;
  height: 100%;
  border-left: 1px dashed #f36b24;
}

.next_step_slider ul li:last-child:after,
.next_step_slider ul li:last-child:before {
  content: unset;
}

.next_step_slider ul li:last-child {
  height: unset;
}

.next_step_slider ul li:last-child a::before,
.next_step_slider ul li:last-child a:after {
  content: unset !important;
}

// .commonInput .mat-select{
// padding:8px 12px !important ;
// }

.commonInput {
  width: 100%;
}

.commonInput
  .mdc-text-field--outlined:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-notched-outline__leading,
.commonInput
  .mdc-text-field--outlined:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-notched-outline__notch,
.commonInput
  .mdc-text-field--outlined:not(
    .mdc-text-field--disabled
  ).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #f5f5f5 !important;
}

.commonInput
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.commonInput
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.commonInput
  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #f5f5f5 !important;
}

.commonInput .mdc-text-field--outlined {
  height: 40px;
  background: #f5f5f5;
  border-radius: 0.25rem;
}

.commonInput .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(0px) !important;
}

.commonInput .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  border-radius: 0.25rem;
}

.commonInput
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: #f5f5f5;
}

.commonInput
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-flex
  .mat-mdc-floating-label {
  top: 50%;
}

.commonInput .mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  min-height: 40px !important;
}

.commonInput .mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ced4da !important;
}

.commonInput .mat-mdc-select {
  //  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
}

.commonInput .mat-mdc-select-trigger {
  height: 100%;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background-color: #f36b24 !important;
}

.mdc-list-item__primary-text {
  color: #000 !important;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
}

.Maininput {
  font-family: "Poppins", sans-serif;
  display: block;
  width: 100%;
  padding: 0px 12px !important;
  outline: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5 !important;
  border-radius: 0.25rem;
  height: 40px;
}

.selectDropdownInput {
  font-family: "Poppins", sans-serif;
  display: block;
  width: 100%;
  padding: 0px 12px !important;
  outline: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5 !important;
  border-radius: 0.25rem;
  height: 40px;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
  .mdc-notched-outline__trailing {
  border-color: #ced4da !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-width: 1px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-notched-outline__trailing {
  border-color: #ced4da !important;
}

.customNgselect ng-select {
  // font-family: "Poppins", sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 12px !important;
  outline: unset;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid transparent !important;
  border-radius: 0.25rem;
  height: 40px;
}

.ng-dropdown-panel-items {
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.ng-option {
  padding: 8px 12px;
  font-size: 14px;
}

.ng-option:hover {
  background: rgba(0, 0, 0, 0.04);
}

.materialSearch .mdc-text-field--outlined {
  height: 40px !important;
}

.materialSearch
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mat-mdc-form-field-infix {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.materialSearch .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.RoyalFlycatcherCrest_btn {
  // background-color: #F36B24;
  // border: 1px solid #F36B24;
  background-color: #f36b24;
  border: 1px solid #f36b24;

  color: #fff;
}

.RoyalFlycatcherCrest_Clr {
  color: #f36b24 !important;
}

.DeepBreath_Clr {
  color: #24255c !important;
}

.rebellionRed_btn {
  background-color: #cc0905;
  border: 1px solid #cc0905;
  color: #fff;
}

.MainBtn {
  // font-family: "Poppins", sans-serif;

  max-width: 100%;
  // padding: 0px 16px;
  padding: 0px 24px;
  height: 40px;
  outline: unset;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  // border-radius: .25rem;
  border-radius: 97.403px;
  cursor: pointer;
}

.MainBtn:hover {
  opacity: 0.8;
}

.DeepBreath_btn {
  background-color: #24255c;
  border: 1px solid #24255c;
  color: #fff;
}

.CarbonFootprint_btn {
  background-color: #798089;
  border: 1px solid #798089;
  color: #fff;
}

img.imageplaceholder {
  border: 1px solid #ced4da;
  width: 150px;
  height: 150px;
  border-radius: 5px;
  object-fit: contain;
  padding: 5px;
}

.fontWeight600 {
  font-weight: 600;
}

.fontWeight400 {
  font-weight: 400;
}

.fontWeight300 {
  font-weight: 300;
}

.fontWeight500 {
  font-weight: 500;
}

textarea {
  // font-family: "Poppins", sans-serif;

  display: block;
  width: 100%;
  padding: 14px;
  outline: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 0.25rem;
}

.height_115 {
  height: 115px;
}

// radio button start

.prefer_method {
  align-items: center;
  display: flex;
}

.prefer_method label {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: center;
}

.prefer_method input[type="radio"] + label {
  position: relative;
}

.prefer_method input[type="radio"] + label::before {
  /* Outer Circle of radio button */
  border: 1px solid #ced4da;
  content: " ";
  display: inline-block;
  margin-right: 9px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: border 0.15s ease-in-out;
  position: relative;
  top: 0px;
}

.prefer_method input[type="radio"] + label::after {
  /* Inner Circle of radio button */
  border: 0px solid transparent;
  content: " ";
  // background: transparent;
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  left: 0.5px;
  top: 0.5px;
  transition: border 0.15s ease-in-out;
}

.prefer_method input[type="radio"] {
  display: none;
}

/* When button is active */
.prefer_method input[type="radio"]:checked + label::after {
  background: url("./assets/images/new_img/checkbox_tick.svg");
  background-repeat: no-repeat;
  background-size: 20px;
}

.prefer_method input[type="radio"]:checked + label::before {
  border: 1px solid #f36b24;
  background-color: #f36b24;
}

.prefer_method input[type="radio"]:checked + label {
  color: #000;
}

// radio button end
.marginlr_auto {
  margin-left: auto;
  margin-right: auto;
}

.smallCheckBox {
  display: block;
}

.smallCheckBox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
}

.smallCheckBox label {
  position: relative;
  left: 0px;
  padding: 0px;
  margin: 0px;
  color: #495057;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
  text-transform: unset;
}

.smallCheckBox label:before {
  content: "";
  border: 2px solid #ced4da;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 8px;
}

.smallCheckBox input:checked + label:after {
  content: "";
  background: url("./assets/images/new_img/tick_icon.svg");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 2.5px;
  left: 1.5px;
  width: 15px;
  height: 15px;
}

.smallCheckBox input[type="checkbox"]:checked + label {
  color: #495057;
}

.smallCheckBox input[type="checkbox"]:checked + label:before {
  transform: scale(1);
  background-color: #24255c;
  border-color: #24255c;
}

.labelText {
  // font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #212529;
  line-height: 1.5;
  margin-bottom: 8px;
  display: block;
}

mat-row,
mat-footer-row {
  min-height: unset !important;
}

.custom_materialTab .mat-tab-header {
  border-bottom: 1px solid #e9ebec !important;
}

.custom_materialTab .mdc-data-table__cell {
  padding: 12px 16px !important;
  border-bottom-color: #ccc !important;
  color: #777;
  font-size: 14px;
  font-weight: 500;
}

.custom_materialTab .mat-tab-label-active img {
  filter: brightness(0) saturate(100%) invert(41%) sepia(96%) saturate(2354%)
    hue-rotate(8deg) brightness(101%) contrast(105%);
}

.custom_materialTab .mat-tab-body {
  border-top: unset !important;
  border: 1px solid #e9ebec;
  padding: 16px;
}

.custom_materialTab .mat-tab-label {
  // font-family: "Poppins", sans-serif;
  font-size: 16px;
  text-decoration: unset;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid transparent;
  border-bottom: unset !important;
  background: transparent !important;
  border-radius: 8px 8px 0px 0px;
  height: unset !important;
  opacity: unset !important;
}

.custom_materialTab .mat-tab-label-active {
  color: #f36b24 !important;
  font-weight: 500;
  background-color: #fff !important;
  border: 1px solid #e9ebec !important;
  border-bottom-color: transparent !important;
}

.custom_materialTab .mat-ink-bar {
  position: unset !important;
  bottom: 0 !important;
  height: unset !important;
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

// New Material tab start
.mat-mdc-header-row {
  font-family: unset !important;
  min-height: unset !important;
}

.mat-mdc-row,
.mdc-data-table__content {
  font-family: unset !important;
}

.custom_materialTab .mat-mdc-tab-labels {
  border-bottom: 1px solid #ccc !important;
  gap: 16px;
  padding-bottom: 12px;
}

.custom_materialTab .mdc-tab.mat-mdc-tab {
  flex-grow: 0 !important;
  font-size: 16px;
  text-decoration: unset;
  font-weight: 500;
  line-height: 1.5;
  color: #24255c;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 14px 12px;
  position: relative;
  // border-radius: 8px;
  border: 1px solid transparent;
  background: transparent !important;
  height: unset !important;
  opacity: unset !important;
  font-family: unset !important;
}

.custom_materialTab .mat-mdc-tab .mdc-tab__text-label {
  color: #24255c !important;
}

.custom_materialTab .mat-mdc-tab .mdc-tab__text-label img {
  filter: brightness(0) saturate(100%) invert(14%) sepia(51%) saturate(1343%)
    hue-rotate(210deg) brightness(95%) contrast(99%);
}

.custom_materialTab .mat-mdc-tab.mdc-tab--active {
  font-weight: 500;
  background-color: #f5f5f5 !important;
  border: 1px solid #f5f5f5 !important;
  border-bottom-color: transparent !important;
  border-radius: 8px;
}

.custom_materialTab .mat-mdc-tab.mdc-tab--active img {
  // filter: brightness(0) saturate(100%) invert(41%) sepia(96%) saturate(2354%) hue-rotate(8deg) brightness(101%) contrast(105%);
  filter: brightness(0) saturate(100%) invert(14%) sepia(51%) saturate(1343%)
    hue-rotate(210deg) brightness(95%) contrast(99%);
}

.custom_materialTab .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #24255c !important;
}

.custom_materialTab .mat-mdc-tab .mdc-tab__ripple::before {
  content: unset !important;
}

.custom_materialTab .mat-mdc-tab .mdc-tab-indicator__content--underline {
  border: unset !important;
}

.custom_materialTab .mat-mdc-tab-body-wrapper {
  // border: 1px solid #e9ebec;
  border-top: unset;
}

.custom_materialTab .mat-mdc-tab-body-content {
  padding: 16px !important;
}

.table_wrapper {
  background-color: #fff;
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.table_wrapper table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.table_wrapper table thead {
  background-color: #f4f4f7;
  border-radius: 10px;
}

.table_wrapper table thead tr th {
  // font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding: 12px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
  color: #252525;
  // border-bottom: 1px solid #CCC;
  border-bottom: unset;
  // color: #8A8A8D;
  position: relative;
}

.table_wrapper table thead tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table_wrapper table thead tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table_wrapper table tbody tr td {
  // font-family: "Poppins", sans-serif;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0px;
  border-bottom: 1px solid #ccc;
  color: #777;
}

table.table-striped {
  padding: 16px 0px;
}

.custom_pagination ul {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.custom_pagination ul li:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: unset !important;
}

.custom_pagination ul li:last-child {
  border-left: unset !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.custom_pagination ul li {
  position: relative;
  display: block;
  font-size: 14px;
  color: #878a99;
  margin: 0px;
  padding: 6px 12px !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #e9ebec;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.custom_pagination ul li.current {
  background: #405189 !important;
  border: 1px solid #405189;
  color: #fff;
  // padding: 6px 12px;
}

.custom_pagination ul li:hover {
  background: #e6e6e6;
  border: 1px solid #e6e6e6;
}

.custom_pagination ul li a {
  padding: 0px !important;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: unset !important;
}

.custom_pagination .pagination-previous::before,
.custom_pagination .pagination-next::after {
  content: unset !important;
}

.custom_pagination ul li.disabled {
  color: #878a99 !important;
  padding: 6px 12px;
}

.emptyButton {
  background-color: transparent;
  border: 1px solid transparent;
  outline: unset;
  padding: 0px;
  cursor: pointer;
}

.mat-sort-header-container {
  gap: 10px;
}

.mat-tab-body-content {
  overflow: unset !important;
}

.width_230 {
  width: 230px;
}

.width_120 {
  width: 120px;
}

.ml_auto {
  margin-left: auto !important;
}

.dustyChimney_filter {
  filter: brightness(0) saturate(100%) invert(56%) sepia(7%) saturate(568%)
    hue-rotate(193deg) brightness(96%) contrast(87%);
}

.inputGroup {
  display: flex;
}

.inputgroupText {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 12px;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #ced4da;
  border-right: unset;
  border-radius: 5px 0px 0px 5px;
  height: 40px;
}

.inputGroup input {
  border-radius: 0px 5px 5px 0px;
  border-left: unset !important;
  background-color: transparent;
  border: 1px solid #ced4da;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  color: #212529;
  height: 40px;
  padding: 0px 12px 0px 12px;
  outline: unset;
}



h2.heading24 {
  // font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0px;
  color: #000;
}

h2.heading16 {
  // font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #000;
}

.mat-form-field {
  width: 100%;
}

.mat-paginator-outer-container {
  display: unset !important;
}

.mat-paginator {
  background: unset !important;
}

.mat-paginator-page-size-label,
.mat-paginator-range-label {
  //  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #878a99;
}

.mat-paginator-page-size {
  margin: 0px !important;
}

input.mat-input-element {
  margin-top: 0px !important;
}

.FrameWhite {
  box-shadow: 0px 4px 12px rgb(0 0 0 / 12%);
  background-color: #fff;
  border-radius: 8px;
}

.p16 {
  padding: 16px;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px 0px 24px !important;
}

.mat-form-field-wrapper {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.materialTable_wrapper table {
  width: 100%;
  border-collapse: separate;
}

.materialTable_wrapper mat-table {
  margin-bottom: 12px;
}

.materialTable_wrapper thead {
  background: #f4f4f7 !important;
}

.materialTable_wrapper .mat-table {
  border-radius: 8px;
}

.materialTable_wrapper thead tr th {
  border-bottom: unset !important;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 16px;
}

.materialTable_wrapper thead tr {
  height: unset !important;
}

.materialTable_wrapper thead tr th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.materialTable_wrapper thead tr th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.materialTable_wrapper .mat-header-row {
  width: 100%;
  min-height: unset !important;
  border-bottom-color: #e9ebec;
  height: unset;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.materialTable_wrapper .mat-header-cell {
  // font-family: "Poppins", sans-serif;
  font-size: 14px;
  padding: 12px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
  border-right: unset !important;
  // border-bottom: 1px solid #e9ebec;
  position: relative;
  white-space: nowrap;
}

.materialTable_wrapper .mat-cell {
  // font-family: "Poppins", sans-serif;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0px;
  // border-bottom: 1px solid #e9ebec;
}

.materialTable_wrapper mat-row {
  border-color: #e9ebec !important;
}

.materialTable_wrapper mat-row:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.materialTable_wrapper tbody td {
  padding: 12px px 16px !important;
  border-bottom-color: #ccc !important;
  color: #777;
  font-size: 15px;
  font-weight: 500;
}

// fullmatTable_wrapper

.fullmatTable_wrapper .mat-mdc-header-row {
  background-color: #f4f4f7;
  border-radius: 10px;
  min-height: unset !important;
}

.fullmatTable_wrapper .mat-mdc-header-row mat-header-cell:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.fullmatTable_wrapper .mat-mdc-header-row mat-header-cell:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.fullmatTable_wrapper .mat-mdc-header-row .mat-mdc-header-cell {
  font-size: 14px;
  font-weight: 600;
  padding: 12px 16px;
  border-bottom: unset;
}

.fullmatTable_wrapper .mat-header-cell:first-of-type {
  padding-left: 16px !important;
}

.fullmatTable_wrapper .mat-mdc-cell {
  font-size: 14px;
  padding: 12px 16px;
  font-weight: 500;
  line-height: 1.5;
  margin: 0px;
  color: #777;
  border-right: unset !important;
  position: relative;
  word-break: break-all;
}

.mat-cell,
.mat-footer-cell {
  border-right: unset;
}

.invalid-feedback {
  font-size: 12px !important;
  font-weight: 600;
  margin-top: 8px;
  display: block;
}

.checkbox-validate {
  font-size: 12px !important;
  font-weight: 600;
  margin-top: 8px;
  display: block;
  color: #dc353b;
  margin-top: 25px !important;
}

//loader css
.loading {
  height: 0;
  width: 0;
  padding: 20px;
  border: 15px solid #f36b24;
  border-right-color: #f36b24;
  border-radius: 32px;
  -webkit-animation: rotate 1s infinite linear;
  /* left, top and position just for the demo! */
  position: absolute;
  left: 50%;
  top: 50%;
}

@-webkit-keyframes rotate {
  /* 100% keyframe for  clockwise.
     use 0% instead for anticlockwise */
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.paragraph_text {
  // font-family: "Poppins", sans-serif;

  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  margin: 0px;
  color: #777;
}

.desc_text {
  // font-family: "Poppins", sans-serif;

  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  margin: 0px;
  color: #000;
}

.dynamicStyle {
  // font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  margin: 0px;
  color: #878a99;
}

.linktext {
  color: #24255c;
}

ol.listText {
  padding-left: 20px;
  margin: 0px;
}

ol.listText li {
  font-size: 14px;
  line-height: 1.8;
  font-weight: 400;
  margin-bottom: 12px;
  color: #878a99;
  // font-family: "Poppins", sans-serif;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}

.importContact {
  // font-family: "Poppins", sans-serif;
  cursor: pointer;
  margin: 0px;
  border: 1px dashed rgba(0, 0, 0, 0.54);
  border-radius: 5px;
  padding: 8px 6px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400 !important;
}

.UFOGreenColor {
  background-color: #3fc979;
}

.CarbonFootprintColor {
  background-color: #798089;
}

.RoyalGreenColor {
  background-color: #027a77;
}

.commonButton {
  // font-family: "Poppins", sans-serif;

  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #fff;
  display: flex;
  font-size: 14px;
  height: 36px;
  justify-content: center;
  letter-spacing: 0.7px;
  line-height: 1.5;
  padding: 0 16px;
  text-decoration: none;
  text-decoration: initial;
  width: 100%;
}

.vermilionBird_color {
  color: #f44336 !important;
}

.modalHeader {
  display: flex;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: #f3f3f9;
}

.modalHeader h3 {
  // font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #000;
}

.mat-elevation-z4 {
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}

.mainMenu {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  // font-family: "Poppins", sans-serif;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
}

.subMunu {
  padding: 15px 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  // font-family: "Poppins", sans-serif;

  margin-left: 30px;
  // border: 1px solid #f36b24;
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #777;
}

.example-box {
  // padding: 20px 10px;
  // border-bottom: solid 1.5px #f36b24;
  color: rgba(0, 0, 0, 0.87);
  // display: flex;
  // flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.mat-chip.mat-standard-chip {
  background-color: #f2f2f2;
}

.mat-chip-list-wrapper {
  margin: 12px 0px !important;
}

// .mat-chip-list-wrapper .mat-chip{
//   font-family: "Poppins", sans-serif;
// }
.flexWrap {
  flex-wrap: wrap;
}

.clndrBtn_group {
  display: flex;
  align-items: center;
}

.clndrBtn_group .active {
  background-color: #24255c;
  color: #fff;
}

.clndrBtn {
  // font-family: "Poppins", sans-serif;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  // border-radius: 0.25rem;
  color: #000;
  background-color: #f3f3f9;
  // border-color: #e1e1e1 ;
  cursor: pointer;
}

.clndrBtn:hover {
  background-color: #24255c;
  color: #fff;
}

.clndrBtn:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-right: 1px solid #e1e1e1;
}

.clndrBtn:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: 1px solid #e1e1e1;
}

.clndrBtn_outline {
  // font-family: "Poppins", sans-serif;
  // border-top: 1px solid #e1e1e1;
  // border-bottom: 1px solid #e1e1e1;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #000;
  background-color: #f3f3f9;
  // border-color: #ced4da !important;
}

h3.date_text {
  // font-family: "Poppins", sans-serif;
  font-size: 18px;
  line-height: 1.5;
  margin: 0px;
  font-weight: 500;
  text-transform: uppercase;
}

.cal-month-view .cal-header .cal-cell {
  padding: 10px 0px;
  border: 1px solid #e1e1e1;
  font-weight: 500;
  font-size: 16px;
  border-bottom: unset;
  border-right: unset;
}

.cal-month-view .cal-header .cal-cell:last-child {
  border-right: 1px solid #e1e1e1 !important;
}

.cal-month-view .cal-day-number {
  padding: 16px 16px 0px;
  margin: 0px !important;
  font-size: 14px;
  color: #000;
  opacity: unset;
}

.example-container {
  overflow-x: scroll !important;
}

.mat-dialog-container::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.mat-dialog-container::-webkit-scrollbar-track {
  background: #e5e7eb;
}

/* Handle */
.mat-dialog-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.mat-dialog-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

h5.groupListText {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #878a99;
}

img.header_img {
  width: 130px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

h5.groupListText span {
  color: #000 !important;
  float: right;
}

.circleImg_Center {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #cccccc;
  margin: 0 auto 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addTemplateimg {
  border-radius: 8px;
  width: 230px;
  height: 230px;
  object-fit: cover;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  top: unset !important;
  padding: unset !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 8px 12px !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-input {
  padding: unset !important;
}

.loginLogo {
  width: 85px;
}

.accountWrapper {
  background: url("./assets/images/new_img/login_wrapper.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  position: relative;
}

.accountWrapper::before {
  content: "";
  background: #050514;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.7;
}

.loginWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px;
  // padding: 220px 20px 20px;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
  /* box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3); */
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  height: 530px;
  border: unset;
}

.box__image {
  // position: absolute;
  // top: 0;
  // left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  // height: 200px;
  /* object-fit: cover; */
  /* object-position: 0 bottom; */
  transition: 0.4s ease-in-out;
  overflow: hidden;
  /* background: #333; */
  // background: url("../../../assets/images/new_img/MicrosoftTeams-image.png");
  background: url("./assets/images/new_img/loginLeft_img.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px;
}

.loginWrapper > div {
  flex: 1 1 0;
}

// .custom_checkbox input {
//   display: none;
// }

// .custom_checkbox span {
//   border: 3px solid #7e8a94;
//   float: right;
//   height: 20px;
//   width: 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .custom_checkbox:hover span,
// .custom_checkbox input:checked + span {
//   border: 3px solid #43D8B0;
// }

// .custom_checkbox input:checked + span:before {
//   content: "✔";
// }

// .mr_30{
//   margin-right: 30px;
// }

@media (min-width: 992px) {
  .dropdown-menu .dropdown-toggle:after {
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }

  .dropdown-menu .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
  }

  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .nav-item .submenu-left {
    right: 100%;
    left: auto;
  }

  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    padding: 4px 12px;
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute !important;
    background-color: #fff;
    // min-width: 160px;
    // height: 300px;
    // overflow-y: scroll;
    // box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    // padding: 12px 16px;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
    // padding: 0.5rem 1.5rem;
    z-index: 99999;
    top: 50px;
  }
}

.nav-bar-menu {
  margin: 0 auto;
  // width: 100vw;
  // box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0.5rem 1rem;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.innerMenu {
  padding: 0px;
  list-style-type: none;
  margin: 0px;
}

.innerMenu li {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.innerMenu li:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

// /* common Fonts */

// //   @font-face {
// //     font-family: "Conv_MoonTime-Regular";
// //     src: url("../fonts/MoonTime-Regular.eot");
// //     src: local("☺"), url("../fonts/MoonTime-Regular.woff") format("woff"),
// //       url("../fonts/MoonTime-Regular.ttf") format("truetype"),
// //       url("fonts/MoonTime-Regular.svg") format("svg");
// //     font-weight: normal;
// //     font-style: normal;
// //   }

.justifyCenter {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.templateSection {
  display: flex;
}

/* template 2 */
.responsivesvg svg {
  width: 100%;
  height: auto;
}

.template14header {
  z-index: 99;
  font-size: 80px;
  position: relative;
  font-family: "Conv_MoonTime-Regular";
  bottom: -200px;
  line-height: 60px;
}

.container {
  // width: 970px;
  margin: 0 auto;
}

.arrowsvg {
  transform: rotate(45deg);
  width: 300px;
  margin-left: -44px;
}
.pbottom70 {
  padding-bottom: 70px;
}

@media only screen and (max-width: 900px) {
  .container {
    width: 100%;
    margin: 0 auto;
  }

  .resImage img {
    width: 100%;
    height: auto;
  }
}

.popupContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);
  // display: none;
  z-index: 999999;
}

.plr {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer {
  bottom: 0;
  position: absolute;
}

.footerMenu {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;
}

.pre-footer-links {
  padding: 0;
  list-style-type: none;
}

.pre-footer-links a {
  color: #333;
  display: inline-block;
  font-size: 12px;
  padding: 10px 0;
  text-transform: uppercase;
}

.pre-footer {
  background-color: #e4dedf;
  padding-top: 30px;
}

.pre-footer .text-small {
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
  margin: 10px 0;
  color: #333 !important;
}

footer {
  background-color: #c6c0b6;
  padding-top: 25px;
  padding-bottom: 25px;
}

footer .social-links {
  list-style-type: none;
  display: flex;
  margin: 0;
}

footer .social-links a,
.wholesale-text .social-links a {
  margin-left: 15px;
  color: #333;
  font-size: 20px;
}

// Editor Link Button Background Color
.whiteclr {
  background-color: #ffffff !important;
}

.silverclr {
  background-color: #c0c0c0 !important;
}

.grayclr {
  background-color: #808080 !important;
}

.blackclr {
  background-color: #000000 !important;
}

.redclr {
  background-color: #ff0000 !important;
}

.maroonclr {
  background-color: #800000 !important;
}

.yellowclr {
  background-color: #ffff00 !important;
}

.oliveclr {
  background-color: #808000 !important;
}

.limeclr {
  background-color: #00ff00 !important;
}

.oliveclr {
  background-color: #808000 !important;
}

.limeclr {
  background-color: #00ff00 !important;
}

.greenclr {
  background-color: #008000 !important;
}

.aquaclr {
  background-color: #00ffff !important;
}

.tealclr {
  background-color: #008080 !important;
}

.blueclr {
  background-color: #0000ff !important;
}

.navyclr {
  background-color: #000080 !important;
}

.fuchsiaclr {
  background-color: #ff00ff !important;
}

.purpleclr {
  background-color: #800080 !important;
}

/**
 * CONTENTS
 *
 * BASE
 * Box-sizing............Better default `box-sizing`.
 * Media.................WP specific img aligns and iframe styling.
 * Typography............@fontfaces, base text and vertical rhythem setup.
 *
 * COMPONENTS
 * Wrappers..............Wrapping and constraining elements.
 * Grid System...........Bootstrap based grid.
 * Clearfix..............Properly clear floats.
 *
 * Objects
 * Icons.................Icon Elements.
 * Images................Image Elements.
 * Buttons...............Button elements.
 * Tables................Table Styles.
 * Forms.................Form Elements.
 * Breadcrumbs...........Breadcrumbs.
 * Pagination............Pagination.
 * Sliders/Carousels.....Sliders/Carousels.
 * Posts.................Post content for loop on blog homepage, search page, archives, etc.
 * Tabs..................Tabbed content.
 * Social Share..........Social share buttons
 *
 * UI
 * Page head.............The main page header.
 * Navigation............Navigation elements.
 * Masthead..............Page title/image/slideshow header block.
 * Sidebar...............Sidebar elements.
 * Page footer...........The main page footer.
 *
 * TRUMPS
 * Images................Round, Circle, Square Images.
 * Visiblity.............Make items visible.
 * Hiding................Make items invisible/hidden.
 * Screen Readers........Display for screen readers.
 * Print.................Display for printing.
 * Clears................Clearing floats.
 * Text alignment........Align text.
 * Font weights..........Adjust font weights.
 * Borders...............Add borders.
 * Add/remove margins....Remove margins.
 * Add/remove paddings...Remove padding.
 * Positioning...........float, center, and stick items.
 */

/*------------------------------------*\
    BASE
\*------------------------------------*/

/**
 * Box-sizing
 */

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/**
* Media
*
* These selectors are hard cast because they are only used
* by wordpress wyswyg when adding images to content
*/

.wp-caption.alignright,
.wp-caption.alignleft,
.wp-caption.alignnone,
.wp-caption.aligncenter {
  margin: 0;
  width: auto !important;
  /* to overwrite inline widths */
}

img.alignright,
.wp-caption.alignright img,
img.alignleft,
.wp-caption.alignleft img {
  height: auto;
  max-width: 50%;
}

img.alignnone,
.wp-caption.alignnone img,
img.aligncenter,
.wp-caption.aligncenter img {
  height: auto;
  max-width: 100%;
}

img.alignnone,
.wp-caption.alignnone,
img.aligncenter,
.wp-caption.aligncenter {
  margin: 0 0 22px 0;
}

img.alignright,
.wp-caption.alignright {
  float: right;
  margin: 0 0 22px 30px;
}

img.alignleft,
.wp-caption.alignleft {
  float: left;
  margin: 0 30px 22px 0;
}

img.aligncenter,
.wp-caption.aligncenter img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

iframe {
  max-width: 100%;
}

.fluid-width-video-wrapper {
  width: 930px;
  margin: auto;
}

/**
* Typography
*/

/*
- Please set up line-heights in ems
- Set up typography styling based on styles found in .xd file under Assets > Character Styles
*/

body {
  overflow-anchor: none;
}

.nowrap {
  display: inline-block;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  color: #08326a;
}

h1,
.h1 {
  font-size: 28px;
  line-height: 1.25em;
}

h2,
.h2 {
  font-size: 24px;
  line-height: 1.25em;
  margin-bottom: 20px;
}

h3,
.h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}

h4,
.h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

p {
  color: #141414;
  margin-bottom: 24px;
}

p,
ul,
ol {
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 500;
}

// ul,
// ol {
//   padding-left: 35px;
// }

// ul li,
// ol li {
//   padding-left: 30px;
//   margin-bottom: 16px;
//   position: relative;
// }

// ol {
//   padding-left: 50px;
// }

// ol li {
//   list-style: decimal;
//   padding-left: 14px;
// }

a {
  word-break: break-word;
  /* force link text to overflow when too long for container */
}

@media (min-width: 768px) {
  h2,
  .h2 {
    font-size: 34px;
  }

  h1,
  .h1 {
    font-size: 42px;
    line-height: 1.25em;
  }
}

@media (min-width: 1500px) {
  p,
  ul,
  ol {
    font-size: 16px;
  }

  h1,
  .h1 {
    font-size: 44px;
    line-height: 1.25em;
  }

  h2,
  .h2 {
    font-size: 42px;
  }

  h3,
  .h3 {
    font-size: 28px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }
}

/*------------------------------------*\
  COMPONENTS
\*------------------------------------*/

/**
* Wrappers
*/

.container,
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 22px;
  padding-right: 22px;
}

@media (min-width: 600px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1025px) {
  /*.container {
      max-width: 980px
  }*/
  .container {
    max-width: 1456px;
  }
}

/**
* Grid System
*
* Bootstrap v3.3.1 (http://getbootstrap.com)
* Copyright 2011-2014 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* --------------------------------------------------------------------------
* Base setup 20px gutters
*
* Phones         - xxs - <  600px    ** Default **
* Small Tablets  - xs - >=  600px
* Tablets        - sm - >= 768px
* Desktop        - md - >= 1025px
* Large Desktop  - lg - >= 1200px
* --------------------------------------------------------------------------
* Learn more here: http://getbootstrap.com/css/#grid
* -------------------------------------------------------------------------- */

.row {
  margin-left: -16px;
  /* TODO: Default grid. Update if different in prototypes. */
  margin-right: -16px;
}

[class*="col-"] {
  position: relative;
  min-height: 1px;
  padding-left: 16px;
  /* TODO: Default grid. Update if different in prototypes. */
  padding-right: 16px;
}

/* Extra Extra small devices (devices, less than 600px) */

[class*="col-xxs-"] {
  float: left;
}

.col-xxs-12 {
  width: 100%;
}

.col-xxs-11 {
  width: 91.66666667%;
}

.col-xxs-10 {
  width: 83.33333333%;
}

.col-xxs-9 {
  width: 75%;
}

.col-xxs-8 {
  width: 66.66666667%;
}

.col-xxs-7 {
  width: 58.33333333%;
}

.col-xxs-6 {
  width: 50%;
}

.col-xxs-5 {
  width: 41.66666667%;
}

.col-xxs-4 {
  width: 33.33333333%;
}

.col-xxs-3 {
  width: 25%;
}

.col-xxs-2 {
  width: 16.66666667%;
}

.col-xxs-1 {
  width: 8.33333333%;
}

.col-xxs-pull-12 {
  right: 100%;
}

.col-xxs-pull-11 {
  right: 91.66666667%;
}

.col-xxs-pull-10 {
  right: 83.33333333%;
}

.col-xxs-pull-9 {
  right: 75%;
}

.col-xxs-pull-8 {
  right: 66.66666667%;
}

.col-xxs-pull-7 {
  right: 58.33333333%;
}

.col-xxs-pull-6 {
  right: 50%;
}

.col-xxs-pull-5 {
  right: 41.66666667%;
}

.col-xxs-pull-4 {
  right: 33.33333333%;
}

.col-xxs-pull-3 {
  right: 25%;
}

.col-xxs-pull-2 {
  right: 16.66666667%;
}

.col-xxs-pull-1 {
  right: 8.33333333%;
}

.col-xxs-pull-0 {
  right: auto;
}

.col-xxs-push-12 {
  left: 100%;
}

.col-xxs-push-11 {
  left: 91.66666667%;
}

.col-xxs-push-10 {
  left: 83.33333333%;
}

.col-xxs-push-9 {
  left: 75%;
}

.col-xxs-push-8 {
  left: 66.66666667%;
}

.col-xxs-push-7 {
  left: 58.33333333%;
}

.col-xxs-push-6 {
  left: 50%;
}

.col-xxs-push-5 {
  left: 41.66666667%;
}

.col-xxs-push-4 {
  left: 33.33333333%;
}

.col-xxs-push-3 {
  left: 25%;
}

.col-xxs-push-2 {
  left: 16.66666667%;
}

.col-xxs-push-1 {
  left: 8.33333333%;
}

.col-xxs-push-0 {
  left: auto;
}

.col-xxs-offset-12 {
  margin-left: 100%;
}

.col-xxs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xxs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xxs-offset-9 {
  margin-left: 75%;
}

.col-xxs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xxs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xxs-offset-6 {
  margin-left: 50%;
}

.col-xxs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xxs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xxs-offset-3 {
  margin-left: 25%;
}

.col-xxs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xxs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xxs-offset-0 {
  margin-left: 0;
}

/* Extra small devices (phones, 600px and up) */

@media (min-width: 600px) {
  [class*="col-xs-"] {
    float: left;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-11 {
    width: 91.66666667%;
  }

  .col-xs-10 {
    width: 83.33333333%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-8 {
    width: 66.66666667%;
  }

  .col-xs-7 {
    width: 58.33333333%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-5 {
    width: 41.66666667%;
  }

  .col-xs-4 {
    width: 33.33333333%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-2 {
    width: 16.66666667%;
  }

  .col-xs-1 {
    width: 8.33333333%;
  }

  .col-xs-pull-12 {
    right: 100%;
  }

  .col-xs-pull-11 {
    right: 91.66666667%;
  }

  .col-xs-pull-10 {
    right: 83.33333333%;
  }

  .col-xs-pull-9 {
    right: 75%;
  }

  .col-xs-pull-8 {
    right: 66.66666667%;
  }

  .col-xs-pull-7 {
    right: 58.33333333%;
  }

  .col-xs-pull-6 {
    right: 50%;
  }

  .col-xs-pull-5 {
    right: 41.66666667%;
  }

  .col-xs-pull-4 {
    right: 33.33333333%;
  }

  .col-xs-pull-3 {
    right: 25%;
  }

  .col-xs-pull-2 {
    right: 16.66666667%;
  }

  .col-xs-pull-1 {
    right: 8.33333333%;
  }

  .col-xs-pull-0 {
    right: auto;
  }

  .col-xs-push-12 {
    left: 100%;
  }

  .col-xs-push-11 {
    left: 91.66666667%;
  }

  .col-xs-push-10 {
    left: 83.33333333%;
  }

  .col-xs-push-9 {
    left: 75%;
  }

  .col-xs-push-8 {
    left: 66.66666667%;
  }

  .col-xs-push-7 {
    left: 58.33333333%;
  }

  .col-xs-push-6 {
    left: 50%;
  }

  .col-xs-push-5 {
    left: 41.66666667%;
  }

  .col-xs-push-4 {
    left: 33.33333333%;
  }

  .col-xs-push-3 {
    left: 25%;
  }

  .col-xs-push-2 {
    left: 16.66666667%;
  }

  .col-xs-push-1 {
    left: 8.33333333%;
  }

  .col-xs-push-0 {
    left: auto;
  }

  .col-xs-offset-12 {
    margin-left: 100%;
  }

  .col-xs-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-xs-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-xs-offset-9 {
    margin-left: 75%;
  }

  .col-xs-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-xs-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-xs-offset-6 {
    margin-left: 50%;
  }

  .col-xs-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-xs-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-xs-offset-3 {
    margin-left: 25%;
  }

  .col-xs-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-xs-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-xs-offset-0 {
    margin-left: 0;
  }
}

/* Small devices (tablets, 768px and up) */

@media (min-width: 768px) {
  [class*="col-sm-"] {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
}

/* Medium devices (desktops, 1025px and up) */

@media (min-width: 1025px) {
  [class*="col-md-"] {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}

/* Large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  [class*="col-lg-"] {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
}

/**
* Clearfix
* Apply clearing without adding additional markup
*/

.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

/*--------------------------------------------------------------*\
  OBJECTS
  Objects are independent generic stylibf classes or UI peices.
  All styles for objects should be self contained.

  e.g. an object shouldn't rely on trump helpers to apply padding etc.
\*--------------------------------------------------------------*/

/**
* Buttons
*/

.btn-block {
  display: block;
  width: 100%;
}

.btn + .btn {
  margin-top: 5px;
  /* Add margin-top when 2 buttons are next to each other */
}

@media (min-width: 600px) {
  .btn + .btn {
    margin-top: 0;
    margin-left: 5px;
  }
}

/**
* Icons
*/

/* add icon code here */

/**
* Images
*/

@media (max-width: 767px) {
  :has(> .wysiwyg-img-right-half) {
    display: inline-flex;
    flex-direction: column-reverse;
  }

  :has(> .wysiwyg-img-left-half) {
    display: inline-flex;
    flex-direction: column;
  }

  :has(> .wysiwyg-img-right-half) .wysiwyg-img-right-half,
  :has(> .wysiwyg-img-left-half) .wysiwyg-img-left-half {
    align-self: center;
    margin-left: 0 !important;
    max-width: 100% !important;
  }
}

/* add icon code here */

/**
* Tables
*/

table {
  border-collapse: collapse;
}

/* Responsive Tables */

.table-wrap-outer {
  position: relative;
}

.table-wrap-inner {
  overflow: auto;
}

.table-wrap-outer table {
  margin: 0;
}

/* Need to resolve at a later date
.js-table-fade {
position: absolute;
top: 0;
right: 0;
width: 40px;
height: 100%;
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
}

.table-wrap-outer:after {
  content: '';
  position: absolute;
  right: -1px;
  top: 0;
  height: 100%;
  width: 80px;
background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
} */

@media (min-width: 600px) {
  .table-wrap-outer:after {
    display: none;
  }

  .table-wrap-inner {
    overflow: visible !important;
  }
}

/**
* Forms
*/

/* Removes default webkit form styling */

input:not([type="radio"]):not([type="checkbox"]),
button,
textarea {
  -webkit-appearance: none;
}

input[type="file"] {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  // display: inline-flex !important;
  padding: 0px;
  min-height: 1px !important;
  padding: 13px !important;
}

/* Removes inconsistent padding from Firefox buttons */

button::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border: none;
  padding: 0;
}

/* Hide reCaptcha badge */

.grecaptcha-badge {
  visibility: hidden;
}

/* Default Radio/Checkbox Style (if using CF7) */

.wpcf7-radio .wpcf7-list-item,
.wpcf7-checkbox .wpcf7-list-item {
  display: inline-block;
  margin-right: 15px;
  margin-bottom: 10px;
  /* incase items go to 2 lines */
}

.wpcf7-radio .wpcf7-list-item-label,
.wpcf7-checkbox .wpcf7-list-item-label {
  margin-left: 5px;
}

/* Choices - Select field styling */

.choices {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  font-size: 16px;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-open {
  overflow: initial;
}

.choices.is-disabled .choices__inner,
.choices.is-disabled .choices__input {
  background-color: #eaeaea;
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices [hidden] {
  display: none !important;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  background-color: #ffffff;
  margin: 0;
}

.choices[data-type*="select-one"] .choices__button {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  padding: 0;
  background-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: 0.25;
}

.choices[data-type*="select-one"] .choices__button:hover,
.choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  -webkit-box-shadow: 0px 0px 0px 2px #00bcd4;
  box-shadow: 0px 0px 0px 2px #00bcd4;
}

.choices[data-type*="select-one"]
  .choices__item[data-value=""]
  .choices__button {
  display: none;
}

.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #333333 transparent;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner,
.choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: 0.75;
  border-radius: 0;
}

.choices[data-type*="select-multiple"] .choices__button:hover,
.choices[data-type*="select-multiple"] .choices__button:focus,
.choices[data-type*="text"] .choices__button:hover,
.choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  color: #495057;
  background: #f3f2f2;
  padding: 0.375rem 0.75rem;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  min-height: 49px;
  overflow: hidden;
}

.is-focused .choices__inner,
.is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 9px 16px 9px 0;
  width: 100%;
}

[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00bcd4;
  border: 1px solid #00a5bb;
  color: #ffffff;
  word-break: break-all;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  visibility: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
  will-change: visibility;
}

.choices__list--dropdown.is-active {
  visibility: visible;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: 0.5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: 0.5;
}

.filterBy .choices {
  min-width: 200px;
}

/* @media (min-width: 600px) {
.choices__list--dropdown .choices__item--selectable {
  padding-right: 100px;
}
.choices__list--dropdown .choices__item--selectable:after {
  content: attr(data-select-text);
  font-size: 12px;
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
[dir="rtl"] .choices__list--dropdown .choices__item--selectable {
  text-align: right;
  padding-left: 100px;
  padding-right: 10px;
}
[dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
  right: auto;
  left: 10px;
}
} */

/* CF7 Validation (Replace if not using CF7) */

/* Individual field error messages */

/* Entire form error message */

.wpcf7-response-output {
  width: 100%;
  margin-top: 32px;
}

/* Styling for fields that have errors */

/**
* Breadcrumbs
*/
.heading-box {
  background: #080b36;
  border-top: 1px solid #08326a;
  padding: 60px 0;
}

.heading-box h1 {
  font-weight: bold;
  color: #fff;
  margin-bottom: 25px;
  margin-top: 0;
}

.heading-box ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.heading-box ul li {
  display: inline-block;
  color: #fff;
  padding-right: 10px;
  margin-bottom: 0;
  padding-left: 0;
}

.heading-box ul li a {
  color: #fff;
  text-decoration: none;
}

.heading-box ul li a:hover,
.heading-box ul li a:active,
.heading-box ul li a:focus {
  color: #009cdd;
}

.heading-box ul li.breadcrumb_last {
  color: #ff4e00;
  font-weight: 700;
}

.breadcrumbs li:before {
  content: "\e901";
  font-family: "icomoon";
  font-size: 12px;
  font-weight: 400;
  color: #009cdd;
  margin-right: 10px;
}

.breadcrumbs li:first-child:before {
  display: none;
}

.breadcrumbs li:not(:nth-last-child(2)),
.breadcrumbs li:after {
  display: none;
  visibility: hidden;
  content: none;
}

.breadcrumbs li a:before {
  content: "Go to ";
}

@media (min-width: 767px) {
  .breadcrumbs li a:before {
    content: none;
  }

  .breadcrumbs li:not(:nth-last-child(2)),
  .breadcrumbs li:after {
    display: inline-block;
    visibility: visible;
    content: initial;
  }

  .breadcrumbs li {
    font-size: 16px;
  }
}

/**
* Pagination
*/

.wp-pagenavi {
  margin-top: 50px;
}

.wp-pagenavi .pages {
  border: 0;
  margin-left: 0;
  padding-left: 0;
}

.wp-pagenavi span.current,
.wp-pagenavi .page,
.wp-pagenavi .nextpostslink,
.wp-pagenavi .previouspostslink {
  border: 1px solid #ddd;
  display: inline-block;
  padding: 6px 11px;
}

.wp-pagenavi span.current {
  background-color: #ddd;
  border-color: #ddd;
}

@media (min-width: 1025px) {
  .wp-pagenavi {
    margin-top: 70px;
  }
}

/**
* Sliders/Carousels
* Slick Slider - http://kenwheeler.github.io/slick/
*/

/* General styling */

.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slide,
.slick-arrow,
.slick-dots button,
.slick-slide:focus * {
  outline: none !important;
}

.slick-initialized .slick-slide {
  float: none;
  display: inline-block;
  vertical-align: middle;
}

/* Preload affect */

.slick-slider .slide {
  display: none;
}

.slick-slider .slide:first-child {
  display: block;
}

.slick-slider.slick-initialized .slide {
  display: block;
}

.js-slider-has-preloader {
  /* Add this class to your slider */
  min-height: 50px;
  position: relative;
}

.js-slider-has-preloader:before {
  //content: url("../img/loading.gif");
  /* Create and upload a loading gif to your image directory */
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  /* Update width based on gif size */
  height: 50px;
  /* Update height based on gif size */
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.js-slider-has-preloader-init:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

/* Slick Dots */

.slick-dots {
  padding: 0;
  margin: 0;
  list-style: none;
}

.slick-dots li {
  display: inline-block;
  cursor: pointer;
}

.slick-dots li:only-child {
  display: none;
}

.slick-dots li button {
  display: block;
  cursor: pointer;
  outline: none;
}

/* Custom styling per slider/carousel */

/**
* Posts
* Used on blog, category, archive and search results pages
*/

.post-item {
  border-top: 1px solid #ddd;
  padding-top: 30px;
  margin-top: 30px;
}

.post-item:first-of-type {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.blog-listing-container .post-item {
  border-top: 0;
  margin-top: 0;
}

.post-item__heading a {
  text-decoration: none;
}

.post-item .btn,
.post-item .btn-primary {
  margin-top: 30px;
}

.post-item__meta {
  font-size: 11px;
  color: #09326a;
  text-transform: uppercase;
  font-weight: 600;
}

.post-item__meta-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 8px;
}

@media (min-width: 1025px) {
  .post-item {
    padding-top: 50px;
    margin-top: 50px;
  }
}

/**
* Tabs
* In use on search.php template by default.
* Use as base for any other tabs on the site.
*/

.tabs {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 0;
  margin: 0;
}

.tabs:before,
.tabs:after {
  content: "";
  display: table;
}

.tabs:after,
.tab-content:after {
  clear: both;
}

.tab {
  list-style-type: none !important;
  margin: 0 5px -1px 0;
  padding: 0;
  float: left;
}

.tab > a {
  display: block;
  padding: 10px 35px;
  text-decoration: none;
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 1px 1px 0 0;
  color: inherit;
  font-weight: bold;
}

.tab-active > a {
  border-bottom: 1px solid #fff;
}

.tab-active > a,
.tab:hover > a,
.tab:focus > a {
  color: inherit;
  background: #fff;
  text-decoration: none;
}

// .tab-content {
//   padding: 20px;
//   border: 1px solid #ddd;
//   border-top: none;
//   display: none;
// }

/**
* Social Share
* Used in single.php by default
* Make any additional customizations here https://sharingbuttons.io/
*/

.social-share-row {
  margin: 0;
  padding: 0 !important;
  /* get rid of default <ul> padding */
  list-style: none !important;
  /* get rid of list bullets */
}

.social-share-row__item {
  display: inline-block;
  padding-left: 0;
  margin: 0;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.social-share-row__item:first-child .resp-sharing-button__link {
  margin-left: 0;
}

.resp-sharing-button {
  border-radius: 5px;
  -webkit-transition: 25ms ease-out;
  -o-transition: 25ms ease-out;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */

.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */

.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
  background-color: #046293;
  border-color: #046293;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #777;
  border-color: #777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

/* --------------------------------------------------*\
  UI
  UI peices are site specific non generic styles.
  eg: header, footer, sidebar, page specific styles
\* -----------------------------------------------------*/

/**
* Page-head
*/

@media (min-width: 1025px) {
  .page-header--sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .header {
    position: sticky;
    z-index: 999;
    position: -webkit-sticky;
    top: 0;
  }
}

/* Fix sticky header position for when admin bar visible
 TODO: Uncomment if design has sticky header */

.admin-bar .header.js-scrolled {
  top: 32px;
}

@media screen and (max-width: 782px) {
  .admin-bar .header.js-scrolled {
    top: 46px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1024px),
  (-webkit-min-device-pixel-ratio: 2) and (-o-min-device-pixel-ratio: 2/1) and (max-width: 1024px),
  (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) and (max-width: 1024px) {
  /* TODO: Add Retina-specific logo here - see instructions https://webpagefx.mangoapps.com/mlink/wiki/NjA3Nzg */
}

@media (min-width: 1025px) {
  .sticky-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 125px;
    /* TODO: update to match how far down page when sidebar should stick */
  }
}

/**
* Navigation
* If creating a menu in Ubermenu, remove the following CSS and add any custom styling to custom.css in ubermenu plugin files
*/

/* General Styling */

/* .nav-primary {
}

.nav-primary ul {
list-style: none;
padding: 0;
margin: 0;
} */

/* .nav-primary li {
cursor: pointer;
position: relative;
} */

/* .nav-primary a {
display: block;
text-decoration: none;
} */

/* 1st Level */

/* .nav-primary > .menu > li {
display: inline-block;
} */

/* Sub Menus */

/* .nav-primary .sub-menu {
display: none;
position: absolute;
top: 100%;
left: 0;
max-width: 250px;
} */

/* .nav-primary li:hover > .sub-menu {
display: block;
}

.nav-primary .sub-menu li {
display: block;
} */

/* 3rd Level Sub Menus */

/* .nav-primary .sub-menu .sub-menu {
top: 0;
left: 100%;
} */

/**
* Ubermenu
*/

.header.js-scrolled {
  position: fixed;
  top: 0;
  z-index: 9999;
}

.header.js-scrolled {
  border-bottom: 1px solid #08326a;
}

.header.js-scrolled .wrap {
  padding: 20px 0px;
}

.ubermenu.ubermenu-horizontal.ubermenu-items-vstretch
  .ubermenu-item.ubermenu-item-level-0
  > .ubermenu-submenu-drop {
  top: 100% !important;
  margin-top: 0 !important;
}

.ubermenu-main
  .ubermenu-nav
  .ubermenu-submenu
  .ubermenu-item-header
  > .ubermenu-target
  .ubermenu-target-text {
  /* border-bottom: 4px solid #ff4e00;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  min-height: 52px; */
  font-weight: 500 !important;
}

.ubermenu
  .ubermenu-submenu-type-stack
  > .ubermenu-item-normal
  > .ubermenu-target {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px !important;
  padding-right: 8px !important;
  margin-left: 20px;
  margin-right: 0px;
}

.ubermenu-main .ubermenu-item-normal > .ubermenu-target {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ubermenu .ubermenu-submenu-type-stack.ubermenu-submenu-indent {
  padding-left: 16px;
  padding-top: 10px;
}

.ubermenu-active.ubermenu-item-level-0 > a:after {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #09326a;
  content: "";
  display: block;
  position: absolute;
  top: calc(100% + 26px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.ubermenu .ubermenu-submenu-type-stack .ubermenu-has-submenu-stack ul,
.ubermenu .ubermenu-submenu-type-flyout .ubermenu-has-submenu-stack ul,
.ubermenu .ubermenu-submenu-type-mega .ubermenu-has-submenu-stack ul {
  display: none !important;
}

/*.ubermenu .ubermenu-submenu-type-stack .ubermenu-has-submenu-stack > a:after{
  content: '＋';
  display: block;
  color: #fff;
}*/

.js-submenu-indicator {
  font-style: normal;
  padding: 0 12px;
}

.ubermenu .ubermenu-submenu-type-stack .ubermenu-has-submenu-stack > a,
.ubermenu-main .ubermenu-item-normal > .ubermenu-target,
.ubermenu-main
  .ubermenu-submenu
  .ubermenu-item-header.ubermenu-has-submenu-stack
  > .ubermenu-target {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ubermenu-main
  .ubermenu-item-level-0.nav-primary__sustainability
  .ubermenu-target,
.nav-primary .nav-primary__sustainability .nav-label {
  color: #00e600 !important;
  position: relative;
}

.ubermenu-main
  .ubermenu-item-level-0.nav-primary__sustainability
  .ubermenu-target:hover,
.nav-primary .nav-primary__sustainability .nav-label:hover {
  color: #008000 !important;
  position: relative;
}

.ubermenu-main .ubermenu-item-level-0 > .ubermenu-target {
  font-size: 13px;
  white-space: nowrap;
  padding: 15px 10px;
}

.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target {
  padding-right: 22px !important;
}

.ubermenu-main
  .ubermenu-item-level-0.nav-primary__sustainability
  .ubermenu-target:before,
.nav-primary .nav-primary__sustainability .nav-label:before {
  content: "\e90d";
  font-family: "icomoon" !important;
  display: inline-block;
  margin-right: 2px;
}

/*.ubermenu .ubermenu-submenu-type-stack .ubermenu-has-submenu-stack:hover ul{
  display: block !important;
}*/

.ubermenu .ubermenu-has-submenu-stack.fx-expanded > ul {
  display: block !important;
}

.left-sidebar {
  position: fixed;
  z-index: 3;
  background: #08326a;
  width: 60px;
  height: 100%;
  padding: 20px 0;
  bottom: 0;
  left: 0;
  top: 0;
  display: none;
}

.btnand-search .btn {
  font-size: 13px;
  padding: 8px 24px;
  margin-left: 8px;
  white-space: nowrap;
}

@media (min-width: 769px) and (max-width: 1199px) {
  .ubermenu-main .ubermenu-item-level-0 .ubermenu-target {
    font-size: 13px;
    white-space: nowrap;
    padding: 4px;
  }

  .ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target {
    padding-right: 13px !important;
  }

  .btnand-search .btn {
    font-size: 12px;
    padding: 8px 16px;
    margin-left: 0;
  }

  .logo a {
    max-width: 116px !important;
  }

  .ubermenu-sub-indicators
    .ubermenu-has-submenu-drop
    > .ubermenu-target
    > .ubermenu-sub-indicator {
    right: 4px !important;
  }
}

@media (max-width: 1500px) {
  .logo {
    padding-top: 40px;
    padding-right: 5px;
  }
}

@media (min-width: 1366px) {
  .ubermenu-main .ubermenu-item-level-0 .ubermenu-target {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  body.menu-is-expanded {
    overflow: hidden;
  }

  .nav-primary.is-expanded {
    height: calc(100vh - 128px) !important;
    overflow-y: scroll;
  }

  .header.js-scrolled {
    position: static;
  }

  .tablet-quick-links {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 15px;
  }

  .tablet-quick-links li {
    padding: 0px;
    margin: 0;
  }

  .tablet-quick-links li a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    padding: 15px;
    font-size: 16px;
  }

  .left-sidebar {
    display: block;
  }

  .left-sidebar ul {
    padding: 0;
  }

  .left-sidebar li {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 25px;
  }

  .left-sidebar li:first-child:not(.menu-item) {
    margin: 0px;
    height: 48px;
  }

  .left-sidebar li a {
    color: #fff;
    text-decoration: none;
  }

  .left-sidebar li a i {
    font-size: 30px;
  }

  .btn-mobile {
    position: relative;
    display: block;
    margin: auto;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    color: #fff;
    font-weight: bold;
    padding: 0;
    float: none;
    background: transparent;
    line-height: 6em;
  }

  .btn-mobile:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    position: absolute;
    top: 0px;
    width: 34px;
    height: 2px;
    background: #fff;
    -webkit-box-shadow: 0 8px 0 0 #fff, 0 16px 0 0 #fff;
    box-shadow: 0 8px 0 0 #fff, 0 16px 0 0 #fff;
  }

  .menu-is-expanded .btn-mobile::before,
  .menu-is-expanded .btn-mobile::after {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    -webkit-transform-origin: center left;
    -ms-transform-origin: center left;
    transform-origin: center left;
    -webkit-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    width: 34px;
    z-index: -1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .menu-is-expanded .btn-mobile::before {
    top: -8px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 3px;
  }

  .menu-is-expanded .btn-mobile::after {
    top: 16px;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 3px;
  }

  .js-searchactive:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #080b36;
    display: block;
    position: absolute;
    top: 0;
    z-index: 8;
  }

  .search-container.opened {
    padding: 10px 2.8% 18px;
    padding: 30px;
    background: transparent;
    top: 74px;
    max-height: 100%;
    overflow: hidden;
  }

  .search-container.opened {
    z-index: 9;
  }

  .nav-primary {
    height: 100%;
    width: 100%;
    position: fixed;
    right: -100%;
    z-index: 99999;
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
    background-color: #08326a;
    border-left: 1px solid #0e3f81;
    top: 0;
  }

  .nav-primary.is-expanded {
    width: calc(100% - 60px);
    /* 60px === width of sidebar */
    right: 0;
  }

  .plus-icon:before {
    content: "＋";
    display: block;
    color: #fff;
  }

  .plus-icon.active:before {
    content: "﹣";
  }

  .nav-primary li {
    text-align: left;
    border-bottom: 1px solid #0e3f81;
    padding: 0 !important;
    margin: 0;
    position: static;
  }

  .nav-primary a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    padding: 14px 22px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .nav-primary .sub-menu {
    display: none;
  }

  .nav-primary .sub-menu a {
    font-weight: 500;
    font-size: 16px;
  }

  .nav-primary .sub-menu.active {
    width: 100%;
    background: #0f3f81;
    max-width: 100%;
    display: block;
    position: relative;
  }

  .nav-primary .sub-menu li {
    border-color: #08326a;
  }

  .nav-primary .sub-menu li:last-child {
    border: none;
  }

  .nav-primary .sub-menu.active .sub-menu.active {
    background: #08326a;
  }

  .nav-primary .sub-menu.active .sub-menu.active li {
    border-color: #08326a;
  }

  .nav-primary .sub-menu.active .submenu-back span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    color: #ff4e00;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }

  .nav-primary .sub-menu.active .submenu-back span i {
    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  body {
    padding-left: 0 !important;
  }

  .left-sidebar {
    left: -100%;
  }

  .heading-box {
    border-top: 0 !important;
  }

  .hdrBar2 {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .hdrBar2 .flex-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mobile-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 32px;
    margin-top: 1px;
  }

  .btn-mobile {
    font-size: 8px;
    line-height: 7em;
  }

  .btn-mobile:before,
  .menu-is-expanded .btn-mobile::before,
  .menu-is-expanded .btn-mobile::after {
    width: 24px;
  }

  .menu-is-expanded .btn-mobile::before {
    top: 0;
    left: 5px;
  }

  .menu-is-expanded .btn-mobile::after {
    top: 17px;
    left: 5px;
  }

  .nav-primary-controls {
    display: none;
  }

  .nav-primary.is-expanded {
    width: 100%;
    top: 127px;
  }

  .nav-primary.is-expanded:before {
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid #09326a;
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    right: 22px;
  }
}

/**
* Masthead
*/

/**
* Sidebar
*/

/* Blog */

.page-sidebar .widget {
  margin-bottom: 20px;
}

.page-sidebar .widget {
  padding: 20px;
  background: #080b36;
}

.page-sidebar .widget h4 {
  color: #fff;
  border-bottom: 4px solid #ff4f01;
  padding-bottom: 8px;
}

.page-sidebar .widget a {
  color: #fff;
  text-decoration: none;
}

.page-sidebar .widget-popular-posts a:hover {
  color: #13d8fc;
}

.page-sidebar .widget ul {
  padding-left: 0;
}

.page-sidebar .widget ul li {
  padding-left: 0;
  border-bottom: 1px solid #08326a;
  margin: 0;
  padding: 8px 0px;
}

.page-sidebar .widget ul li {
  padding-left: 20px;
}

.page-sidebar .widget ul li:last-child {
  border-bottom: 0;
}

.page-sidebar .widget ul li::after {
  top: 20px;
}

.widget_mailpoet_form #mailpoet_form_1 form.mailpoet_form {
  padding: 0;
}

#mailpoet_form_1 .mailpoet_segment_label,
#mailpoet_form_1 .mailpoet_text_label,
#mailpoet_form_1 .mailpoet_textarea_label,
#mailpoet_form_1 .mailpoet_select_label,
#mailpoet_form_1 .mailpoet_radio_label,
#mailpoet_form_1 .mailpoet_checkbox_label,
#mailpoet_form_1 .mailpoet_list_label,
#mailpoet_form_1 .mailpoet_date_label {
  font-weight: bold !important;
}

.page-sidebar .widget_search label {
  color: #fff;
  border-bottom: 4px solid #ff4f01;
  padding-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  display: block;
}

.page-sidebar .widget_search input {
  display: block;
  width: 100%;
  padding: 10px !important;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: none;
  border-radius: 4px;
}

.widget_mailpoet_form label {
  font-size: 14px;
}

.page-sidebar .widget_search button {
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  padding: 12px 33px;
  line-height: 1.5em;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

/*--- Leadership Page ---*/

.team--wrapper .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.team--wrapper a {
  text-decoration: none;
  margin-bottom: 32px;
}

.team-box {
  height: 100%;
  border: 1px solid #ddd;
  background: #fff;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.team-box img {
  margin-bottom: 8px;
}

.team--wrapper a:hover .team-box {
  -webkit-box-shadow: 0 0 20px rgba(0, 8, 15, 0.1);
  box-shadow: 0 0 20px rgba(0, 8, 15, 0.1);
}

/**
* Page-footer
*/

/**
* 50/50 Sections
*/

.half-section .flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.half-and-half-image,
.half-and-half-text {
  position: relative;
}

.half-and-half-image {
  max-height: 400px;
}

.half-and-half-image img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  -o-object-fit: cover;
}

.half-and-half-text {
  padding: 42px 22px;
}

@media (min-width: 768px) {
  .half-and-half-image {
    max-height: inherit;
  }

  .half-and-half-image img {
    position: absolute;
  }

  .half-and-half-text {
    max-width: 512px;
    margin-left: auto;
    padding-right: 64px;
  }

  .half-and-half-text.right {
    margin-right: auto;
    margin-left: 0;
    padding-right: 22px;
    padding-left: 64px;
  }
}

@media (min-width: 1025px) {
  .half-and-half-text {
    max-width: 490px;
    padding: 64px 22px;
    padding-right: 64px;
  }

  .half-and-half-text.right {
    padding-right: 22px;
    padding-left: 64px;
  }
}

@media (min-width: 1200px) {
  .half-and-half-text {
    max-width: 589px;
  }
}

@media (min-width: 1500px) {
  .half-and-half-text {
    max-width: 728px;
  }
}

/**
* Portfolio Gallery Styles
*/

.gallery-tabs {
  border-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.gallery-tabs .js-tabs {
  margin-bottom: 8px;
  padding-left: 0;
  margin-right: 8px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.gallery-tabs .js-tabs a {
  text-decoration: none;
  background: #ddd;
  padding: 12px 22px;
  border-radius: 50px;
  color: #08326a;
  text-transform: capitalize;
  font-weight: 700;
}

.gallery-tabs .js-tabs a:hover,
.gallery-tabs .js-tabs.show a {
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  color: #fff;
}

.portfoliolist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
}

.masonry-item {
  margin-bottom: 22px;
  font-size: 14px;
}

.gallery-image-title {
  padding-top: 22px;
}

.gallery-desc {
  width: 100%;
  text-align: left;
}

.masonry-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #08326a;
  text-decoration: none;
  border: 1px solid #ddd;
  padding: 22px 12px;
  height: 100%;
  font-weight: 500;
}

.masonry-item a:hover {
  -webkit-box-shadow: 0 0 20px rgba(0, 8, 15, 0.1);
  box-shadow: 0 0 20px rgba(0, 8, 15, 0.1);
}

img.mfp-img {
  background: #fff;
}

.mfp-close-btn-in .mfp-close {
  color: #08326a !important;
  right: 0 !important;
  padding-right: 12px !important;
}

.mfp-bg {
  background: #080b36 !important;
  opacity: 0.9 !important;
}

/**
* Print CSS
*/

@media print {
  /* Reset*/
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    filter: none !important;
    -webkit-filter: none !important;
    text-shadow: none !important;
  }

  h2,
  h3 {
    /* Avoid a paragraph being detached from the heading immediately preceding it */
    page-break-after: avoid;
  }

  figure,
  table {
    /* Avoid breaking figure or table into 2 pages */
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  p {
    /* Prevent single line at the end of a page and a single line at the top the next page */
    orphans: 2;
    widows: 2;
  }

  /* Grid Styling */
  .container {
    width: auto;
  }

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666666666666%;
  }

  .col-sm-10 {
    width: 83.33333333333334%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666666666666%;
  }

  .col-sm-7 {
    width: 58.333333333333336%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666666666667%;
  }

  .col-sm-4 {
    width: 33.33333333333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.666666666666664%;
  }

  .col-sm-1 {
    width: 8.333333333333332%;
  }

  /* Slick Slider - remove if not using slick slider */
  .slick-slider .slick-arrow,
  .slick-slider .slick-dots {
    display: none !important;
    /* we usually don't need to show slider navigation for print */
  }

  /* For sliders you want to display full width for print.
   Update ".slick-slider" with your specific slider names. */
  .slick-slider,
  .slick-sliderg .slick-list,
  .slick-slider .slick-track,
  .slick-slider .slick-slide {
    width: 100% !important;
    height: auto !important;
  }

  /* For sliders where you only want to show the 1st image of that slider.
   Update ".slick-slider" with your specific slider names. */
  .slick-slider .slick-slide {
    display: none !important;
  }

  .slick-slider .slick-slide.slick-active {
    display: block !important;
  }

  /* Show Only Active Thumbnails */
  .slick-slider .slick-cloned {
    display: none;
  }

  /* Reset adaptiveHeight */
  .slick-list {
    height: auto !important;
  }

  /* Remove Scrollbars */
  .slick-track {
    width: auto !important;
    height: auto !important;
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
  }

  .slick-track.slick-slide {
    width: auto !important;
  }
}

@media (min-width: 480px) {
  .promotions-bar__text {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .promotions-bar {
    padding: 5px 25px;
  }

  .promotions-bar__text {
    padding: 7px 20px;
    font-size: 16px;
  }

  .promotions-bar__text {
    width: 100%;
  }
}

/*------------------------------------*\
  Trumps
\*------------------------------------*/

@-ms-viewport {
  width: device-width;
}

/**
* Images
*/

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

/**
* Visiblity
*/

.show {
  display: block !important;
}

.visible-xxs,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xxs-block,
.visible-xxs-inline,
.visible-xxs-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 599px) {
  .visible-xxs {
    display: block !important;
  }

  table.visible-xxs {
    display: table;
  }

  tr.visible-xxs {
    display: table-row !important;
  }

  th.visible-xxs,
  td.visible-xxs {
    display: table-cell !important;
  }

  .visible-xxs-block {
    display: block !important;
  }

  .visible-xxs-inline {
    display: inline !important;
  }

  .visible-xxs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }

  .visible-xs-block {
    display: block !important;
  }

  .visible-xs-inline {
    display: inline !important;
  }

  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }

  .visible-sm-block {
    display: block !important;
  }

  .visible-sm-inline {
    display: inline !important;
  }

  .visible-sm-inline-block {
    display: inline-block !important;
  }

  .heading-border {
    width: 100%;
  }

  .light-img {
    padding: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .light-img {
    padding: 0;
  }

  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }

  .visible-md-block {
    display: block !important;
  }

  .visible-md-inline {
    display: inline !important;
  }

  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }

  .visible-lg-block {
    display: block !important;
  }

  .visible-lg-inline {
    display: inline !important;
  }

  .visible-lg-inline-block {
    display: inline-block !important;
  }

  .light-img {
    padding: 0;
  }
}

/**
* Hiding
*/

.hide,
.hidden-xxs-up {
  display: none !important;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.invisible {
  visibility: hidden !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@media (max-width: 599px) {
  .hidden-xxs {
    display: none !important;
  }
}

@media (min-width: 600px) {
  .hidden-xs-up {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (min-width: 1025px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

/**
* Screen Readers
*/

.sr-only,
.screen-reader-text,
.wpcf7 .screen-reader-response {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

/**
* Print
*/

.visible-print,
.visible-print-block,
.visible-print-inline,
.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }

  .visible-print-block {
    display: block !important;
  }

  .visible-print-inline {
    display: inline !important;
  }

  .visible-print-inline-block {
    display: inline-block !important;
  }

  .hidden-print {
    display: none !important;
  }
}

/**
* Text alignment
*/

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/**
* Positioning
*/

.affix-top {
  position: fixed !important;
  top: 0 !important;
}

.affix-bottom {
  position: fixed !important;
  bottom: 0 !important;
}

.affix-left {
  position: fixed !important;
  left: 0 !important;
}

.affix-right {
  position: fixed !important;
  right: 0 !important;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/**
* Add/remove margins
*/

.push {
  margin: 20px !important;
}

.push-top {
  margin-top: 20px !important;
}

.push-right {
  margin-right: 20px !important;
}

.push-bottom {
  margin-bottom: 20px !important;
}

.push-left {
  margin-left: 20px !important;
}

.push-ends {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.push-sides {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.push-half {
  margin: 10px !important;
}

.push-half-top {
  margin-top: 10px !important;
}

.push-half-right {
  margin-right: 10px !important;
}

.push-half-bottom {
  margin-bottom: 10px !important;
}

.push-half-left {
  margin-left: 10px !important;
}

.push-half-ends {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.push-half-sides {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.flush {
  margin: 0 !important;
}

.flush-top {
  margin-top: 0 !important;
}

.flush-right {
  margin-right: 0 !important;
}

.flush-bottom {
  margin-bottom: 0 !important;
}

.flush-left {
  margin-left: 0 !important;
}

.flush-ends {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.flush-sides {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/**
* Add/remove paddings
*/

.soft {
  padding: 20px !important;
}

.soft-top {
  padding-top: 20px !important;
}

.soft-right {
  padding-right: 20px !important;
}

.soft-bottom {
  padding-bottom: 20px !important;
}

.soft-left {
  padding-left: 20px !important;
}

.soft-ends {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.soft-sides {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.soft-half {
  padding: 10px !important;
}

.soft-half-top {
  padding-top: 10px !important;
}

.soft-half-right {
  padding-right: 10px !important;
}

.soft-half-bottom {
  padding-bottom: 10px !important;
}

.soft-half-left {
  padding-left: 10px !important;
}

.soft-half-ends {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.soft-half-sides {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.hard {
  padding: 0 !important;
}

.hard-top {
  padding-top: 0 !important;
}

.hard-right {
  padding-right: 0 !important;
}

.hard-bottom {
  padding-bottom: 0 !important;
}

.hard-left {
  padding-left: 0 !important;
}

.hard-ends {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hard-sides {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

@media (min-width: 600px) {
  .flush-xs {
    margin: 0 !important;
  }

  .flush-xs-top {
    margin-top: 0 !important;
  }

  .flush-xs-right {
    margin-right: 0 !important;
  }

  .flush-xs-bottom {
    margin-bottom: 0 !important;
  }

  .flush-xs-left {
    margin-left: 0 !important;
  }

  .flush-xs-ends {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .flush-xs-sides {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .push-xs {
    margin: 20px !important;
  }

  .push-xs-top {
    margin-top: 20px !important;
  }

  .push-xs-right {
    margin-right: 20px !important;
  }

  .push-xs-bottom {
    margin-bottom: 20px !important;
  }

  .push-xs-left {
    margin-left: 20px !important;
  }

  .push-xs-ends {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .push-xs-sides {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .push-half-xs {
    margin: 10px !important;
  }

  .push-half-xs-top {
    margin-top: 10px !important;
  }

  .push-half-xs-right {
    margin-right: 10px !important;
  }

  .push-half-xs-bottom {
    margin-bottom: 10px !important;
  }

  .push-half-xs-left {
    margin-left: 10px !important;
  }

  .push-half-xs-ends {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .push-half-xs-sides {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .push-double-xs {
    margin: 40px !important;
  }

  .push-double-xs-top {
    margin-top: 40px !important;
  }

  .push-double-xs-right {
    margin-right: 40px !important;
  }

  .push-double-xs-bottom {
    margin-bottom: 40px !important;
  }

  .push-double-xs-left {
    margin-left: 40px !important;
  }

  .push-double-xs-ends {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .push-double-xs-sides {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .push-triple-xs {
    margin: 60px !important;
  }

  .push-triple-xs-top {
    margin-top: 60px !important;
  }

  .push-triple-xs-right {
    margin-right: 60px !important;
  }

  .push-triple-xs-bottom {
    margin-bottom: 60px !important;
  }

  .push-triple-xs-left {
    margin-left: 60px !important;
  }

  .push-triple-xs-ends {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .push-triple-xs-sides {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }

  .soft-xs {
    padding: 20px !important;
  }

  .soft-xs-top {
    padding-top: 20px !important;
  }

  .soft-xs-right {
    padding-right: 20px !important;
  }

  .soft-xs-bottom {
    padding-bottom: 20px !important;
  }

  .soft-xs-left {
    padding-left: 20px !important;
  }

  .soft-xs-ends {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .soft-xs-sides {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .soft-half-xs {
    padding: 10px !important;
  }

  .soft-half-xs-top {
    padding-top: 10px !important;
  }

  .soft-half-xs-right {
    padding-right: 10px !important;
  }

  .soft-half-xs-bottom {
    padding-bottom: 10px !important;
  }

  .soft-half-xs-left {
    padding-left: 10px !important;
  }

  .soft-half-xs-ends {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .soft-half-xs-sides {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .soft-double-xs {
    padding: 40px !important;
  }

  .soft-double-xs-top {
    padding-top: 40px !important;
  }

  .soft-double-xs-right {
    padding-right: 40px !important;
  }

  .soft-double-xs-bottom {
    padding-bottom: 40px !important;
  }

  .soft-double-xs-left {
    padding-left: 40px !important;
  }

  .soft-double-xs-ends {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .soft-double-xs-sides {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .soft-triple-xs {
    padding: 60px !important;
  }

  .soft-triple-xs-top {
    padding-top: 60px !important;
  }

  .soft-triple-xs-right {
    padding-right: 60px !important;
  }

  .soft-triple-xs-bottom {
    padding-bottom: 60px !important;
  }

  .soft-triple-xs-left {
    padding-left: 60px !important;
  }

  .soft-triple-xs-ends {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .soft-triple-xs-sides {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }

  .hard-xs {
    padding: 0 !important;
  }

  .hard-xs-top {
    padding-top: 0 !important;
  }

  .hard-xs-right {
    padding-right: 0 !important;
  }

  .hard-xs-bottom {
    padding-bottom: 0 !important;
  }

  .hard-xs-left {
    padding-left: 0 !important;
  }

  .hard-xs-ends {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .hard-xs-sides {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 768px) {
  .flush-sm {
    margin: 0 !important;
  }

  .flush-sm-top {
    margin-top: 0 !important;
  }

  .flush-sm-right {
    margin-right: 0 !important;
  }

  .flush-sm-bottom {
    margin-bottom: 0 !important;
  }

  .flush-sm-left {
    margin-left: 0 !important;
  }

  .flush-sm-ends {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .flush-sm-sides {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .push-sm {
    margin: 20px !important;
  }

  .push-sm-top {
    margin-top: 20px !important;
  }

  .push-sm-right {
    margin-right: 20px !important;
  }

  .push-sm-bottom {
    margin-bottom: 20px !important;
  }

  .push-sm-left {
    margin-left: 20px !important;
  }

  .push-sm-ends {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .push-sm-sides {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .push-half-sm {
    margin: 10px !important;
  }

  .push-half-sm-top {
    margin-top: 10px !important;
  }

  .push-half-sm-right {
    margin-right: 10px !important;
  }

  .push-half-sm-bottom {
    margin-bottom: 10px !important;
  }

  .push-half-sm-left {
    margin-left: 10px !important;
  }

  .push-half-sm-ends {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .push-half-sm-sides {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .push-double-sm {
    margin: 40px !important;
  }

  .push-double-sm-top {
    margin-top: 40px !important;
  }

  .push-double-sm-right {
    margin-right: 40px !important;
  }

  .push-double-sm-bottom {
    margin-bottom: 40px !important;
  }

  .push-double-sm-left {
    margin-left: 40px !important;
  }

  .push-double-sm-ends {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .push-double-sm-sides {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .push-triple-sm {
    margin: 60px !important;
  }

  .push-triple-sm-top {
    margin-top: 60px !important;
  }

  .push-triple-sm-right {
    margin-right: 60px !important;
  }

  .push-triple-sm-bottom {
    margin-bottom: 60px !important;
  }

  .push-triple-sm-left {
    margin-left: 60px !important;
  }

  .push-triple-sm-ends {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .push-triple-sm-sides {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }

  .soft-sm {
    padding: 20px !important;
  }

  .soft-sm-top {
    padding-top: 20px !important;
  }

  .soft-sm-right {
    padding-right: 20px !important;
  }

  .soft-sm-bottom {
    padding-bottom: 20px !important;
  }

  .soft-sm-left {
    padding-left: 20px !important;
  }

  .soft-sm-ends {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .soft-sm-sides {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .soft-half-sm {
    padding: 10px !important;
  }

  .soft-half-sm-top {
    padding-top: 10px !important;
  }

  .soft-half-sm-right {
    padding-right: 10px !important;
  }

  .soft-half-sm-bottom {
    padding-bottom: 10px !important;
  }

  .soft-half-sm-left {
    padding-left: 10px !important;
  }

  .soft-half-sm-ends {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .soft-half-sm-sides {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .soft-double-sm {
    padding: 40px !important;
  }

  .soft-double-sm-top {
    padding-top: 40px !important;
  }

  .soft-double-sm-right {
    padding-right: 40px !important;
  }

  .soft-double-sm-bottom {
    padding-bottom: 40px !important;
  }

  .soft-double-sm-left {
    padding-left: 40px !important;
  }

  .soft-double-sm-ends {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .soft-double-sm-sides {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .soft-triple-sm {
    padding: 60px !important;
  }

  .soft-triple-sm-top {
    padding-top: 60px !important;
  }

  .soft-triple-sm-right {
    padding-right: 60px !important;
  }

  .soft-triple-sm-bottom {
    padding-bottom: 60px !important;
  }

  .soft-triple-sm-left {
    padding-left: 60px !important;
  }

  .soft-triple-sm-ends {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .soft-triple-sm-sides {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }

  .hard-sm {
    padding: 0 !important;
  }

  .hard-sm-top {
    padding-top: 0 !important;
  }

  .hard-sm-right {
    padding-right: 0 !important;
  }

  .hard-sm-bottom {
    padding-bottom: 0 !important;
  }

  .hard-sm-left {
    padding-left: 0 !important;
  }

  .hard-sm-ends {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .hard-sm-sides {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 1025px) {
  .flush-md {
    margin: 0 !important;
  }

  .flush-md-top {
    margin-top: 0 !important;
  }

  .flush-md-right {
    margin-right: 0 !important;
  }

  .flush-md-bottom {
    margin-bottom: 0 !important;
  }

  .flush-md-left {
    margin-left: 0 !important;
  }

  .flush-md-ends {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .flush-md-sides {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .push-md {
    margin: 20px !important;
  }

  .push-md-top {
    margin-top: 20px !important;
  }

  .push-md-right {
    margin-right: 20px !important;
  }

  .push-md-bottom {
    margin-bottom: 20px !important;
  }

  .push-md-left {
    margin-left: 20px !important;
  }

  .push-md-ends {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .push-md-sides {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .push-half-md {
    margin: 10px !important;
  }

  .push-half-md-top {
    margin-top: 10px !important;
  }

  .push-half-md-right {
    margin-right: 10px !important;
  }

  .push-half-md-bottom {
    margin-bottom: 10px !important;
  }

  .push-half-md-left {
    margin-left: 10px !important;
  }

  .push-half-md-ends {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .push-half-md-sides {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .push-double-md {
    margin: 40px !important;
  }

  .push-double-md-top {
    margin-top: 40px !important;
  }

  .push-double-md-right {
    margin-right: 40px !important;
  }

  .push-double-md-bottom {
    margin-bottom: 40px !important;
  }

  .push-double-md-left {
    margin-left: 40px !important;
  }

  .push-double-md-ends {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .push-double-md-sides {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .push-triple-md {
    margin: 60px !important;
  }

  .push-triple-md-top {
    margin-top: 60px !important;
  }

  .push-triple-md-right {
    margin-right: 60px !important;
  }

  .push-triple-md-bottom {
    margin-bottom: 60px !important;
  }

  .push-triple-md-left {
    margin-left: 60px !important;
  }

  .push-triple-md-ends {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .push-triple-md-sides {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }

  .soft-md {
    padding: 20px !important;
  }

  .soft-md-top {
    padding-top: 20px !important;
  }

  .soft-md-right {
    padding-right: 20px !important;
  }

  .soft-md-bottom {
    padding-bottom: 20px !important;
  }

  .soft-md-left {
    padding-left: 20px !important;
  }

  .soft-md-ends {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .soft-md-sides {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .soft-half-md {
    padding: 10px !important;
  }

  .soft-half-md-top {
    padding-top: 10px !important;
  }

  .soft-half-md-right {
    padding-right: 10px !important;
  }

  .soft-half-md-bottom {
    padding-bottom: 10px !important;
  }

  .soft-half-md-left {
    padding-left: 10px !important;
  }

  .soft-half-md-ends {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .soft-half-md-sides {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .soft-double-md {
    padding: 40px !important;
  }

  .soft-double-md-top {
    padding-top: 40px !important;
  }

  .soft-double-md-right {
    padding-right: 40px !important;
  }

  .soft-double-md-bottom {
    padding-bottom: 40px !important;
  }

  .soft-double-md-left {
    padding-left: 40px !important;
  }

  .soft-double-md-ends {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .soft-double-md-sides {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .soft-triple-md {
    padding: 60px !important;
  }

  .soft-triple-md-top {
    padding-top: 60px !important;
  }

  .soft-triple-md-right {
    padding-right: 60px !important;
  }

  .soft-triple-md-bottom {
    padding-bottom: 60px !important;
  }

  .soft-triple-md-left {
    padding-left: 60px !important;
  }

  .soft-triple-md-ends {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .soft-triple-md-sides {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }

  .hard-md {
    padding: 0 !important;
  }

  .hard-md-top {
    padding-top: 0 !important;
  }

  .hard-md-right {
    padding-right: 0 !important;
  }

  .hard-md-bottom {
    padding-bottom: 0 !important;
  }

  .hard-md-left {
    padding-left: 0 !important;
  }

  .hard-md-ends {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .hard-md-sides {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .flush-lg {
    margin: 0 !important;
  }

  .flush-lg-top {
    margin-top: 0 !important;
  }

  .flush-lg-right {
    margin-right: 0 !important;
  }

  .flush-lg-bottom {
    margin-bottom: 0 !important;
  }

  .flush-lg-left {
    margin-left: 0 !important;
  }

  .flush-lg-ends {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .flush-lg-sides {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .push-lg {
    margin: 20px !important;
  }

  .push-lg-top {
    margin-top: 20px !important;
  }

  .push-lg-right {
    margin-right: 20px !important;
  }

  .push-lg-bottom {
    margin-bottom: 20px !important;
  }

  .push-lg-left {
    margin-left: 20px !important;
  }

  .push-lg-ends {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .push-lg-sides {
    margin-right: 20px !important;
    margin-left: 20px !important;
  }

  .push-half-lg {
    margin: 10px !important;
  }

  .push-half-lg-top {
    margin-top: 10px !important;
  }

  .push-half-lg-right {
    margin-right: 10px !important;
  }

  .push-half-lg-bottom {
    margin-bottom: 10px !important;
  }

  .push-half-lg-left {
    margin-left: 10px !important;
  }

  .push-half-lg-ends {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  .push-half-lg-sides {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }

  .push-double-lg {
    margin: 40px !important;
  }

  .push-double-lg-top {
    margin-top: 40px !important;
  }

  .push-double-lg-right {
    margin-right: 40px !important;
  }

  .push-double-lg-bottom {
    margin-bottom: 40px !important;
  }

  .push-double-lg-left {
    margin-left: 40px !important;
  }

  .push-double-lg-ends {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .push-double-lg-sides {
    margin-right: 40px !important;
    margin-left: 40px !important;
  }

  .push-triple-lg {
    margin: 60px !important;
  }

  .push-triple-lg-top {
    margin-top: 60px !important;
  }

  .push-triple-lg-right {
    margin-right: 60px !important;
  }

  .push-triple-lg-bottom {
    margin-bottom: 60px !important;
  }

  .push-triple-lg-left {
    margin-left: 60px !important;
  }

  .push-triple-lg-ends {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }

  .push-triple-lg-sides {
    margin-right: 60px !important;
    margin-left: 60px !important;
  }

  .soft-lg {
    padding: 20px !important;
  }

  .soft-lg-top {
    padding-top: 20px !important;
  }

  .soft-lg-right {
    padding-right: 20px !important;
  }

  .soft-lg-bottom {
    padding-bottom: 20px !important;
  }

  .soft-lg-left {
    padding-left: 20px !important;
  }

  .soft-lg-ends {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .soft-lg-sides {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .soft-half-lg {
    padding: 10px !important;
  }

  .soft-half-lg-top {
    padding-top: 10px !important;
  }

  .soft-half-lg-right {
    padding-right: 10px !important;
  }

  .soft-half-lg-bottom {
    padding-bottom: 10px !important;
  }

  .soft-half-lg-left {
    padding-left: 10px !important;
  }

  .soft-half-lg-ends {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .soft-half-lg-sides {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }

  .soft-double-lg {
    padding: 40px !important;
  }

  .soft-double-lg-top {
    padding-top: 40px !important;
  }

  .soft-double-lg-right {
    padding-right: 40px !important;
  }

  .soft-double-lg-bottom {
    padding-bottom: 40px !important;
  }

  .soft-double-lg-left {
    padding-left: 40px !important;
  }

  .soft-double-lg-ends {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .soft-double-lg-sides {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }

  .soft-triple-lg {
    padding: 60px !important;
  }

  .soft-triple-lg-top {
    padding-top: 60px !important;
  }

  .soft-triple-lg-right {
    padding-right: 60px !important;
  }

  .soft-triple-lg-bottom {
    padding-bottom: 60px !important;
  }

  .soft-triple-lg-left {
    padding-left: 60px !important;
  }

  .soft-triple-lg-ends {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .soft-triple-lg-sides {
    padding-right: 60px !important;
    padding-left: 60px !important;
  }

  .hard-lg {
    padding: 0 !important;
  }

  .hard-lg-top {
    padding-top: 0 !important;
  }

  .hard-lg-right {
    padding-right: 0 !important;
  }

  .hard-lg-bottom {
    padding-bottom: 0 !important;
  }

  .hard-lg-left {
    padding-left: 0 !important;
  }

  .hard-lg-ends {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .hard-lg-sides {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

/*
*  Owl Carousel - Core
*/

.owl-carousel {
  display: none;
  width: calc(100% + 22px);
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .pro-box,
.owl-carousel .item {
  width: 100%;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

@media (min-width: 1200px) {
  .owl-carousel {
    width: 100%;
  }
}

/* No Js */

.no-js .owl-carousel {
  display: block;
}

/*
*  Owl Carousel - Animate Plugin
*/

.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*
*  Owl Carousel - Auto Height Plugin
*/

.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/*
*  Owl Carousel - Lazy Load Plugin
*/

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

/*
*  Owl Carousel - Video Plugin
*/

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  //background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 100ms ease;
  transition: -webkit-transform 100ms ease;
  -o-transition: transform 100ms ease;
  transition: transform 100ms ease;
  transition: transform 100ms ease, -webkit-transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  -webkit-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

.sf-menu,
.sf-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sf-menu ul {
  position: absolute;
  top: -999em;
  background: #08326a;
}

.sf-menu ul li {
  width: 100%;
}

.sf-menu li:hover {
  visibility: inherit;
}

.sf-menu > li {
  float: left;
}

/*** SINGLE DROPDOWN  START ***/

.sf-menu .single-dropdown {
  float: left;
  width: 20%;
  position: relative;
}

.sf-menu .single-dropdown ul {
  padding: 26px 30px 31px;
  width: 240px;
}

.submenu-container .sf-with-ul {
  color: #fff !important;
  text-decoration: none;
  border-bottom: 2px solid #ff4e00;
  display: inline-block;
  font-weight: bold;
  padding-bottom: 5px;
  width: 100%;
}

.sf-with-ul {
  position: relative;
}

.sfHover .sf-with-ul::after {
  position: absolute;
  margin-left: -9px;
  left: 50%;
  bottom: -28px;
  content: "";
  border-top: 18px solid #08326a;
  border-left: 18px solid transparent;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.sf-menu > .sf-with-ul {
  position: relative;
}

.sf-menu > .sf-with-ul::before {
  content: " ";
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -15px;
  border-top: 15px solid #08326a;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}

.sf-menu > .single-dropdown > ul > li {
  float: left;
  width: 100%;
  padding-right: 15px;
}

.sf-menu > .single-dropdown > ul > li > ul {
  width: 100%;
  padding: 26px 0 0;
}

/*** SINGLE DROPDOWN  END ***/

.sf-menu .active a {
  color: #13d8fc;
}

.sf-menu > li > a {
  color: #ffffff;
  display: block;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  padding: 0 15px;
}

.sf-menu > li.sfHover > a,
.sf-menu > li > a:hover,
.sf-menu > li.sfHoverForce > a {
  color: #13d8fc;
}

.sf-menu li li li a {
  display: inline-block;
  position: relative;
  color: #777777;
  padding-bottom: 10px;
}

.sf-menu li li li a:hover {
  color: #333333;
}

.sf-menu li ul {
  display: none;
  left: 0;
  top: 135px;
  /* match top ul list item height */
  z-index: 99;
  padding: 12px 0 18px 0;
}

.sf-menu li li ul {
  position: static;
  display: block !important;
  opacity: 1 !important;
  background: none;
  padding-bottom: 0;
  -webkit-box-shadow: transparent 0 0 0;
  box-shadow: transparent 0 0 0;
}

.sf-menu li li li ul {
  padding: 0 0 0 20px;
}

.sf-menu li li li ul {
  width: 220px;
}

ul.sf-menu li:hover li ul,
ul.sf-menu li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li:hover ul,
ul.sf-menu li li.sfHover ul {
  left: 200px;
  /* match ul width */
  top: 0;
}

ul.sf-menu li li:hover li ul,
ul.sf-menu li li.sfHover li ul {
  top: -999em;
}

ul.sf-menu li li li:hover ul,
ul.sf-menu li li li.sfHover ul {
  left: 200px;
  /* match ul width */
  top: 0;
}

.sf-menu > li > ul {
  padding: 26px 30px 31px;
  width: 100%;
}

.sf-menu > li > ul > li > a {
  color: #333333;
}

.sf-menu > li > ul > li > a:hover {
  color: #515151;
}

.sf-menu > li > ul > li {
  float: left;
  width: 16%;
  padding-right: 30px;
}

.submenu-container li ul {
  margin: 0 -5px;
}

.submenu-container li ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding: 5px;
  display: inline-block;
  width: 100%;
}

.submenu-container li ul li a:hover {
  text-decoration: none;
  color: #fff;
  background: #080b36;
}

.sf-menu > li > ul > li#category-thumbnail {
  width: 100% !important;
  float: none;
  clear: both;
  overflow: hidden;
  padding-right: 0;
}

.sf-menu > li > ul > li#category-thumbnail > div {
  float: left;
  padding-left: 10px;
  width: 33.333%;
}

.sf-menu > li > ul > li#category-thumbnail > div:first-child {
  padding-left: 0;
}

.sf-menu > li > ul > li#category-thumbnail > div img {
  max-width: 100%;
  display: block;
}

.cat-title {
  display: none;
}

.sf-menu li.sf-search {
  border: none;
}

.sf-menu li.sf-search input {
  display: inline;
  padding: 0 13px;
  height: 30px;
  line-height: 30px;
  background: white;
  margin: 13px 10px 0 0;
  font-size: 13px;
  color: #9c9b9b;
  border: 1px solid #d6d4d4;
}

@media (max-width: 992px) {
  .sf-menu > li > ul > li > a {
    font-size: 11px;
  }
}

@media (max-width: 767px) {
  .sf-menu {
    display: none;
  }

  .sf-menu ul {
    position: relative;
  }

  .sf-menu > li {
    float: none;
    position: relative;
    border-right: none;
    width: 100%;
  }

  .sf-menu > li span {
    position: absolute;
    right: 6px;
    top: 16px;
    width: 30px;
    height: 30px;
    color: #ffffff;
    z-index: 2;
  }

  .sf-menu > li span:after {
    font-family: "FontAwesome";
    content: "\f067";
    font-size: 26px;
  }

  .sf-menu > li span.active:after {
    content: "\f068";
  }

  .sf-menu > li > a {
    text-align: left;
    padding: 15px 20px 16px 20px;
  }

  .sf-menu li ul {
    top: 0;
  }

  .sf-menu li li ul {
    padding-bottom: 20px;
  }

  .sf-menu > li > ul > li {
    width: 50%;
  }

  .sf-menu > li > ul > li.first-in-line-lg {
    clear: left;
  }

  /*** SINGLE DROPDOWN  START ***/
  .sf-menu .single-dropdown {
    float: none;
    border-right: none;
    width: 100%;
    position: relative;
  }

  .sf-menu .single-dropdown ul {
    padding: 26px 30px 31px;
    width: 100%;
  }

  .sf-menu > .single-dropdown > ul > li {
    float: left;
    width: 100%;
    padding-right: 15px;
  }

  .sf-menu > .single-dropdown > ul > li > ul {
    width: 100%;
    padding: 26px 0 0;
  }

  /*** SINGLE DROPDOWN  END ***/
  .cat-title {
    display: block;
    width: 100%;
    position: relative;
  }

  .cat-title:after {
    display: block;
    font-family: "FontAwesome";
    content: "";
    position: absolute;
    right: 15px;
    top: 18px;
    font-size: 26px;
    // background-image: url(../img/menu.svg);
    background-size: contain;
    width: 35px;
    height: 35px;
  }

  .cat-title.active:after {
    content: "\f068";
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .sf-menu > li > ul > li.first-in-line-xs {
    clear: left;
  }
}

@media (max-width: 479px) {
  .sf-menu > li > ul > li {
    width: 100%;
  }

  .sf-menu > li > ul > li#category-thumbnail > div {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
    text-align: center;
  }
}

/* multi level menu */

.dl-menuwrapper {
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-perspective-origin: 50% 200%;
  perspective-origin: 50% 200%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dl-menuwrapper button {
  border: none;
  overflow: hidden;
  cursor: pointer;
  outline: none;
}

.dl-menuwrapper ul {
  padding: 0;
  list-style: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.dl-menuwrapper li {
  position: relative;
}

.dl-menuwrapper li a {
  display: block;
  position: relative;
  outline: none;
  text-align: left;
  text-decoration: none;
  border-bottom: 1px solid #0e3f81;
}

.no-touch .dl-menuwrapper li a:hover {
  background: rgba(255, 248, 213, 0.1);
}

.dl-menuwrapper li.dl-back > a {
  background: rgba(0, 0, 0, 0.2);
}

.dl-menuwrapper li.dl-back > a:after,
.dl-menuwrapper li > a:not(:only-child):after {
  position: absolute;
  content: "\f061";
  speak: none;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.dl-menuwrapper li.dl-back > a:after {
  left: 10px;
  right: auto;
  color: white;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.dl-menuwrapper li > a:after {
  right: 10px;
  color: white;
}

.dl-menuwrapper .dl-menu {
  margin: 0;
  position: absolute;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  top: 35px;
}

.dl-menuwrapper .dl-menu.dl-menu-toggle {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dl-menuwrapper .dl-menu.dl-menuopen {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

/* Hide the inner submenus */

.dl-menuwrapper li .dl-submenu {
  display: none;
}

.dl-menuwrapper li .dl-submenu li a {
  color: #fff;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/

.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

/* Dynamically added submenu outside of the menu context */

.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  margin: 0;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out-1 {
  -webkit-animation: MenuAnimOut1 0.4s;
  animation: MenuAnimOut1 0.4s;
}

.dl-menu.dl-animate-out-2 {
  -webkit-animation: MenuAnimOut2 0.3s ease-in-out;
  animation: MenuAnimOut2 0.3s ease-in-out;
}

.dl-menu.dl-animate-out-3 {
  -webkit-animation: MenuAnimOut3 0.4s ease;
  animation: MenuAnimOut3 0.4s ease;
}

.dl-menu.dl-animate-out-4 {
  -webkit-animation: MenuAnimOut4 0.4s ease;
  animation: MenuAnimOut4 0.4s ease;
}

.dl-menu.dl-animate-out-5 {
  -webkit-animation: MenuAnimOut5 0.4s ease;
  animation: MenuAnimOut5 0.4s ease;
}

@-webkit-keyframes MenuAnimOut1 {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
  }

  75% {
    -webkit-transform: translateZ(-372.5px) rotateY(15deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut2 {
  100% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut3 {
  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut4 {
  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}

@-webkit-keyframes MenuAnimOut5 {
  100% {
    -webkit-transform: translateY(40%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut1 {
  50% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    transform: translateZ(-250px) rotateY(30deg);
  }

  75% {
    -webkit-transform: translateZ(-372.5px) rotateY(15deg);
    transform: translateZ(-372.5px) rotateY(15deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }
}

@keyframes MenuAnimOut2 {
  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes MenuAnimOut3 {
  100% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes MenuAnimOut4 {
  100% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
}

@keyframes MenuAnimOut5 {
  100% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
    opacity: 0;
  }
}

.dl-menu.dl-animate-in-1 {
  -webkit-animation: MenuAnimIn1 0.3s;
  animation: MenuAnimIn1 0.3s;
}

.dl-menu.dl-animate-in-2 {
  -webkit-animation: MenuAnimIn2 0.3s ease-in-out;
  animation: MenuAnimIn2 0.3s ease-in-out;
}

.dl-menu.dl-animate-in-3 {
  -webkit-animation: MenuAnimIn3 0.4s ease;
  animation: MenuAnimIn3 0.4s ease;
}

.dl-menu.dl-animate-in-4 {
  -webkit-animation: MenuAnimIn4 0.4s ease;
  animation: MenuAnimIn4 0.4s ease;
}

.dl-menu.dl-animate-in-5 {
  -webkit-animation: MenuAnimIn5 0.4s ease;
  animation: MenuAnimIn5 0.4s ease;
}

@-webkit-keyframes MenuAnimIn1 {
  0% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }

  20% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes MenuAnimIn5 {
  0% {
    -webkit-transform: translateY(40%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    opacity: 1;
  }
}

@keyframes MenuAnimIn1 {
  0% {
    -webkit-transform: translateZ(-500px) rotateY(0deg);
    transform: translateZ(-500px) rotateY(0deg);
    opacity: 0;
  }

  20% {
    -webkit-transform: translateZ(-250px) rotateY(30deg);
    transform: translateZ(-250px) rotateY(30deg);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: translateZ(0px) rotateY(0deg);
    transform: translateZ(0px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes MenuAnimIn2 {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes MenuAnimIn5 {
  0% {
    -webkit-transform: translateY(40%);
    transform: translateY(40%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-1 {
  -webkit-animation: SubMenuAnimIn1 0.4s ease;
  animation: SubMenuAnimIn1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-2 {
  -webkit-animation: SubMenuAnimIn2 0.3s ease-in-out;
  animation: SubMenuAnimIn2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-3 {
  -webkit-animation: SubMenuAnimIn3 0.4s ease;
  animation: SubMenuAnimIn3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-4 {
  -webkit-animation: SubMenuAnimIn4 0.4s ease;
  animation: SubMenuAnimIn4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in-5 {
  -webkit-animation: SubMenuAnimIn5 0.4s ease;
  animation: SubMenuAnimIn5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimIn1 {
  0% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }
}

@-webkit-keyframes SubMenuAnimIn5 {
  0% {
    -webkit-transform: translateZ(-200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn1 {
  0% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn2 {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn3 {
  0% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn4 {
  0% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }
}

@keyframes SubMenuAnimIn5 {
  0% {
    -webkit-transform: translateZ(-200px);
    transform: translateZ(-200px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-1 {
  -webkit-animation: SubMenuAnimOut1 0.4s ease;
  animation: SubMenuAnimOut1 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-2 {
  -webkit-animation: SubMenuAnimOut2 0.3s ease-in-out;
  animation: SubMenuAnimOut2 0.3s ease-in-out;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-3 {
  -webkit-animation: SubMenuAnimOut3 0.4s ease;
  animation: SubMenuAnimOut3 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-4 {
  -webkit-animation: SubMenuAnimOut4 0.4s ease;
  animation: SubMenuAnimOut4 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out-5 {
  -webkit-animation: SubMenuAnimOut5 0.4s ease;
  animation: SubMenuAnimOut5 0.4s ease;
}

@-webkit-keyframes SubMenuAnimOut1 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(50%);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut3 {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(-300px);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut4 {
  0% {
    -webkit-transform: translateZ(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(300px);
    opacity: 0;
  }
}

@-webkit-keyframes SubMenuAnimOut5 {
  0% {
    -webkit-transform: translateZ(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(-200px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut1 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut2 {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut3 {
  0% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(-300px);
    transform: translateZ(-300px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut4 {
  0% {
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    opacity: 0;
  }
}

@keyframes SubMenuAnimOut5 {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateZ(-200px);
    transform: translateZ(-200px);
    opacity: 0;
  }
}

/* No JS Fallback */

.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
  display: block;
}

.no-js .dl-menuwrapper li.dl-back {
  display: none;
}

.no-js .dl-menuwrapper li > a:not(:only-child) {
  background: rgba(0, 0, 0, 0.1);
}

.no-js .dl-menuwrapper li > a:not(:only-child):after {
  content: "";
}

/* #3: CSS added for override at widths above mobile menu
--------------------------------------------------------- */

@media only screen and (min-width: 801px) {
  .dl-menuwrapper .dl-menu {
    opacity: 1;
    pointer-events: initial;
  }

  .dl-menuwrapper li > a:not(:only-child):after {
    display: none;
  }
}

/* #Navigation
================================================== */

/* Main nav menu */

.nav-main {
  width: 100%;
  position: absolute;
  right: 0;
  top: 50px;
  text-align: center;
  -webkit-box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.4);
  background: #08326a;
}

@media only screen and (min-width: 801px) {
  .nav-main {
    position: relative;
    width: 100%;
    right: auto;
    top: auto;
    margin: 0 auto;
    max-width: 1200px;
    background: #d06f1a;
  }
}

.nav-main-list,
.nav-sub-list {
  list-style: none;
  padding: 0;
}

.nav-main-list {
  max-width: 978px;
  margin: 0 auto;
  background: #08326a;
}

.nav-main-item {
  display: block;
  width: 100%;
  border-bottom: 1px solid #154688;
}

.nav-main-item:first-child {
  border-top: 1px solid #154688;
}

@media only screen and (min-width: 801px) {
  .nav-main-item:first-child {
    border-top: none;
  }
}

.nav-main-item > a {
  display: block;
  color: white;
  padding: 10px 15px;
  font-weight: bold;
}

.nav-main-item > a:hover {
  background: #080b36;
}

@media only screen and (min-width: 801px) {
  .nav-main-item {
    float: left;
    position: relative;
    width: calc(25% - 1px);
    border-right: 1px solid #080b36;
    border-bottom: none;
    text-align: center;
  }

  .nav-main-item:first-child {
    border-left: 1px solid #080b36;
    border-top: none;
    width: calc(25% - 2px);
  }

  .nav-main-item > a {
    display: block;
  }
}

@media only screen and (min-width: 801px) {
  .nav-main-item {
    font-size: 12.25px;
    font-size: 0.875rem;
  }
}

@media only screen and (min-width: 801px) {
  .nav-main-item {
    font-size: 14px;
    font-size: 1rem;
  }
}

/* nav icon */

.nav-handle {
  position: absolute;
  right: 0;
  top: -32px;
  display: block;
  cursor: pointer;
  width: 50px;
  height: 50px;
  padding: 0;
  color: white;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 30px;
  background: transparent;
}

.nav-handle:before {
  content: "";
  display: block;
  width: 24px;
  height: 3px;
  top: 5px;
  left: 13px;
  position: absolute;
  background: white;
  -webkit-box-shadow: 0 8px 0 0 white, 0 16px 0 0 white;
  box-shadow: 0 8px 0 0 white, 0 16px 0 0 white;
}

.dl-back a {
  padding-left: 30px !important;
}

.mobile-main-item a {
  color: #fff;
  font-weight: bold;
  border-bottom: 2px solid #ff4e00 !important;
}

.nav-sub-item a {
  font-size: 14px;
}

.nav-sub-item > a,
.dl-back > a {
  text-decoration: none;
}

@media only screen and (min-width: 801px) {
  .nav-handle {
    display: none;
  }
}

.nav-sub-list {
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  background: #08326a;
  white-space: nowrap;
}

.nav-sub-item a {
  padding: 10px;
}

@media only screen and (min-width: 801px) {
  .nav-sub-list {
    display: block;
    max-height: 0;
    width: auto;
    overflow: hidden;
    text-align: left;
    min-width: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}

.nav-main-item:last-child > .nav-sub-list {
  left: auto;
  right: 0;
}

@media only screen and (min-width: 801px) {
  .nav-main-item:hover > .nav-sub-list {
    height: auto;
    max-height: 500px;
    -webkit-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
  }
}

.nav-sub-item,
.dl-back {
  border-bottom: 1px solid #154688;
}

.nav-sub-item:first-child,
.dl-back {
  border-top: 1px solid #154688;
}

.nav-sub-item > a,
.dl-back > a {
  color: white;
  display: block;
  padding: 10px 15px;
}

.nav-sub-item > a:hover {
  background: #080b36;
}

@media only screen and (min-width: 801px) {
  .dl-back {
    display: none;
  }

  .nav-sub-sub {
    display: none;
  }

  .mobile-main-item {
    display: none;
  }
}

* {
  margin: 0;
  padding: 0;
  // list-style: none;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif !important;
  font-size: 16px;
  color: #141414;
  -webkit-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  transition: all 0.1s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* padding-left: 100px; */
}

.img-fluid {
  max-width: 100%;
}

.w-100 {
  width: 100% !important;
}

.text-center {
  text-align: center;
}

:focus {
  outline: hidden;
}

/* margin all */

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-50 {
  padding-right: 50px;
}

/* Style Guide */

a {
  color: #ff4e00;
  text-decoration: underline;
}

a:hover {
  color: #ff8955;
}

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  text-align: center;
  border-radius: 50px;
  padding: 12px 33px;
  line-height: 1.5em;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.readmore {
  font-size: 16px;
  font-weight: bold;
  color: #ff4e00;
  text-decoration: none;
}

.readmore:hover {
  color: #ff8955;
}

.readmore i {
  position: relative;
  top: 6px;
  font-size: 20px;
}

.btn-secondary {
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
}

.btn-secondary:hover {
  background: #009cdd;
  color: #fff !important;
}

.btn-primary {
  background: -o-linear-gradient(
    left,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 78, 0, 1)),
    to(rgba(236, 159, 5, 1))
  );
  background: linear-gradient(
    to right,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
}

.btn-primary:hover {
  background: #ff4e00;
  color: #fff !important;
}

.bdyClass {
  padding-left: 100px;
}

/* Sidebar Style */

.hdrBar2.sticky-bar {
  position: fixed;
  z-index: 500;
  left: 0;
  top: 0;
  right: 0;
}

/* .container {
width: 95%;
margin: 0 auto;
} */

/* Navigation Styles */

/* Header */

.header {
  position: relative;
  width: 100%;
  background: #080b36;
}

.wrap {
  max-width: 100%;
  // padding: 40px 0 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo {
  font-family: inherit;
  font-size: 1.5em;
}

.logo a {
  color: #fff;
  text-decoration: none;
  opacity: 1;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: block;
  max-width: 196px;
}

.logo a:hover {
  opacity: 0.85;
}

.top-detail {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
  color: #fff;
  font-size: 14px;
}

.top-detail strong {
  padding-left: 5px;
}

.top-detail .color-blue {
  padding-right: 10px;
}

.top-detail a {
  text-decoration: none;
}

.top-detail ul {
  display: inline-block;
  padding-left: 0;
}

.top-detail ul li {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.top-detail ul li a {
  color: #13d8fc;
  padding: 0 10px;
}

.top-detail ul li a:hover {
  color: #ff8955;
}

.top-detail ul li a,
.top-detail strong {
  position: relative;
}

.top-detail ul li a:before,
.top-detail strong:not(:first-child):before {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background-color: #fff;
  transform: translateY(-50%);
  left: -5px;
}

/*.top-detail ul li:last-child a {
color: #ff4e00 !important;
font-weight: bold;
}*/

.color-blue {
  color: #13d8fc;
}

.color-orange {
  color: #ff4e00;
}

.top-detail ul li a.color-orange {
  color: #ff4e00;
  font-weight: bold;
}

.top-detail ul li a.color-orange:hover {
  color: #13d8fc;
}

/* Mobile menu */

.navbar {
  float: none;
  max-height: 0;
  -o-transition: max-height 0.4s;
  -webkit-transition: max-height 0.4s;
  transition: max-height 0.4s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar .menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  float: left;
}

.navbar .menu li {
  list-style-type: none;
  display: block;
}

.navbar .menu li a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 0 0.8em;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  font-size: 18px;
  font-weight: bold;
}

.navbar .menu li a:hover {
  color: #13d8fc;
}

.navbar .menu li a.active {
  color: #13d8fc;
}

.btnand-search {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn i {
  margin-left: 6px;
  font-size: 20px;
}

.searchIcon {
  display: inline-block;
  width: auto;
  position: relative;
  top: 10px;
  padding-right: 20px;
  padding-left: 10px;
  color: #fff;
}

.searchIcon i {
  font-size: 34px;
}

.search-input {
  position: fixed;
  top: 0px;
  left: 100px;
  right: 0;
  height: 100%;
  background-color: #080b36;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  display: none;
}

.search-input.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  z-index: 106;
  display: block;
}

.search-input input[type="search"] {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 60%;
  margin-left: 20%;
  color: rgb(255, 255, 255);
  background: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.8);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  font-size: 40px;
  // font-family: Roboto;
  font-weight: 300;
  text-align: center;
  padding: 10px;
}

.search-input .close {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 1;
  font-size: 27px;
  color: #fff;
  z-index: 500;
}

.search-input .close:hover {
  color: #fc2121;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .logo {
    margin-left: 0;
  }

  .logo {
    display: inline-block;
  }

  /* Mobile menu icon */
  /* #menu-icon {
          display: none;
      } */
  .navbar {
    float: right;
    max-height: none;
  }

  .navbar .menu li {
    list-style-type: none;
    display: inline-block;
    text-align: center;
  }

  .navbar .menu li a {
    display: block;
    border-bottom: 0;
  }
}

.bannerSec {
  background: #fdfdfd;
  padding-bottom: 80px;
}

.bnrFlex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.solution-textbox {
  width: 35%;
  padding-right: 3%;
}

.solution-textbox h2 {
  /* font-size: 58px; */
  color: #fff;
  font-weight: bold;
}

.border-heading {
  border-bottom: 4px solid #ff4e00;
  display: inline-block;
}

.solution-textbox p {
  font-size: 20px;
  color: #fff;
}

.right-img {
  width: 65%;
  float: right;
  padding-left: 2%;
}

.flex-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flexbuttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flexbuttons .box-category:nth-child(4),
.flexbuttons .box-category:nth-child(5) {
  -webkit-box-flex: 0.5;
  -ms-flex-positive: 0.5;
  flex-grow: 0.5;
}

.width33per {
  width: 33.333%;
}

.width50per {
  width: 50%;
}

.box-category {
  position: relative;
}

.box-category img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.btm-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 13px 30px 13px;
  z-index: 1;
}

.btm-content h2,
.btm-content h3 {
  color: #fff;
  /* font-size: 28px; */
  /* font-weight: bold; */
}

.btm-content p {
  /* font-size: 16px; */
  color: #fff;
  font-weight: bold;
  line-height: 1em;
  margin-bottom: 0;
}

.box-category::before {
  background: -o-linear-gradient(
    top,
    rgba(0, 156, 221, 0) 0%,
    rgba(8, 11, 54, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 156, 221, 0)),
    to(rgba(8, 11, 54, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 156, 221, 0) 0%,
    rgba(8, 11, 54, 1) 100%
  );
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 120px;
}

.box-category:hover::before {
  background: -o-linear-gradient(
    top,
    rgba(0, 156, 221, 0.93) 0%,
    rgba(0, 156, 221, 0.93) 1%,
    rgba(8, 50, 106, 0.93) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 156, 221, 0.93)),
    color-stop(1%, rgba(0, 156, 221, 0.93)),
    to(rgba(8, 50, 106, 0.93))
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 156, 221, 0.93) 0%,
    rgba(0, 156, 221, 0.93) 1%,
    rgba(8, 50, 106, 0.93) 100%
  );
  top: 0;
  height: 100%;
}

.btm-content .arw-in {
  display: none;
  position: absolute;
  right: 25px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 40%;
  color: #fff;
}

.box-category:hover .arw-in {
  display: block;
}

/* consumer engage */

.consumerEngage {
  padding: 60px 0;
  position: relative;
}

.consumerEngage::after {
  content: "";
  height: 329px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #080b36;
  position: absolute;
}

.consumerEngage .container {
  position: relative;
  z-index: 1;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.justify-content-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.border-heading2 {
  border-bottom: 4px solid #ff4e00;
  /* display: inline-block; */
}

.pro-box {
  background: -o-radial-gradient(
    center,
    ellipse,
    rgba(0, 156, 221, 1) 0%,
    rgba(8, 50, 106, 1) 67%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 156, 221, 1) 0%,
    rgba(8, 50, 106, 1) 67%
  );
  border-radius: 5px;
  border-bottom: 6px solid #009cdd;
  position: relative;
  overflow: hidden;
}

.pro-box-head {
  /* height: 156px; */
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  position: relative;
}

.pro-box-head::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 1.5em;
  background: #080b36;
  content: "";
}

.po-absolute {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  bottom: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 10px;
  padding-right: 10px;
}

.pro-box-body {
  padding-bottom: 50px;
  text-align: center;
  position: relative;
  width: 100%;
}

.consumerEngage .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.consumerEngage .owl-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /* -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; */
}

.consumerEngage .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pro-box-body .img-fluid {
  display: inline-block !important;
}

.uparw-Curcle {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  position: absolute;
  width: 75px;
  height: 35px;
  border-radius: 50px 50px 0 0;
  background: #009cdd;
  text-decoration: none;
  color: #fff !important;
  font-size: 20px;
}

.uparw-Curcle i {
  position: relative;
  top: 10px;
}

.uparw-Curcle img {
  position: absolute;
  top: 10px;
  width: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.consumerEngage .item:hover .pro-box-body {
  opacity: 0;
}

.consumerEngage .item:hover .pro-box {
  background: #080b36;
}

.consumerEngage .item:hover .pro-box-head {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* PLI Advantage */

.pli-advantage {
  padding-bottom: 40px;
}

.pliAdvantage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.light-img {
  width: 30%;
  padding-left: 60px;
}

.advantage-pli {
  width: 70%;
  padding-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.yewd {
  padding-top: 80px;
  width: 70%;
  padding-right: 5%;
}

.yearsSec {
  width: 30%;
  padding-top: 80px;
}

.inovation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.border-right {
  border-right: 1px solid #e0dddd;
}

.border-top {
  border-top: 1px solid #e0dddd;
}

.mitter1 {
  padding: 15px;
  text-align: center;
  width: 50%;
  font-weight: 500;
}

.mitter1 .number {
  width: 109px;
  height: 109px;
  border-radius: 50%;
  background: -o-linear-gradient(
    left,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 78, 0, 1)),
    to(rgba(236, 159, 5, 1))
  );
  background: linear-gradient(
    to right,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.white-curcle {
  width: 94px;
  height: 94px;
  background: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 42px;
  color: #08326a;
  font-weight: bold;
}

@media (min-width: 768px) {
  .pli-advantage {
    padding-bottom: 60px;
  }
}

@media (min-width: 1025px) {
  .pli-advantage {
    padding-bottom: 84px;
  }
}

/* Card Talk */

.cardTalk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cardTalkbg {
  padding: 80px 40px;
  background: #08326a;
  width: 70%;
}

.flextalk {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
}

.flextalk .btn-primary {
  margin-right: 20px;
}

.flextalk h2 {
  color: #fff;
  font-size: 46px;
  font-weight: bold;
}

.respnds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #fff;
}

.respnds .slideImg {
  width: 42%;
  border-right: 6px solid #ff4e00;
}

.cardTalk .respnds {
  display: block;
}

.cardTalk .respnds .slideImg {
  width: 100%;
  min-height: 240px;
}

.cardTalk .respnds .slidecontent {
  width: 100%;
}

.respnds .slideImg img {
  width: 100%;
}

.respnds .slidecontent {
  width: 58%;
  padding: 0 15px;
}

.respnds .slidecontent.flexgrow {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0;
}

.respnds .slidecontent h3 {
  font-size: 18px;
  font-weight: bold;
  color: #08326a;
  margin-bottom: 10px;
}

.respnds .slidecontent p {
  color: #141414;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 500;
}

.cardTalk .owl-carousel .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cardTalk .owl-carousel .owl-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.cardTalk .owl-carousel .owl-item .item {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.respnds .slidecontent {
  padding: 15px;
}

.cardTalk .respnds .slideImg {
  position: relative;
}

.cardTalk .respnds .slideImg img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
}

button:focus {
  outline: none;
}

.them-caro2 {
  padding-left: 20px;
  padding-right: 20px;
}

.them-caro2 .owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  right: 0;
  z-index: 500;
}

.them-caro2 .owl-prev {
  left: -20px;
  //background: url(../img/pre.svg) no-repeat !important;
}

.them-caro2 .owl-next {
  right: -20px;
  //background: url(../img/next.svg) no-repeat !important;
}

.them-caro2 .owl-nav button {
  position: absolute;
  width: 30px;
  height: 24px;
  background-size: contain;
  text-indent: -999999px;
}

.them-caro2 .owl-prev.disabled {
  opacity: 0.6;
}

.them-caro2 .owl-next.disabled {
  opacity: 0.6;
}

.subscribeBox {
  // width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url("https://theecode-development.s3.us-west-2.amazonaws.com/theecode-crm/websitePageTempImages/1uausi00dtd.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 45px;
}

.subFrom h3 {
  color: #fff;
}

.subFrom p {
  color: #fff;
  font-weight: bold;
}

/* .fromBox {
padding-top: 30px;
} */

.fromBox .form-group,
.widget_mailpoet_form .form-group {
  margin-bottom: 25px;
}

.fromBox .form-group label,
.widget_mailpoet_form .form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 16px;
  color: #fff;
}

.fromBox .form-control input,
.widget_mailpoet_form .form-control input {
  display: block;
  width: 100%;
  padding: 10px !important;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: none;
  border-radius: 4px;
}

.fromBox .form-check,
.widget_mailpoet_form .form-check {
  margin-bottom: 30px;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 4px;
}

.container-checkbox:hover input ~ .checkmark {
  background-color: #fff;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: #ff4e00;
}

.container-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.contactpli {
  background: #eff4f8;
  position: relative;
}

.contactpli img {
  width: 100%;
}

.marketing {
  position: absolute;
  left: 50%;
  top: 70%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 752px;
}

footer {
  background: #080b36;
}

.logobox img {
  max-width: 160px;
}

.footer-sec {
  padding: 60px 0;
}

.footerTop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.addressInfobox h4 {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 4px solid #ff4e00;
}

.addressInfobox p {
  /* font-size: 18px; */
  color: #fff;
  margin-bottom: 0;
  /* line-height: 1.750em; */
}

.color-link {
  /* font-size: 18px; */
  font-weight: bold;
  color: #13d8fc;
  text-decoration: none;
  /* line-height: 1.75em; */
}

.color-link i {
  position: relative;
  top: 6px;
}

.menu-footer-quick-link-container ul,
.menu-footer-resource-menu-container ul {
  padding-left: 0px;
}

.addressInfobox ul li {
  margin-bottom: 14px;
  padding-left: 0;
  list-style: none;
}

.addressInfobox ul li a {
  /* font-size: 18px; */
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.addressInfobox ul li a:hover {
  color: #13d8fc;
}

.socalBox {
  text-align: right;
}

.socalBox ul {
  padding-left: 0;
}

.socalBox ul li {
  display: inline-block;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.socalBox ul li a {
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 45px;
  text-decoration: none;
  position: relative;
}

.socalBox ul li a::before {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 10px;
  content: "";
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 46%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.socalBox ul li a span {
  color: #13d8fc;
  position: relative;
  z-index: 1;
}

.socalBox ul li a:hover span {
  color: #ff4e00;
}

.footer-btm {
  padding: 20px 0;
}

.flexFtr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.leftFtr {
  font-size: 12px;
  color: #fff;
  /* font-weight: bold; */
}

.leftFtr ul {
  display: inline-block;
  padding-left: 0;
  font-size: 12px;
}

.leftFtr ul li {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0;
}

.leftFtr ul li:first-child {
  padding-left: 0;
}

/* .leftFtr ul li:nth-child(2) a {
color: #ff4e00;
} */

.leftFtr ul li a {
  text-decoration: none;
  color: #fff;
}

.leftFtr a:hover {
  text-decoration: underline;
}

.back-top a {
  color: #13d8fc;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.back-top a i {
  position: relative;
  margin-left: 6px;
}

.desk-nan {
  display: none;
}

.fullBoxnav {
  padding: 50px;
  position: fixed;
  background: #080b36;
  left: 100px;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 5000;
  display: none;
  overflow: auto;
}

.fullBoxnav::-webkit-scrollbar {
  width: 0;
}

.fullBoxnav::-webkit-scrollbar-track {
  background: #fff;
}

.fullBoxnav::-webkit-scrollbar-thumb {
  border: 1 solid #fff;
  border-radius: 10px;
  background: #81b3fe;
}

.fullBoxnav.expand {
  display: block;
}

.fullBoxnav h4 {
  font-size: 45px;
  font-weight: bold;
  color: #fff;
  padding-bottom: 5px;
  margin-bottom: 30px;
}

.fullBoxnav h4 span {
  border-bottom: 4px solid #ff4e00;
}

.fullBoxnav ul {
  padding-right: 5%;
}

.fullBoxnav ul li {
  margin-bottom: 15px;
}

.fullBoxnav ul li a {
  font-size: 35px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
}

.fullBoxnav ul li a:hover {
  color: #13d8fc;
}

.topUl-flex .sf-menu {
  margin: 0 -15px;
}

.topUl-flex .sf-menu li ul li .sf-with-ul {
  font-size: 20px;
}

.topUl-flex .submenu-container {
  top: 200px;
}

.topUl-flex {
  border-bottom: 8px solid rgba(255, 255, 255, 0.125);
  display: inline-block;
  width: 100%;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

/*--- markets page ---*/
.page-id-4787 .heading-box h1 {
  color: #008000 !important;
}

.page-id-4787 .breadcrumb_last {
  color: #008000 !important;
}

.newAddress p {
  line-height: 30px;
}

.newAddress .new-pra br {
  display: block !important;
}

/*--- Common Box ---*/

.marketBox {
  padding: 84px 0 84px 0;
}

.marketBox + .marketBox {
  padding-top: 0;
}

.row-marge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0 15px;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0 15px;
}

.revercerow {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.orderList li br {
  display: none;
}

.contentPad p br {
  display: none;
}

.col-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0;
  padding-right: 20px;
}

.marketBox li::after,
.unLIst1 li::after {
  color: #ff4e00;
  position: absolute;
  left: 0;
  top: 0;
  content: "\f0dd";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.orderList li {
  list-style: decimal;
}

ol li::marker {
  position: absolute;
  left: 0;
  top: 0;
}

.halfSection {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.half50 {
  width: 50%;
}

.half50.bgbluegrdt,
.bgbluegrdt {
  background: -o-linear-gradient(#08326a 0%, #176791 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#08326a),
    to(#176791)
  );
  background: linear-gradient(#08326a 0%, #176791 100%);
}

.half50.bgbluegrdt {
  background: -o-linear-gradient(#08326a 0%, #176791 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#08326a),
    to(#176791)
  );
  background: linear-gradient(#08326a 0%, #176791 100%);
}

.half50.bgbluegrdt .contentPad h2,
.half50.bgbluegrdt .contentPad h3,
.half50.bgbluegrdt .contentPad h4,
.half50.bgbluegrdt .contentPad p,
.half50.bgbluegrdt .contentPad li,
.half50.bgbluegrdt .contentPad table,
.bgbluegrdt h2,
.bgbluegrdt h3,
.bgbluegrdt h4,
.bgbluegrdt p,
.bgbluegrdt li,
.bgbluegrdt table {
  color: #fff;
}

.half50 .img-full {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contentPad {
  padding: 50px;
}

.contentPad h2 {
  margin-bottom: 20px;
}

.img-full img {
  width: 100%;
  margin-bottom: 0;
  display: block;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

/*--- caroucel 3 ---*/

.nobg-img {
  background-image: none;
  background-color: #080b36;
}

.divCenetr h2 {
  text-align: center;
  font-size: 46px;
  font-weight: bold;
  color: #fff;
}

.them-caro3 {
  padding-left: 20px;
  padding-right: 20px;
}

.them-caro3 .owl-nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  right: 0;
  z-index: 500;
}

.them-caro3 .owl-prev {
  left: -20px;
  //background: url(../img/pre.svg) no-repeat !important;
}

.them-caro3 .owl-next {
  right: -20px;
  //background: url(../img/next.svg) no-repeat !important;
}

.them-caro3 .owl-nav button {
  position: absolute;
  width: 30px;
  height: 24px;
  background-size: contain;
  text-indent: -999999px;
}

.them-caro3 .owl-prev.disabled {
  opacity: 0.6;
}

.them-caro3 .owl-next.disabled {
  opacity: 0.6;
}

/*--- Location Page ---*/

.filterBy {
  padding: 35px 0;
  background: #080b36;
  position: relative;
  z-index: 1;
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.filterFlex label {
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.filterFlex .form-group {
  padding: 0 14px;
}

.filterFlex .choices {
  margin-bottom: 0;
}

.filterFlex .form-group .form-control {
  height: 49px;
  border-radius: 4px;
  background: #f3f2f2;
  color: #848484;
  font-size: 16px;
  padding: 0 15px;
  min-width: 275px;
}

input:focus {
  outline: none;
}

div.dropdown {
  position: relative;
}

div.dropdown > div.caption {
  background: #f2f2f2;
  padding: 0 30px 0 15px;
  border-radius: 3px;
  cursor: pointer;
  height: 49px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 275px;
  position: relative;
}

div.dropdown > div.caption::after {
  position: absolute;
  content: "\f107";
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

div.dropdown > div.list {
  position: absolute;
  background-color: #f2f2f2;
  width: 100%;
  border-radius: 0 0 3px 3px;
  display: none;
  z-index: 1;
}

div.dropdown > div.list > div.item {
  padding: 11px 24px;
  cursor: pointer;
}

div.dropdown > div.list > div.item.selected {
  font-weight: bold;
}

div.dropdown > div.list > div.item:hover {
  background-color: #13d8fc;
  color: #fff;
}

div.dropdown.open > div.caption {
  border-radius: 3px 3px 0 0;
  border-bottom: solid 1px #999;
}

div.dropdown.open > div.list {
  display: block;
}

.mapSection {
  position: relative;
}

.mapSection img {
  width: 100%;
}

.pli-asheville {
  background: #fff;
  padding: 30px;
  position: absolute;
  right: 150px;
  bottom: 150px;
}

.addresspnl1 {
  padding: 100px 0;
  padding-bottom: 30px;
}

.addresspnl1.gray-box {
  padding-top: 50px;
  padding-bottom: 100px;
}

.addressbox1 h4 {
  padding-bottom: 8px;
  margin-bottom: 15px;
  border-bottom: 4px solid #ff4e00;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.addressbox1 h4 .colorLink {
  font-size: 14px;
}

/* .addressbox1 p {
font-size: 18px;
font-weight: bold;
color: #141414;
} */

.addressbox1 p a {
  font-weight: bold;
}

.row25 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-25 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.colorLink {
  font-weight: bold;
  color: #009cdd;
  float: right;
}

.colorLink i {
  position: relative;
  /* top: 4px; */
}

.gray-box {
  background: #f1f4f9;
}

.col-30 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 34.333%;
  flex: 0 0 34.333%;
  max-width: 34.333%;
}

.linehr {
  width: 100%;
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 40px;
  border-bottom: 2px solid #e0dddd;
}

.newAddress h6 {
  color: #ff4e00;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.new-pra {
  font-size: 20px;
  font-weight: bold;
  color: #08326a !important;
}

.newAddress .new-pra strong {
  font-size: 20px;
  line-height: 34px;
}

.new-pra a {
  color: #009cdd;
  text-decoration: none;
}

.font-size18 {
  font-size: 18px !important;
}

.form-row2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.fromBox span.wpcf7-form-control-wrap,
.widget_mailpoet_form span.wpcf7-form-control-wrap {
  position: relative;
  display: inline-block;
  width: 100%;
}

.fromBox .wpcf7-not-valid-tip,
.widget_mailpoet_form .wp-not-valid-tip {
  color: red;
  font-size: 12px;
  top: 5px;
  position: relative;
}

.fromBox .wpcf7-response-output,
.widget_mailpoet_form .wpcf7-response-output {
  padding-top: 10px;
}

.fromBox .form-group,
.widget_mailpoet_form .form-group {
  margin-bottom: 25px;
}

.fromBox .col-md-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

.fromBox .col-md-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  padding-left: 15px;
}

.fromBox label,
.widget_mailpoet_form label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.marketBox .fromBox .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background: #f3f2f2;
  background-clip: padding-box;
  border: none;
  border-radius: 4px;
  min-height: 49px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.marketBox .fromBox .form-control::-webkit-input-placeholder {
  border: none !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.marketBox .fromBox .form-control::-moz-placeholder {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.marketBox .fromBox .form-control:-ms-input-placeholder {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.marketBox .fromBox .form-control::-ms-input-placeholder {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.marketBox .fromBox .form-control::placeholder {
  border: none !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.textArea {
  height: 135px;
  font-family: "Montserrat", sans-serif;
}

.fromBox .form-group label,
.widget_mailpoet_form .form-group label {
  font-size: 16px;
  color: #141414;
  font-weight: normal;
}

.subscribeBox .fromBox .form-group label {
  color: #fff;
}

.fromBox .form-group label sup,
.widget_mailpoet_form .form-group label sup {
  color: #ff4e00;
  font-size: 16px;
  position: relative;
  top: 0px;
}

.fromBox .form-group small,
.widget_mailpoet_form .form-group small {
  color: #707070;
}

.fromBox h5,
.widget_mailpoet_form h5 {
  font-size: 20px;
  font-weight: bold;
  color: #08326a;
  margin-bottom: 20px;
}

.flexCheck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flexCheck .form-check {
  padding-right: 20px;
}

.flexCheck .form-check label {
  font-size: 18px;
  color: #141414;
}

.flexCheck .container-checkbox .checkmark {
  background: #f3f2f2;
  border: 1px solid #a8a8a8;
}

/*--- Capabilities page ---*/

.connect-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /*padding-left: 100px;
padding-right: 100px;*/
  padding-bottom: 40px;
}

/* .owl-stage {width: auto!important;;} */

/*--- Hospitality page ---*/

.allCategory {
  padding-top: 90px;
  padding-bottom: 90px;
  background: #080b36;
}

.arw-in2 {
  display: none;
  position: absolute;
  right: 25px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 30%;
  color: #fff;
}

.box-category:hover .arw-in2 {
  display: block;
}

.loop .box-category {
  display: inline-block;
  width: 100%;
}

/* .heading-border {
position: relative;
width: 85%;
} */

/* .heading-border::after {
position: absolute;
left: 0;
right: 0;
height: 4px;
background-color: #ff4e00;
content: "";
bottom: 0;
} */

.form-check .wpcf7-form-control-wrap::after {
  display: none !important;
}

.form-check .wpcf7-form-control-wrap::before {
  display: none !important;
}

.container-checkbox2 label {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff !important;
}

.container-checkbox2 label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-checkbox2 label .wpcf7-list-item-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff !important;
  border-radius: 4px;
  text-indent: -999999px;
}

.container-checkbox2 label:hover input ~ .wpcf7-list-item-label {
  background-color: #fff !important;
}

.container-checkbox2 label input:checked ~ .wpcf7-list-item-label {
  background-color: #ff4e00 !important;
}

.container-checkbox2 .wpcf7-list-item-label:after {
  content: "";
  position: absolute;
  display: none;
  //background: url(../img/tick-sign.png) no-repeat;
  background-size: contain;
}

.container-checkbox2 label input:checked ~ .wpcf7-list-item-label:after {
  display: block;
}

.container-checkbox2 .wpcf7-list-item-label:after {
  left: 2px;
  top: 4px;
  width: 15px;
  height: 10px;
  /* border: 1px solid #fff !important; */
  /* border-width: 0 3px 3px 0; */
  /* transform: rotate(45deg); */
}

.flexCheck .form-check {
  position: relative;
}

.flexCheck .container-checkbox2 label .wpcf7-list-item-label {
  background: #f3f2f2;
  border: 1px solid #a8a8a8;
  margin: 0;
}

.container-checkbox2 .wpcf7-list-item-label {
  color: #333 !important;
}

.paralal {
  position: relative;
  top: -21px;
  left: 30px;
  font-weight: normal;
}

@media only screen and (min-width: 768px) and (max-width: 10000px) {
  .addressInfobox {
    min-width: 185px;
  }
}

.eachBox {
  min-width: 710px;
  font-size: 36px !important;
}

.bolder-padding {
  padding: 80px 0;
}

/* .bialty-container {
padding: 80px 0;
width: 95%;
margin: 0 auto;
} */

.bialty-container h4 {
  color: #ff4e00;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 15px;
}

.bialty-container .testimonial--info {
  color: #666;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5em;
  display: inline-block;
  font-weight: bold;
  width: 100%;
}

.bolder-padding p label {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}

.bolder-padding p input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background: #f3f2f2;
  background-clip: padding-box;
  border: none;
  border-radius: 4px;
  min-height: 49px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
}

.bolder-padding p button[type="submit"] {
  background: -o-linear-gradient(
    left,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 78, 0, 1)),
    to(rgba(236, 159, 5, 1))
  );
  background: linear-gradient(
    to right,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  display: inline-block;
  text-align: center;
  height: 49px;
  border-radius: 50px;
  padding: 0 33px;
  line-height: 49px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.justify-center p {
  margin-bottom: 15px;
}

.fromBox label,
.widget_mailpoet_form label {
  color: #fff;
}

.mailpoet_submit {
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  display: inline-block;
  text-align: center;
  height: 49px;
  border-radius: 50px;
  padding: 0 33px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  min-width: 169px !important;
  line-height: 41px;
  width: auto !important;
}

.blog-listing-container .respnds {
  border: 1px solid #ddd;
}

.blog-listing-container .page-sidebar h3 {
  margin-bottom: 10px;
}

.blog-listing-container .respnds .slideImg {
  width: 30%;
  border-right: 6px solid #ff4e00;
  overflow: hidden;
  position: relative;
  width: 60%;
}

.blog-listing-container .respnds .slideImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
}

.blog-listing-container .respnds .slidecontent {
  width: 69%;
  padding: 30px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 50%;
}

.blog-listing-container .widget_search form label {
  font-size: 16px;
  font-weight: bold;
  color: #141414;
  width: 100%;
}

.blog-listing-container .widget_search form input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background: #f3f2f2;
  background-clip: padding-box;
  border: none;
  border-radius: 4px;
  min-height: 49px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
}

.blog-listing-container .widget_search form button[type="submit"] {
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  display: inline-block;
  text-align: center;
  height: 49px;
  border-radius: 50px;
  padding: 0 33px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  min-width: 169px !important;
  line-height: 41px;
  width: auto !important;
}

.blog-listing-container .widget_mailpoet_form input[type="email"] {
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background: #f3f2f2;
  background-clip: padding-box;
  border: none;
  border-radius: 4px;
  min-height: 49px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
}

.blog-listing-container .widget_mailpoet_form form.mailpoet_form {
  padding: 0 !important;
}

.mapSection .gm-style .gm-style-iw-c {
  border-radius: 0 !important;
  padding: 20px !important;
}

.mapSection .map-marker h2 {
  font-weight: bold !important;
  color: #08326a !important;
  padding-bottom: 8px !important;
  margin-bottom: 15px !important;
  border-bottom: 4px solid #ff4e00;
  font-size: 20px !important;
}

.mapSection .map-marker p {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #141414 !important;
}

.mapSection .map-marker p a {
  font-weight: normal !important;
}

.search-toggle .search-icon {
  color: #fff;
  border: 0;
  outline: none;
  background: transparent;
  padding: 0;
  position: relative;
  top: 4px;
}

.search-toggle .search-icon:hover {
  color: #13d8fc;
}

.search-toggle {
  text-decoration: none;
  display: inline-block;
}

.search-toggle .search-icon.icon-close {
  display: none;
}

.search-toggle.opened .search-icon.icon-search {
  display: none;
}

.search-toggle.opened .search-icon.icon-close {
  display: block;
}

.search-container {
  position: relative;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  max-height: 0;
  overflow: hidden;
  background-color: #eee;
  left: 0;
  right: 0;
  top: 100%;
}

.search-container.opened {
  max-height: 100px;
  padding: 10px 2.8% 18px;
}

.search-container input[type="search"] {
  outline: none;
  font-size: 14px;
  margin: 0;
  width: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  padding: 10px;
  height: 60px;
}

.search-container .search-icon {
  vertical-align: middle;
  width: 5%;
  top: 4px;
  position: relative;
  font-size: 20px;
}

.search-container form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.search-toggle .search-icon i {
  position: relative;
  font-size: 34px;
  /* color: #fff; */
}

.search-container .search-icon i {
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  height: 60px;
  padding: 0 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
}

.search-container {
  position: absolute;
  z-index: 2;
}

/*--- Media Query ---*/

@media only screen and (min-width: 2500px) and (max-width: 10000px) {
  .solution-textbox p {
    font-size: 28px;
  }

  .advantage-pli p {
    font-size: 28px;
    line-height: normal;
  }

  .pragraph p {
    font-size: 24px;
    line-height: normal;
  }

  .mitter1 p {
    font-size: 24px;
    line-height: normal;
  }

  .respnds .slidecontent h3 {
    font-size: 28px;
  }

  .respnds .slidecontent p {
    font-size: 20px;
    line-height: normal;
  }

  .marketing p {
    font-size: 24px;
    line-height: normal;
  }

  .flextalk h2 {
    font-size: 70px;
  }

  .connect-flex {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .connect-flex .item-box {
    margin: 0 25px;
  }
}

@media only screen and (min-width: 1920px) and (max-width: 2560px) {
  .connect-flex {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .connect-flex .item-box {
    margin: 0 25px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .respnds .slidecontent p {
    -webkit-line-clamp: 3;
    margin-bottom: 0;
  }

  .contactpli {
    padding-bottom: 100px;
  }

  .marketing {
    top: 65%;
  }

  .heading-border {
    width: 75%;
  }

  .solution-textbox p {
    font-size: 16px;
  }

  .btn,
  .readmore {
    font-size: 14px;
  }

  .connect-flex {
    padding-left: 0;
    padding-right: 0;
  }

  .addressbox1 h2 {
    font-size: 20px;
  }

  .colorLink {
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .halfSection {
    display: -webkit-box;
  }

  .halfSection .img-full img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .solution-textbox h2 {
    font-size: 34px;
  }

  .heading-border {
    width: 100%;
  }

  .solution-textbox p {
    font-size: 14px;
  }

  .flextalk h2 {
    font-size: 28px;
  }

  .pro-box-head {
    font-size: 16px;
  }

  .respnds .slidecontent h3 {
    font-size: 14px;
  }

  .subFrom h4 {
    font-size: 20px;
  }

  /* .subFrom p {
  font-size: 14px;
} */
  .respnds .slidecontent p {
    font-size: 12px;
    -webkit-line-clamp: 3;
    margin-bottom: 0;
  }

  .btm-content h2 {
    font-size: 22px;
  }

  .btm-content p {
    font-size: 14px;
  }

  .contactpli {
    padding-bottom: 100px;
  }

  .addressInfobox h4 {
    font-size: 18px;
  }

  /* .addressInfobox p {
  font-size: 14px;
} */
  /* .addressInfobox ul li a {
  font-size: 14px;
} */
  .newAddress h6 {
    font-size: 16px;
  }

  .connect-flex {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-wrap: inherit;
    flex-wrap: inherit;
  }

  .connect-flex .item-box {
    margin: 0 15px;
  }

  .addressbox1 h2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 16px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .colorLink {
    font-size: 16px;
  }

  .addressbox1 p {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1027px) {
  .btnand-search .btn {
    float: right;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .pro-box-head {
    font-size: 10px;
    height: auto;
    padding-bottom: 15px;
  }

  .btm-content h2 {
    font-size: 18px;
  }

  .btm-content p {
    font-size: 12px;
  }

  .respnds .slidecontent h3 {
    font-size: 14px !important;
  }

  .respnds .slidecontent p {
    font-size: 12px;
    line-height: normal;
  }

  .respnds .slideImg img {
    height: 170px;
  }

  .flextalk h2 {
    font-size: 24px;
  }

  .po-absolute {
    top: 44%;
  }

  .fromBox,
  .widget_mailpoet_form {
    padding-top: 0;
  }

  .subFrom p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .fromBox .form-group label,
  .widget_mailpoet_form .form-group label {
    font-size: 12px;
  }

  .container-checkbox {
    font-size: 12px;
  }

  .fromBox .form-check,
  .widget_mailpoet_form .form-check {
    margin-bottom: 10px;
  }

  .marketing p br {
    display: none;
  }

  .addressInfobox {
    min-width: 125px;
  }

  .marketing {
    top: 60%;
  }

  .halfSection {
    display: -webkit-box;
  }

  .halfSection .img-full {
    height: 100%;
  }

  .halfSection .img-full img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .colorLink {
    display: block;
    font-size: 12px;
  }

  .colorLink i {
    font-size: 12px;
    top: 2px;
  }

  .addressbox1 p {
    font-size: 12px;
  }

  .addressbox1 h2 {
    font-size: 12px;
  }

  .readmore {
    font-size: 12px;
  }

  .addresspnl1 {
    padding: 60px 0;
  }

  .pli-asheville {
    bottom: 40px;
  }
}

@media only screen and (min-width: 1025px) {
  .img-full {
    width: 50%;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .pro-box-head {
    font-size: 10px;
    height: auto;
    padding-bottom: 15px;
  }

  .po-absolute {
    top: 44%;
  }

  .fromBox,
  .widget_mailpoet_form {
    padding-top: 0;
  }

  .halfSection .img-full img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .colorLink i {
    font-size: 16px;
    top: 2px;
  }
}

@media only screen and (max-width: 1221px) {
  .tabnan {
    display: none;
  }

  .top-detail ul {
    display: none;
  }

  .pro-box-head::before {
    height: 2rem;
  }

  .po-absolute {
    top: 50%;
  }

  .advantage-pli {
    width: 60%;
  }

  .yearsSec {
    width: 40%;
  }

  .subscribeBox {
    padding: 20px;
  }

  .leftFtr {
    font-size: 14px;
  }

  .back-top a {
    font-size: 14px;
  }

  .leftFtr ul li {
    padding-left: 5px;
    padding-right: 5px;
  }

  .leftFtr ul li:first-child {
    padding-left: 0;
  }

  .logobox img {
    width: 100px;
  }

  .btn {
    /* padding: 0 15px; */
    font-size: 14px;
  }

  .respnds .slideImg img {
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .pragraph p {
    font-size: 16px;
  }

  .advantage-pli p {
    font-size: 16px;
  }

  .mitter1 p {
    font-size: 16px;
  }

  .respnds .slidecontent h3 {
    font-size: 22px;
  }

  .respnds .slidecontent p {
    font-size: 16px;
  }

  .marketing p {
    font-size: 16px;
  }

  .btn i {
    top: 8px;
  }
}

.top-searchbar {
  display: none;
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
  .marketing {
    top: 60%;
  }

  .leftFtr {
    font-size: 12px;
  }

  .back-top a {
    font-size: 12px;
  }

  .col-25 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }

  .col-30 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45%;
    flex: 0 0 45%;
    max-width: 45%;
  }

  .addresspnl1 {
    padding: 60px 0;
  }

  .pli-asheville {
    bottom: 40px;
  }

  .search-input {
    left: 60px;
  }
}

@media only screen and (max-width: 1024px) {
  .search-input {
    left: 60px;
  }

  .bdyClass {
    padding-left: 60px;
  }

  // body {
  //   padding-left: 60px;
  // }

  .left-sidebar li {
    padding: 10px;
  }

  .left-sidebar li img {
    width: 30px;
  }

  .left-sidebar li a i {
    font-size: 30px;
  }

  .solution-textbox h2 {
    font-size: 38px;
  }

  .solution-textbox h2 br {
    display: none;
  }

  .solution-textbox p {
    font-size: 16px;
  }

  .wrap {
    padding: 20px 0;
  }

  .bannerSec {
    padding: 20px 0;
  }

  .btn {
    font-size: 14px;
  }

  .solution-textbox {
    padding-right: 0;
  }

  .btm-content h2 {
    font-size: 14px;
  }

  .btm-content p {
    font-size: 11px;
  }

  .btm-content {
    padding-bottom: 15px;
  }

  .logo img {
    width: 126px;
  }

  .navbar .menu li a {
    font-size: 16px;
    padding: 0 10px;
  }

  .top-detail {
    font-size: 14px;
  }

  .searchIcon {
    padding-right: 10px;
  }

  .top-detail {
    margin-bottom: 10px;
  }

  .pro-box-head {
    font-size: 16px;
    overflow: hidden;
  }

  .pro-box-head img {
    width: auto;
  }

  .consumerEngage::after {
    height: 200px;
  }

  .pt-80 {
    padding-top: 40px;
  }

  #menu-icon {
    top: 20px;
  }

  .top-detail ul {
    display: none;
  }

  .tabnan {
    display: none !important;
  }

  .bnrFlex {
    display: inline-block;
    width: 100%;
  }

  .solution-textbox {
    width: 100%;
  }

  .right-img {
    width: 100%;
    padding-left: 0;
    margin-top: 40px;
  }

  .d-flex {
    display: inline-block;
    width: 100%;
  }

  .pr-50 {
    padding-right: 0;
  }

  .w-100 {
    width: 100% !important;
  }

  .sf-menu > li > ul > li {
    width: 30%;
  }

  .pliAdvantage {
    display: inline-block;
    width: 100%;
  }

  .advantage-pli {
    width: 100%;
  }

  .yewd {
    padding-right: 0;
    padding-top: 40px;
  }

  .yearsSec {
    width: 100%;
  }

  .cardTalk {
    display: inline-block;
    width: 100%;
  }

  .cardTalkbg {
    width: 100%;
    padding: 40px 20px;
  }

  .subscribeBox {
    width: 100%;
    padding: 40px 20px;
  }

  .subFrom {
    width: 65%;
  }

  .contactpli {
    // background-image: url(../img/contactpli.jpg);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 240px 100px 100px;
  }

  .contactpli img {
    display: none;
  }

  .footer-sec {
    padding: 40px 0;
  }

  .consumerEngage {
    padding: 40px 0;
  }

  .marketing {
    position: relative;
    top: inherit;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    width: 100%;
    left: inherit;
  }

  .flextalk h2 {
    font-size: 34px;
  }

  .desk-nan {
    display: block;
  }

  .tab-nan {
    display: none;
  }

  .logobox {
    width: 48%;
    padding: 0 15px;
  }

  .addressInfobox {
    width: 48%;
    padding: 0 15px;
  }

  .footerTop {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    margin: 0 -15px;
  }

  .socalBox {
    text-align: left;
    padding-top: 15px;
  }

  .flexFtr {
    width: 100%;
    display: inline-block;
    text-align: center;
  }

  .leftFtr ul {
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
  }

  .back-top {
    padding-top: 10px;
  }

  .back-top a i {
    top: 8px;
  }

  .respnds .slidecontent p {
    -webkit-line-clamp: 3;
  }

  .socalBox ul li a img {
    width: auto !important;
  }

  .fullBoxnav {
    left: 60px;
  }

  .fullBoxnav ul li a {
    font-size: 24px;
  }

  .search {
    left: 60px;
  }

  .them-caro2 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .them-caro2 .owl-next {
    right: 0;
  }

  .them-caro2 .owl-prev {
    left: 0;
  }

  .subscribeBox {
    background-size: cover;
    background-color: #080b36;
    //background-image: url(../img/grp.png);
    background-position: left bottom;
  }

  .addressInfobox h4 {
    font-size: 16px;
  }

  /* .addressInfobox ul li a {
  font-size: 18px;
}
.addressInfobox p {
  font-size: 16px;
} */
  .row-marge {
    display: inline-block;
    width: 100%;
    margin: 0;
  }

  .col-4 {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
  }

  .col-8 {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
  }

  .heading-box h1 {
    /* font-size: 34px; */
    margin-bottom: 16px;
  }

  .halfSection {
    display: inline-block;
    width: 100%;
  }

  .half50 {
    width: 100%;
  }

  .divCenetr h2 {
    font-size: 34px;
  }

  .nobg-img {
    background-image: none !important;
    background-color: #080b36 !important;
  }

  .filterFlex .form-group .form-control {
    min-width: 180px;
  }

  div.dropdown > div.caption {
    min-width: 180px;
  }

  .filterFlex .form-group {
    padding: 0 5px;
  }

  .pli-asheville {
    position: relative;
    left: inherit;
    right: inherit;
    bottom: inherit;
  }

  .col-25 {
    width: 100%;
    max-width: 50%;
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
  }

  .col-30 {
    width: 100%;
    max-width: 50%;
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
  }

  .addresspnl1 {
    padding: 40px 0;
  }

  .addressbox1 h2 {
    font-size: 18px;
  }

  .addressbox1 p {
    font-size: 14px;
  }

  .colorLink {
    font-size: 18px;
    position: relative;
    top: -5px;
  }

  .pli-asheville {
    padding: 25px;
  }

  .heading-box {
    padding: 30px 0 30px;
  }

  .marketBox {
    padding-bottom: 60px;
  }

  .connect-flex {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding-bottom: 0;
  }

  .connect-flex .item-box {
    width: 33.333%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
  }

  .allCategory {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .d-nan {
    display: none;
  }

  .light-img {
    padding: 0;
  }
}

.bg-responsive {
  display: none;
}

.hdrBar2 {
  display: none;
}

.d-nann2 {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-sidebar {
    padding-top: 40px;
  }

  .owl-carousel .owl-stage {
    padding-left: 0 !important;
  }

  .heading-border {
    width: 100%;
  }

  .d-nann2 {
    display: block;
  }

  .d-nann1 {
    display: none;
  }

  .light-img {
    padding: 0;
  }

  .mitter1 {
    padding: 25px;
  }

  .respnds .slidecontent h3 {
    font-size: 18px;
  }

  .respnds .slidecontent {
    padding: 15px;
  }

  .respnds .slidecontent p {
    -webkit-line-clamp: 6;
    font-size: 14px;
  }

  .respnds .slideImg img {
    height: 260px;
  }

  .them-caro2 {
    padding-left: 60px;
    padding-right: 60px;
  }

  .subscribeBox {
    padding: 50px 20px 100px;
  }

  .contactpli {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 170px;
    //background-image: url(../img/contactpli2.jpg);
  }

  .addressInfobox {
    margin-bottom: 30px;
  }

  .addressInfobox ul li {
    margin-bottom: 20px;
  }

  .eachBox {
    min-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .light-img {
    padding: 0 0 0 34px;
  }

  .header {
    display: none;
  }

  .bdyClass {
    padding-left: 0;
  }

  /* .container {
  width: 90%;
} */
  .solution-textbox h2 {
    font-size: 28px;
  }

  .flex-4 {
    display: inline-block;
    width: 100%;
  }

  .box-category {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
  }

  .box-category img {
    height: 150px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .btm-content h2 {
    font-size: 20px;
  }

  .border-heading2 {
    display: initial;
  }

  .advantage-pli {
    display: block;
    width: 100%;
  }

  .light-img {
    width: 100%;
    text-align: center;
  }

  .light-img img {
    width: 100px;
    position: relative;
    margin-top: 0;
  }

  .consumerEngage {
    z-index: 1;
  }

  .yewd {
    width: 100%;
  }

  .inovation {
    display: inline-block;
    width: 100%;
  }

  .mitter1 {
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #e0dddd !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .mitter1 .number {
    margin-right: 20px;
    margin-bottom: 0;
    width: 62px;
    height: 62px;
  }

  .white-curcle {
    width: 54px;
    height: 54px;
    font-size: 24px;
  }

  .border-right {
    border: none;
  }

  .mitter1 p {
    width: 53%;
  }

  .mitter1:last-child {
    border: none !important;
  }

  .flextalk {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .flextalk h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .respnds {
    display: inline-block;
    width: 100%;
  }

  .respnds .slideImg {
    width: 100%;
    border-right: none;
    border-bottom: 6px solid #ff4e00;
  }

  .respnds .slidecontent {
    width: 100%;
    padding: 15px;
  }

  .them-caro2 {
    padding: 0;
  }

  .them-caro2 .owl-nav {
    position: relative;
    padding-top: 15px;
    text-align: center;
  }

  .them-caro2 .owl-nav button {
    position: relative;
    margin: 0 15px;
  }

  .blog-listing-container .respnds .slideImg {
    width: 100% !important;
    border-right: none !important;
    height: 200px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .subFrom {
    width: 100%;
  }

  .subFrom h4 {
    font-size: 19px;
  }

  .subFrom p {
    font-size: 14px;
  }

  .contactpli {
    padding: 0 0 40px;
    background-image: none;
  }

  .marketing {
    padding: 0 40px;
    position: relative;
    margin-top: -50px;
  }

  .bg-responsive {
    display: block !important;
  }

  .footerTop {
    display: inline-block;
    width: 100%;
    margin: 0;
  }

  .logobox {
    width: 100%;
    padding: 15px 0;
    text-align: center;
  }

  .logobox img {
    width: auto;
  }

  .socalBox {
    text-align: center;
  }

  .addressInfobox {
    padding: 15px 0;
    width: 100%;
  }

  .pro-box-head {
    height: auto;
    font-size: 20px;
  }

  .top-searchbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #08326a;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .callBlock {
    display: inline-block;
    padding: 15px 10px;
    font-size: 12px;
    color: #13d8fc !important;
    text-decoration: none;
    font-weight: bold;
    border-right: 1px solid #080b36;
    width: 45%;
    text-align: center;
  }

  .callBlock:nth-child(2) {
    border: none;
  }

  .searchbox {
    position: absolute;
    min-width: 46px;
    width: 0%;
    height: 46px;
    right: 0;
    overflow: hidden;
    -webkit-transition: width 0.3s;
    -o-transition: width 0.3s;
    transition: width 0.3s;
  }

  .searchbox-input {
    top: 0;
    right: 0;
    border: 0;
    outline: 0;
    background: #08326a;
    width: 100%;
    height: 46px;
    margin: 0;
    padding: 0px 55px 0px 20px;
    font-size: 16px;
    color: #fff;
  }

  .searchbox-input::-webkit-input-placeholder {
    color: #fff;
  }

  .searchbox-input:-moz-placeholder {
    color: #fff;
  }

  .searchbox-input::-moz-placeholder {
    color: #fff;
  }

  .searchbox-input:-ms-input-placeholder {
    color: #fff;
  }

  .searchbox-icon,
  .searchbox-submit {
    width: 46px;
    height: 46px;
    display: block;
    position: absolute;
    top: 3px;
    font-family: verdana;
    font-size: 16px;
    right: 0;
    padding: 0;
    margin: 0;
    border: 0;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    background: #08326a;
    border: none;
  }

  .searchbox-icon:focus,
  .searchbox-submit:focus {
    outline: 0;
  }

  .searchbox-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .searchbox-icon i {
    color: #fff;
  }

  .searchbox-open {
    width: 100%;
  }

  .searchbox-open {
    position: absolute;
    right: 0;
  }

  .hdrBar2 {
    background: #080b36;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 22px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 50000;
  }

  .logo-mob {
    padding-right: 15px;
  }

  .logo-mob img {
    width: 100px;
  }

  .mob-menu img {
    width: 28px;
  }

  .hdrBar2 .sf-contener {
    width: 100%;
  }

  .pragraph p {
    font-size: 16px;
  }

  .advantage-pli p {
    font-size: 16px;
  }

  .mitter1 p {
    width: 70%;
    font-size: 16px;
  }

  .respnds .slidecontent h3 {
    font-size: 18px;
  }

  .respnds .slidecontent p {
    font-size: 16px;
    display: none;
  }

  .them-caro2 .owl-nav {
    padding-top: 30px;
  }

  .marketing p {
    font-size: 16px;
  }

  .addressInfobox h4 {
    font-size: 22px;
  }

  /* .addressInfobox p {
  font-size: 16px;
} */
  /* .addressInfobox ul li a {
  font-size: 16px;
} */
  .flex-row {
    display: inline-block;
    width: 100%;
  }

  .col-left {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
  }

  .blogUl {
    padding-left: 0;
  }

  .btnGrp a {
    margin-bottom: 15px;
  }

  .readmore {
    display: inline-block;
  }

  .contentPad {
    padding: 40px 20px;
  }

  .col-4 {
    padding: 0;
  }

  .col-8 {
    padding: 0;
  }

  .form-inline {
    display: inline-block;
    width: 100%;
  }

  .filterFlex .form-group {
    padding: 10px 0;
  }

  .filterFlex .form-group .form-control {
    min-width: 100%;
  }

  div.dropdown > div.caption {
    min-width: 100%;
  }

  .row25 {
    margin: 0;
  }

  .col-25 {
    padding: 0;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
  }

  .col-30 {
    padding: 0;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
  }

  .dl-menuwrapper .dl-menu.dl-menuopen::after {
    position: absolute;
    right: 18px;
    top: -8px;
    content: "";
    border-top: 18px solid #08326a;
    border-left: 18px solid transparent;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .newAddress h6 {
    font-size: 20px;
  }

  .font-size18 {
    font-size: 16px !important;
  }

  .form-row2 {
    margin-left: 0;
    margin-right: 0;
  }

  .fromBox .col-md-6 {
    padding: 0;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
    width: 100%;
  }

  .fromBox .col-md-4 {
    padding: 0;
    max-width: 100%;
    -webkit-box-flex: inherit;
    -ms-flex: inherit;
    flex: inherit;
    width: 100%;
  }

  .connect-flex {
    display: inline-block;
    width: 100%;
    margin: 0;
  }

  .connect-flex .item-box {
    width: 100%;
    padding: 0;
  }

  .allCategory {
    padding-top: 30px;
    padding-bottom: 10px;
  }

  .loop .box-category img {
    height: 424px;
  }

  .arw-in2 {
    display: block;
  }

  .owl-dots {
    display: none;
  }

  .mapSection img {
    height: 300px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .hdrBar2 {
    border-bottom: 1px solid #08326a;
  }

  .hdrBar2 .btn-secondary {
    margin-right: 16px;
  }

  .searchbox-icon,
  .searchbox-submit {
    top: 0;
    border-left: 1px solid #080b36;
  }

  .heading-border {
    width: 100%;
  }

  .btn i {
    top: 1px;
  }

  .btn {
    height: auto;
    line-height: inherit;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 15px !important;
  }

  .box-category {
    margin-bottom: 15px;
  }

  .owl-carousel .owl-stage {
    padding-left: 0 !important;
  }

  .flextalk .btn {
    margin-right: 0;
  }

  .yearsSec {
    padding-top: 60px;
  }

  .container-checkbox {
    font-size: 12px;
    line-height: 22px;
  }

  .subscribeBox {
    padding-bottom: 75px;
  }

  .contactpli img {
    margin-top: -19px;
  }

  .leftFtr ul li a {
    font-size: 12px;
  }

  .leftFtr {
    font-size: 12px;
  }

  .footer-sec {
    padding-bottom: 0;
  }

  .page-sidebar {
    padding-top: 40px;
  }

  .cardTalk {
    margin: -4px 0 0 0;
  }

  .eachBox {
    min-width: inherit;
    font-size: 24px !important;
  }

  .addressbox1 p br {
    display: none;
  }

  .halfSection {
    display: block;
  }
}

.them-caro1 .owl-nav {
  position: relative;
  top: 20px;
  left: 0;
  width: 100%;
  right: 0;
  z-index: 500;
  text-align: center;
}

.them-caro1 .owl-prev {
  left: -20px;
  // background: url(../img/pre.svg) no-repeat !important;
}

.them-caro1 .owl-next {
  right: -20px;
  //background: url(../img/next.svg) no-repeat !important;
}

.them-caro1 .owl-prev.disabled {
  opacity: 0.6;
}

.them-caro1 .owl-next.disabled {
  opacity: 0.6;
}

.them-caro1 .owl-nav button {
  position: relative;
  width: 30px;
  height: 24px;
  background-size: contain;
  text-indent: -999999px;
}

@media only screen and (max-width: 360px) {
  .btn {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 310px) {
  .btn {
    padding: 0 10px;
    font-size: 12px;
  }

  .logo-mob img {
    width: 60px;
  }

  .socalBox ul li a {
    width: 40px;
    height: 40px;
  }
}

@font-face {
  font-family: "icomoon";
  src: url("../src/assets/newfonts/icomoon.eot");
  src: url("../src/assets/newfonts/icomoon.eot") format("embedded-opentype"),
    url("../src/assets/newfonts/icomoon.ttf") format("truetype"),
    url("../src/assets/newfonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e900";
}

.icon-arrow-left:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-arrow-up:before {
  content: "\e903";
}

.icon-facebook:before {
  content: "\e904";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-linkedin:before {
  content: "\e906";
}

.icon-mail:before {
  content: "\e907";
}

.icon-phone:before {
  content: "\e908";
}

.icon-search:before {
  content: "\e909";
}

.icon-star:before {
  content: "\e90a";
}

.icon-twitter:before {
  content: "\e90b";
}

.icon-youtube:before {
  content: "\e90c";
}

.icon-leaf:before {
  content: "\e90d";
}

.search-icon.icon-search::before {
  display: none;
}

/* Home Page Redisign */

.top-bar {
  text-align: center;
  background-color: #104285;
  color: #fff;
  padding-block: 40px;
}

.top-bar__title {
  color: #fff;
}

@media (min-width: 768px) {
  .top-bar {
    padding-block: 60px;
  }
}

@media (min-width: 1025px) {
  .top-bar {
    padding-block: 84px;
  }
}

@media (min-width: 1500px) {
  .top-bar__title {
    font-size: 56px;
  }
}

.top-bar__text-cont * {
  color: #fff;
}

.top-bar__text-cont *:last-child {
  margin-bottom: 0;
}

.top-bar__cta-box {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.top-bar__title i {
  font-weight: normal;
}

.top-bar__title strong {
  border-bottom: 4px solid #f84d00;
}

.m_banners__container:before,
.m_banners__container:after {
  display: none;
}

.m_banners__item--overlay .m_benners_box {
  position: absolute;
}

.m_banners__container {
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
  max-width: 90%;
}

@media (min-width: 600px) {
  .m_banners__container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .m_banners__container {
    grid-template-columns: repeat(2, 1fr);
  }

  .m_banners__container {
    display: grid;
    grid-template-columns: 1fr max(400px, 30%);
    grid-template-rows: repeat(6, 100px);
    grid-auto-flow: column dense;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .m_banners__item--overlay .m_banners__img {
    position: absolute;
    top: 0;
  }

  .m_banners__item-1,
  .m_banners__item-2 {
    grid-row: span 3;
  }

  .m_banners__item-3,
  .m_banners__item-4,
  .m_banners__item-5 {
    grid-row: span 2;
  }
}

.m_banners__item {
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  font-weight: bold;
}

.m_banners__img {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.m_banners__box {
  display: block;
  height: auto;
  width: 100%;
  position: static;
  min-height: 190px;
  z-index: 1;
  overflow: hidden;
  flex-grow: 1;
  background: rgb(33, 96, 154);
  background: linear-gradient(
    90deg,
    rgba(33, 96, 154, 1) 0%,
    rgba(39, 125, 186, 1) 17%,
    rgba(30, 83, 139, 1) 37%,
    rgba(39, 129, 191, 1) 63%,
    rgba(27, 70, 125, 1) 83%,
    rgba(36, 137, 204, 1) 100%
  );
}

@media (min-width: 600px) {
  .m_banners__box {
    min-height: auto;
  }
}

.m_banners__top-item {
  background: rgb(0, 177, 226);
  background: linear-gradient(
    90deg,
    rgba(0, 177, 226, 1) 0%,
    rgba(30, 27, 75, 1) 70%,
    rgb(30, 27, 75, 1) 100%
  );
  color: #fff;
  padding: 0.8rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.m_banners__title {
  color: #fff;
  text-decoration: none;
  border: none;
  margin: 0 0 4px;
  font-size: 1.3em;
}

.m_banners__subtitle {
  font-size: 15px;
}

.m_banners__disclaimer {
  position: absolute;
  bottom: 5px;
  right: 5px;
  z-index: 2;
  color: #fff;
  font-size: 80%;
}

.consumers-use {
  background: #ff51001c;
  padding-block: 5rem 7rem;
}

.consumers-use__hgroup {
  max-width: 850px;
  text-align: center;
  margin-inline: auto;
}

.consumers-use__banners {
  gap: 2rem;
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.consumers-use__item {
  display: flex;
  align-items: center;
  background: rgb(246, 150, 35);
  background: linear-gradient(
    90deg,
    rgba(246, 150, 35, 1) 0%,
    rgba(255, 72, 36, 1) 68%,
    rgba(255, 70, 36, 1) 100%
  );
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  width: 100%;
  min-height: 90px;
  flex: 0 0 100%;
}

@media (min-width: 600px) {
  .consumers-use__item {
    flex-basis: 47%;
  }
}

@media (min-width: 1025px) {
  .consumers-use__banners {
    max-width: 1200px;
    margin-inline: auto;
  }

  .consumers-use__item {
    flex-basis: 31%;
  }
}

.m_banners__arrow:before {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDIwIDIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Im0xMiAyLTEuNCAxLjQgNS42IDUuNmgtMTYuMnYyaDE2LjJsLTUuNiA1LjYgMS40IDEuNCA4LTh6IiBmaWxsPSIjZmZmZmZmIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iIj48L3BhdGg+PC9nPjwvc3ZnPg==);
  background-size: contain;
  background-position: center;
  content: "";
  display: inline-block;
  width: 30px;
}

.m_banners__arrow {
  background: rgb(255, 72, 36, 1);
  background: linear-gradient(
    -90deg,
    rgba(246, 150, 35, 1) 0%,
    rgba(255, 72, 36, 1) 68%,
    rgba(255, 70, 36, 1) 100%
  );
  display: flex;
  width: 50px;
  aspect-ratio: 1;
  padding: 0.5rem;
  border-radius: 50%;
}

.consumers-use__item:hover {
  color: #fff;
  opacity: 0.8;
}

.consumers-use__img-box {
  width: 90px;
  height: 90%;
  position: relative;
}

.consumers-use__item-txt-box {
  flex-grow: 1;
  font-size: 18px;
}

.consumers-use__img {
  object-fit: contain;
  object-position: left;
  height: 100%;
  width: 100%;
}

.cta-long-btn__link {
  background: rgb(120, 231, 255);
  background: linear-gradient(
    90deg,
    rgba(120, 231, 255, 1) 0%,
    rgba(39, 140, 206, 1) 64%,
    rgba(39, 140, 206, 1) 69%,
    rgba(36, 137, 204, 1) 100%
  );
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  padding-block: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cta-long-btn__link:hover {
  opacity: 0.75;
  color: #fff;
}

.cta-long-btn__link:after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDIwIDIwIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Im0xMiAyLTEuNCAxLjQgNS42IDUuNmgtMTYuMnYyaDE2LjJsLTUuNiA1LjYgMS40IDEuNCA4LTh6IiBmaWxsPSIjZmZmZmZmIiBkYXRhLW9yaWdpbmFsPSIjMDAwMDAwIiBjbGFzcz0iIj48L3BhdGg+PC9nPjwvc3ZnPg==);
  background-size: contain;
  background-position: center;
  content: "";
  display: inline-block;
  height: 15px;
  margin-left: 5px;
  width: 15px;
}

.page-template-page-template-home .heading-box {
  display: none;
}

/*******************************
* No CSS Should be added below the above TRUMPS section.
*
*
* Please add all additional CSS above the TRUMPS section above.
* /
*/

.colorCombo {
  font-size: 45px;
  color: transparent;
  background-image: -o-linear-gradient(
    left,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 78, 0, 1)),
    to(rgba(236, 159, 5, 1))
  );
  background-image: linear-gradient(
    to right,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  -webkit-background-clip: text;
}

/* To merge later */

.previouspostslink,
.nextpostslink,
.first,
.last,
.wp-pagenavi .page {
  border: none !important;
}

.pages {
  font-size: 14px;
  font-weight: bold;
}

h3 a {
  color: inherit;
  font-size: inherit;
  text-decoration: inherit;
}

.bialty-container img {
  max-width: 100%;
  display: block;
}

/* ul li, ol li {
  list-style: initial;
  padding-left: 8px;
}

p, ul, ol {
  line-height: 1.8;
} */

blockquote {
  margin: 1rem 0 0 1rem;
  padding-left: 1rem;
  border-left: 5px solid #f84e01;
}

/* May need to add to mobile first, the blog code is not build like that. */

@media only screen and (max-width: 767px) {
  .blog-listing-container .respnds .slidecontent {
    width: 100%;
    padding: 15px;
  }

  .post-item__meta-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .push-sides {
    display: none;
  }
}

/* ------------------------
** New Home Test Styles
------------------------ */
.page-template-page-template-home-test .m_banners__container {
  margin-bottom: 0 !important;
}

.advantage__flex-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.advantage__main-content {
  padding-top: 40px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.light-img__wrapper {
  width: 50%;
  margin-left: 50px;
  position: absolute;
  top: -100px;
  opacity: 0.1;
  z-index: -1;
}

.advantage__text-grp {
  width: 100%;
  margin-bottom: 50px;
}

.advantage-video {
  width: 100%;
}

.responsive-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.responsive-video-wrapper iframe,
.responsive-video-wrapper object,
.responsive-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.advantage__stats-grp {
  width: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.advantage__stat {
  display: flex;
  align-items: center;
  max-width: 328px;
}

.advantage__stat-number {
  flex-shrink: 0;
  width: 109px;
  height: 109px;
  border-radius: 50%;
  background: -o-linear-gradient(
    left,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 78, 0, 1)),
    to(rgba(236, 159, 5, 1))
  );
  background: linear-gradient(
    to right,
    rgba(255, 78, 0, 1) 0%,
    rgba(236, 159, 5, 1) 100%
  );
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.advantage__stat-circle {
  width: 94px;
  height: 94px;
  background: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 42px;
  color: #08326a;
  font-weight: bold;
}

.advantage__stat-text {
  margin-left: 10px;
  text-align: start;
  font-size: 16px;
  max-width: 208px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .advantage__flex-wrap {
    padding-bottom: 60px;
  }

  .advantage__main-content {
    padding-top: 60px;
  }

  .light-img__wrapper {
    width: 35%;
    left: 15%;
  }

  .advantage__stats-grp {
    padding-top: 60px;
  }

  .advantage__stat {
    max-width: 316px;
  }
}

@media (min-width: 1025px) {
  .advantage__flex-wrap {
    padding-bottom: 84px;
  }

  .advantage__main-content {
    padding-top: 84px;
  }

  .light-img__wrapper {
    width: 15%;
    top: 0;
    left: 0;
    margin-left: 0;
    opacity: 1;
  }

  .advantage__text-grp {
    width: 50%;
    padding-left: 15%;
    margin-bottom: 0;
  }

  .advantage-video {
    width: 50%;
    padding-left: 32px;
  }

  .advantage__stats-grp {
    padding-top: 84px;
  }

  .advantage__stat {
    max-width: 328px;
  }
}

header {
  padding-bottom: 30px !important;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d;
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 20px -7px;
  position: relative;
}

form.submitting .wpcf7-spinner {
  visibility: visible;
}

.wpcf7-spinner::before {
  content: "";
  position: absolute;
  background-color: #fbfbfc;
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media (max-width: 600px) {
  img.alignright.wp-image-4845.size-large {
    width: 100%;
    float: none;
    max-width: initial;
    margin: 0;
  }
}

input.mailpoet_text {
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  display: block;
  border-radius: 4px;
  width: 100%;
  max-width: 430px !important;
  color: #495057;
  background: #f3f2f2;
  min-height: 49px;
  margin-top: 15px;
}

.newform {
  max-width: 430px;
  margin: 0 auto;
}

.newform label {
  display: block;
  font-weight: bold !important;
}

.newform input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border-radius: 4px;
  min-height: 49px;
  margin-bottom: 20px;
}

input#cm-privacy-consent {
  height: 13px;
  min-height: initial;
  display: inline;
  width: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
}

.newform .signup-input label {
  display: inline;
}

.newform button {
  background: linear-gradient(
    to right,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(19, 216, 252, 1) 0%,
    rgba(0, 156, 221, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(19, 216, 252, 1)),
    to(rgba(0, 156, 221, 1))
  );
  display: inline-block;
  text-align: center;
  height: 49px;
  border-radius: 50px;
  min-width: 169px;
  max-width: 100%;
  font-size: 16px;
  line-height: 41px;
  color: #fff;
  font-weight: bold;
  margin-top: 30px;
}

/*Christmas in July Page Start*/

.page-id-5144 p {
  font-size: 18px;
}

.page-id-5144 .half-and-half__text-wrapper ul {
  font-size: 18px;
}

.page-id-5144 .marketBox ul {
  font-size: 18px;
}

.page-id-5144 p strong {
  color: green;
}

.page-id-5144 .half-and-half__text-wrapper p b {
  color: green;
}

.page-id-5144 .half-and-half__text-wrapper strong {
  color: green;
}

.page-id-5144 .half-and-half__text-wrapper h2 strong {
  color: #08326a;
}

.page-id-5144 .half-section {
  padding: 40px 0;
}

.page-id-5144 .col-md-8 img.alignright {
  height: auto;
  max-width: 100%;
}

.page-id-5144 li.breadcrumb_last {
  color: green !important;
}

.page-id-5144 .addressInfobox p strong {
  color: white;
}

.page-id-5144 .color-link {
  color: #13d8fc;
}

.page-id-5144 .color-link:hover {
  color: #ff8955;
}

.page-id-5144 .marketBox li::after {
  color: green;
}

.page-id-5144 .half-and-half__text-wrapper li {
  list-style: circle;
  padding-left: 0px;
}

html,
body {
  height: 100%;
}

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.customMat_fieldSelect {
  width: 100%;
}

.customMat_fieldSelect .mdc-text-field--filled {
  background-color: unset !important;
}

.customMat_fieldSelect .mdc-text-field {
  padding: 0px !important;
}

.customMat_fieldSelect
  .mat-mdc-form-field:hover
  .mat-mdc-form-field-focus-overlay {
  opacity: unset !important;
}

.customMat_fieldSelect .mdc-evolution-chip__text-label {
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.customMat_fieldSelect
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.87);
}

.customMat_fieldSelect .mat-mdc-form-field-focus-overlay {
  background-color: unset !important;
}

.material_toggle .mdc-form-field {
  gap: 12px;
}

.material_toggle label.mdc-label {
  font-size: 14px;
  color: #212529;
  font-weight: 500;
  line-height: 1.5;
  margin: 0px;
  padding: 0px;
}

// new code

.page_wrapper {
  background: #f5f5f5;
  position: relative;
  display: flex;
  overflow-x: hidden;
  background-size: cover;
  min-height: 100%;
}

.sidebar_wrapper {
  display: flex;
  justify-content: space-between;
  width: 300px;
  padding: 16px;
  flex-direction: column;
  position: fixed;
  height: 100%;
  z-index: 999;
  overflow-y: auto;
}

.sidebar_wrapper ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.sidebar_wrapper ul li {
  // margin-bottom: 24px;
  margin-bottom: 16px;
}

.sidebar_wrapper ul li a {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #252525;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: none;
  padding: 0;
  padding: 8px 16px;
  width: 100%;
}

.sidebar_wrapper ul li a:hover {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.sidebar_wrapper ul li a:hover .menuIcon {
  background: linear-gradient(180deg, #f36b24 0%, #ffb168 100%);
}

.sidebar_wrapper ul li a:hover .menuIcon img {
  filter: brightness(0) saturate(100%) invert(96%) sepia(100%) saturate(0%)
    hue-rotate(274deg) brightness(110%) contrast(101%);
}

.sidebar_wrapper ul li a.active {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.sidebar_wrapper ul li a.active .menuIcon {
  background: linear-gradient(180deg, #f36b24 0%, #ffb168 100%);
}

.sidebar_wrapper ul li a.active .menuIcon img {
  filter: brightness(0) saturate(100%) invert(96%) sepia(100%) saturate(0%)
    hue-rotate(274deg) brightness(110%) contrast(101%);
}

.menuIcon {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.customSubmenu {
  background: #fff;
  padding: 14px 5px;
  margin-top: 6px;
}

ul.customSubmenu li {
  margin-bottom: 0px;
}

ul.customSubmenu li a:hover {
  box-shadow: unset;
  background: #ffebe0;
  border-radius: unset;
}

ul.customSubmenu li a.menuActive {
  box-shadow: unset;
  background: #ffebe0;
  border-radius: unset;
}

.content_wrapper {
  width: 100%;
  margin-left: 300px;
  display: inline-block;
  overflow: hidden;
  border-radius: 40px 0px 0px 40px;
  background: #fff;
}

.navbarHeader nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbarHeader {
  padding: 16px 24px;
}

.contentFrame {
  padding: 24px 34px 24px 34px;
}

.contactUs_frame {
  background: url("./assets/images/new_img/contactUs_frame.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 217px;
  padding: 24px;
  border-radius: 10px;
  background-position: bottom;
}

.page_wrapper.collapseHide .sidebar_wrapper {
  display: none;
}

.page_wrapper.collapseHide .content_wrapper {
  margin-left: 0px;
}

.page_wrapper.collapseHide .hideMenuClick img {
  transform: rotate(180deg) !important;
}

.whiteClr_text {
  color: #ffffff;
  line-height: 1.5;
  margin: 0px;
}

.blackClr_text {
  color: #000;
  line-height: 1.5;
  margin: 0px;
}
.fuegoNuevoClr_text{
  color: #F36B24;
    line-height: 1.5;
      margin: 0px;
}
.greenJuiceClr_text{
  color: #3EDF45;
    line-height: 1.5;
    margin: 0px;
}
.steelWoolClr_text{
  color: #777;
    line-height: 1.5;
    margin: 0px;
}
.deepBreathClr_text {
  color: #24255c;
  line-height: 1.5;
  margin: 0px;
}

.aztecJadeClr_text {
  color: #2eb987;
  line-height: 1.5;
  margin: 0px;
}

.fluorescentRedClr_text {
  color: #ff5656;
  line-height: 1.5;
  margin: 0px;
}

.blueJayClr_text {
  color: #5a8cdf;
  line-height: 1.5;
  margin: 0px;
}

.steelWool_text {
  color: #777;
  line-height: 1.5;
  margin: 0px;
}

ul.custom_breadcrumb {
  display: flex;
  align-items: center;
  margin: 0;
  gap: 12px;
  padding: 0px;
  list-style: none;
}

ul.custom_breadcrumb li {
  color: #777;
}

ul.custom_breadcrumb li a {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: unset;
  color: #777;
}

ul.custom_breadcrumb li a.active {
  color: #000;
}

.actionBtn {
  background-color: transparent;
  border: 1px solid transparent;
  outline: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 5px;
  padding: 6px 10px;
}

.actionBtn:hover {
  background: rgba(36, 37, 92, 0.05);
}

.UploaderImageFrame {
  width: 100%;
  border: 2px dashed #7777;
  height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.UploaderImageFrame h5 {
  font-size: 16px;
  line-height: 1.5;
  color: #777;
  margin: 0px;
}

.sidebar_wrapper::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

/* Track */

.sidebar_wrapper::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 30px;
}

/* Handle */

.sidebar_wrapper::-webkit-scrollbar-thumb {
  background: #c4c2c1;
  border-radius: 30px;
}

/* Handle on hover */

.sidebar_wrapper::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.hideMenuClick {
  background: #f5f5f5;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorText {
  color: var(--bs-form-invalid-color);
  font-size: 0.875em;
  line-height: 1.5;
  font-weight: 500;
  margin: 5px 0px 0px;
}

.editmenuOverflowY {
  height: 500px;
  overflow-y: scroll;
  padding: 16px 16px 0px;
}

.editmenuOverflowY::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */

.editmenuOverflowY::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 30px;
}

/* Handle */

.editmenuOverflowY::-webkit-scrollbar-thumb {
  background: #c4c2c1;
  border-radius: 30px;
}

/* Handle on hover */

.editmenuOverflowY::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.accessPermission_list {
  background-color: #f4f4f7;
  border-radius: 5px;
  // min-height: 100px;
  height: 100%;
  padding: 12px;
  box-shadow: 0px 4px 12px aliceblue;
}

.accessPermission_list ul {
  padding-left: 24px;
  list-style: none;
}

.accessPermission_list
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.accessPermission_list
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.accessPermission_list
  .mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate="true"]:enabled
  ~ .mdc-checkbox__background {
  border-color: #f36b24 !important;
  background-color: #f36b24 !important;
}

@media (min-width: 1027px) and (max-width: 1229px) {
  .sidebar_wrapper {
    width: 260px;
  }

  .content_wrapper {
    margin-left: 260px;
  }

  .sidebar_wrapper ul li a {
    font-size: 13px;
    padding: 8px 12px;
  }

  .sidebar_wrapper ul li {
    margin-bottom: 12px;
  }

  .sidebar_wrapper {
    font-size: 12px;
  }

  .contentFrame {
    padding: 16px;
  }

  .logoBrand {
    padding-bottom: 30px;
  }

  .labelText {
    font-size: 12px;
  }
}

.contactInfo {
  width: 50%;
  margin-bottom: 24px;
}

.contactInfo th {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #777;
}

.contactInfo th,
.contactInfo td {
  padding: 4px 0px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #dee2e6;
  border: #adb5bd solid 1px;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 15px !important;
}

.cdk-global-scrollblock {
  top: unset !important;
}

.templatedivider {
  position: relative;
  z-index: 1;
}

.toast-container {
  position: fixed !important;
  top: 24px !important;
  right: 24px !important;
}


.analytics_dashItem{
border-radius: 10px;
  border: 1px solid #E5E5E5;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  justify-content: space-between;
// box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.analytics_dashItem:hover{
  border: 1px solid #F36B24;
}

.iconframe{
border-radius: 5px;
  // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  // background:linear-gradient(180deg, #F36B24 0%, #FFB168 100%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
// .iconframe img{
//   filter: brightness(0) saturate(100%) invert(100%) sepia(20%) saturate(1106%) hue-rotate(223deg) brightness(113%) contrast(100%);
// }


.row.analytics_overview .col-md-3:first-child .iconframe{
  // background: #E9CE2C;
  // background: rgba(233, 206, 44, 0.30);
  background: #FF6D60;


}
.analytics_overview .col-md-3:first-child .iconframe img{
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(101%) contrast(101%);
// filter: brightness(0) saturate(100%) invert(98%) sepia(80%) saturate(7480%) hue-rotate(335deg) brightness(98%) contrast(86%);}

}

.analytics_overview .col-md-3:nth-child(2) .iconframe {
// background: rgba(255, 140, 66, 0.30);
background: #F7D060;
}
.analytics_overview .col-md-3:nth-child(2) .iconframe img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(101%) contrast(101%);
// filter: brightness(0) saturate(100%) invert(66%) sepia(23%) saturate(1573%) hue-rotate(330deg) brightness(100%) contrast(103%);
}


.analytics_overview .col-md-3:nth-child(3) .iconframe {
// background: rgba(255, 60, 56, 0.30);
background: #D14D72;
}

.analytics_overview .col-md-3:nth-child(3) .iconframe img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(101%) contrast(101%);
    // filter: brightness(0) saturate(100%) invert(40%) sepia(88%) saturate(3420%) hue-rotate(338deg) brightness(101%) contrast(109%);
}


.analytics_overview .col-md-3:nth-child(4) .iconframe {
// background: rgba(166, 52, 70, 0.30);
background: #19A7CE;
}
.analytics_overview .col-md-3:nth-child(4) .iconframe img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7494%) hue-rotate(139deg) brightness(101%) contrast(101%);
  // filter: brightness(0) saturate(100%) invert(24%) sepia(18%) saturate(4559%) hue-rotate(317deg) brightness(103%) contrast(91%);
}
.analytics_table{
  width: 100%;
  overflow-y: auto;
  white-space: nowrap;
}
.analytics_table table{
  width: 100%;
  border-collapse: collapse;
}
.analytics_table thead {
  background: #F4F4F7 !important;
  border-radius: 10px 10px 0 0;
}
.analytics_table thead tr:first-child th:first-child {
  border-top-left-radius: 10px;
}
.analytics_table thead tr:first-child th:last-child {
  border-top-right-radius: 10px;
}
.analytics_table thead tr:nth-child(2) th:first-child {
  border-bottom-left-radius: 10px;
}

.analytics_table thead tr:nth-child(2) th:last-child {
  border-bottom-right-radius: 10px;
}
.analytics_table thead tr:first-child th {
  border-bottom: unset !important;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 16px;
}

.analytics_table thead tr:nth-child(2) th {
  border-bottom: unset !important;
  font-size: 13px;
  padding: 12px 16px;
}


.analytics_table thead tr:nth-child(2) th h5{
  color: #000000;
  margin-bottom: 6px;
  font-size: 13px;
    font-weight: 600;

}
.analytics_table thead tr:nth-child(2) th p {
  font-size: 13px;
  color: #777777;
  margin-bottom: 0px;
    font-weight: 500;
}
 .analytics_table thead tr:first-child th:not(:first-child):not(:nth-child(2)) {
    border-bottom: 1px solid #CCCCCC !important;
 }

 .analytics_table thead tr:first-child th:nth-child(2){
  border-right: 1px solid #CCCCCC !important;
 }
 .analytics_table thead tr:nth-child(2) th:nth-child(2) {
   border-right: 1px solid #CCCCCC !important;
 }

 .analytics_table tbody tr td:nth-child(2) {
   border-right: 1px solid #CCCCCC !important;
 }

//  .analytics_table thead tr th:not(:first-child , :last-child):not(:nth-child(2)) {
//    border-right: 1px solid #CCCCCC !important;
//  }
//  .analytics_table tbody tr td:not(:first-child ,
// :last-child):not(:nth-child(2)) {
//    border-right: 1px solid #CCCCCC !important;
//  }

.analytics_table tbody tr td {
  padding: 12px 16px !important;
  border-bottom:1px solid #CCC !important;
  color: #777;
  font-size: 14px;
  font-weight: 500;
}
.analytics_table::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */

.analytics_table::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 30px;
}

/* Handle */

.analytics_table::-webkit-scrollbar-thumb {
  background: #C4C2C1;
  border-radius: 30px;
}

/* Handle on hover */

.analytics_table::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.Channelgrp_dropdown{
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  outline: unset;

    background:transparent  url("./assets/images/new_img/solid_sort.svg") no-repeat;
      background-size: 10px;
      background-position: calc(100% - 0rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-inline: 0px 24px;
}
.filterBtn{
  background: transparent;
border-radius: 5px;
  border: 1px solid #CCC;
  height: 40px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0px 16px;
}
.filterBtn:hover{
    background: #F4F4F7;
}


ul.analytics_navtap{
  display: inline-flex;
  align-items: center;
  margin: 0px;
  list-style: none;
  background-color: #f1eff1;
  border-radius: 50px;
    padding: 6px 12px;
    gap: 16px;
}
ul.analytics_navtap li{
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
    border-radius: 50px;
    cursor: pointer;
}
ul.analytics_navtap li img{
filter: brightness(0) saturate(100%) invert(61%) sepia(5%) saturate(183%) hue-rotate(187deg) brightness(87%) contrast(84%);
}

ul.analytics_navtap li:hover img {
filter: brightness(0) saturate(100%)
}
ul.analytics_navtap li:hover h5{
  color: #000;
}

ul.analytics_navtap li  h5{
  color: #858689;
  line-height: 1.5;
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
}

ul.analytics_navtap li:hover{
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 4px;
}

// .admin-dashboard {
//   background: #fff;
//   box-shadow: 0px 9.62963px 34.4px rgba(0, 0, 0, 0.0425185),
//     0px 5px 17.55px rgba(0, 0, 0, 0.035),
//     0px 2.03704px 8.8px rgba(0, 0, 0, 0.0274815),
//     0px 0.462963px 4.25px rgba(0, 0, 0, 0.0168519);
//   border-radius: 5px;
//   padding: 12px;
//   min-height: 170px;
//   height: 100%;
// }

.admin-dashboard h5 {
  // font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #000000;
}

.admin-dashboard h2 {
  // font-family: "Poppins", sans-serif;
  font-size: 34px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0px;
  color: #000000;
  text-align: center;
}

.admin-dashboard img {
  // font-family: "Poppins", sans-serif;
  font-size: 34px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0px;
  color: #000000;
  text-align: center;
  filter: brightness(0.5);
}


.imageBlock .fr-view table td,
.imageBlock .fr-view table th{
  border: unset !important;
  background: transparent !important;
}
